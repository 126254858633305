export function calculatePremiumForInstalments(
  premium: number,
  paymentPeriod: number,
  premiumDiscounted?: number
): {
  instalment: number;
  paymentPeriod: number;
  premiumCalculated: number;
  premiumDiscounted: number;
} {
  const instalment = Math.round(premium / paymentPeriod / 100) * 100;
  const premiumCalculated = instalment * paymentPeriod;

  return {
    instalment,
    paymentPeriod,
    premiumCalculated,
    premiumDiscounted: premiumDiscounted ?? null,
  };
}

import { ConfirmSignaturePayload } from '../../../../interfaces/idefend/confirm-signature-payload.interface';
import { CreationLockPayload } from '../../shared/interfaces/creation-lock-payload.interface';
import { CreationLockResponse } from '../../shared/interfaces/creation-lock-response.interface';
import { EnabledPortfoliosPayload } from '../../shared/interfaces/enabled-portfolios-payload.interface';
import { EnabledPortfolioWithOffers } from '../../shared/interfaces/enabled-portfolios-response.interface';

export class SetEnabledPortfolios {
  static readonly type = '[iDefend API] Set enabled portfolios';

  constructor(public payload: EnabledPortfoliosPayload) {}
}

export class CalculateWarrantyOffer {
  static readonly type = `[iDefend API] Calculate Warranty Offer`;

  constructor() {}
}

export class SavePortfoliosWithOffers {
  static readonly type = '[UI] Save Portfolios With Offers';

  constructor(public portfolios: EnabledPortfolioWithOffers[]) {}
}

export class SelectWarrantyOffer {
  static readonly type = '[UI] Select Warranty Offer';

  constructor(public productCode: string) {}
}

export class LockOffer {
  static readonly type = '[iDefend API] Lock Warranty Offer';

  constructor(public payload: CreationLockPayload) {}
}

export class SignOffer {
  static readonly type = '[iDefend API] Sign Warranty Offer';

  constructor(public id: number, public payload: ConfirmSignaturePayload) {}
}

export class GetPolicyByNumber {
  static readonly type = '[iDefend API] Get Policy By Number';

  constructor(public policyNo: string) {}
}

export class GetWarrantyPolicy {
  static readonly type = '[iDefend API] Get Warranty Policy';

  constructor(public id: number) {}
}

export class SavePolicy {
  static readonly type = '[UI] Save Warranty Policy';

  constructor(public policy: CreationLockResponse) {}
}

export class ResetWarrantyOfferState {
  static readonly type = '[UI] Reset Warranty Offer State';

  constructor() {}
}

export class ApplyDiscountCode {
  static readonly type = '[UI] Apply Discount Code';

  constructor(public code: string, public amount: string) {}
}

export class ApplyAffiliationDiscount {
  static readonly type = '[UI] Apply Affiliation Discount';

  constructor(public link: string, public amount: string) {}
}

export class SetLoadingState {
  static readonly type = '[UI] Set Loading State';

  constructor(public isLoading: boolean) {}
}

import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DialogService } from 'primeng/dynamicdialog';
import { AnswerQuestionComponent } from './answer-question/answer-question.component';

export interface FaqTale {
  header: string;
  question1: string;
  question2: string;
  question3?: string;
}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  providers: [DialogService],
})
export class FaqComponent implements OnInit {
  faqTales: FaqTale[];

  constructor(
    private transloco: TranslocoService,
    public dialogService: DialogService
  ) {
    this.faqTales = [
      {
        header: this.transloco.translate('faq.1.header'),
        question1: this.transloco.translate('faq.1.question.1'),
        question2: this.transloco.translate('faq.1.question.2'),
      },
      {
        header: this.transloco.translate('faq.2.header'),
        question1: this.transloco.translate('faq.2.question.1'),
        question2: this.transloco.translate('faq.2.question.2'),
      },
      {
        header: this.transloco.translate('faq.3.header'),
        question1: this.transloco.translate('faq.3.question.1'),
        question2: this.transloco.translate('faq.3.question.2'),
      },
      {
        header: this.transloco.translate('faq.4.header'),
        question1: this.transloco.translate('faq.4.question.1'),
        question2: this.transloco.translate('faq.4.question.2'),
      },
      {
        header: this.transloco.translate('faq.5.header'),
        question1: this.transloco.translate('faq.5.question.1'),
        question2: this.transloco.translate('faq.5.question.2'),
        question3: this.transloco.translate('faq.5.question.3'),
      },
      {
        header: this.transloco.translate('faq.6.header'),
        question1: this.transloco.translate('faq.6.question.1'),
        question2: this.transloco.translate('faq.6.question.2'),
        question3: this.transloco.translate('faq.6.question.2'),
      },
      {
        header: this.transloco.translate('faq.7.header'),
        question1: this.transloco.translate('faq.7.question.1'),
        question2: this.transloco.translate('faq.7.question.2'),
      },
      {
        header: this.transloco.translate('faq.8.header'),
        question1: this.transloco.translate('faq.8.question.1'),
        question2: this.transloco.translate('faq.8.question.2'),
      },
      {
        header: this.transloco.translate('faq.9.header'),
        question1: this.transloco.translate('faq.9.question.1'),
        question2: this.transloco.translate('faq.9.question.2'),
      },
    ];
  }

  ngOnInit(): void {}

  initializeAnswer(sectionNumber, questionNumber): void {
    this.dialogService.open(AnswerQuestionComponent, {
      data: {
        section: sectionNumber,
        question: questionNumber,
      },
    });
  }
}

<ng-container *transloco="let t; read: 'contact'">
  <div class="contact p-grid p-nogutter p-jc-center">
    <div class="p-grid p-nogutter wrapper">
      <div class="p-col-12 contact__logo">
        <img
          src="assets/icons/logo-white.svg"
          alt="gap logo" />
      </div>
      <div class="p-col-12 p-xl-7">
        <div class="p-grid">
          <div class="p-col-12 p-md-6 contact__section">
            <h1>{{ t('header.1') }}</h1>
            <p>{{ t('informations.ais.description') }}</p>
            <p class="p-text-bold">{{ t('informations.ais.fullName') }}</p>
            <p>{{ t('informations.ais.address') }}</p>
            <p class="p-text-bold">
              {{ t('informations.ais.emailDesc') }}
              <a
                href="mailto:bok@gaponline.pl"
                class="textYellow"
                >{{ t('informations.ais.email') }}</a
              >
            </p>
            <p class="p-text-bold">
              {{ t('informations.ais.phoneNoDesc')
              }}<span class="textYellow">{{
                t('informations.ais.phoneNo')
              }}</span>
            </p>
            <p class="p-text-bold">
              {{ t('informations.ais.proxyDesc') }}
              <a
                target="_blank"
                href="https://gaponline.pl/download/pozostale/pelnomocnictwo.pdf"
                class="textYellow"
                >{{ t('informations.ais.proxy') }}</a
              >
            </p>
            <div
              class="p-d-flex p-ai-center p-my-2"
              (mouseleave)="ourMission.hide()">
              <img
                src="assets/icons/mission.svg"
                class="shieldIcon"
                alt="our mission icon" />
              <p class="p-text-bold visionMission p-px-3">
                {{ t('informations.ourMission.link') }}
              </p>
              <div class="imgWrapper">
                <img
                  src="assets/icons/info-yellow.svg"
                  class="p-mx-2 infoIcon"
                  alt="info"
                  (mouseover)="ourMission.show($event)" />
                <p-overlayPanel
                  #ourMission
                  [dismissable]="true"
                  [showTransitionOptions]="'200ms'"
                  (mouseleave)="ourMission.hide()">
                  <ng-template pTemplate>
                    <div class="p-mb-2">
                      <span class="textGrey p-text-bold p-mb-4">{{
                        t('informations.ourMission.header')
                      }}</span>
                    </div>
                    <div>
                      <span class="textGrey">
                        {{ t('informations.ourMission.description') }}
                      </span>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </div>
            </div>
            <div
              class="p-d-flex p-ai-center p-my-2"
              (mouseleave)="ourVision.hide()">
              <img
                src="assets/icons/vision.svg"
                class="shieldIcon"
                alt="our mission icon" />
              <p class="p-text-bold visionMission p-px-3">
                {{ t('informations.ourVision.link') }}
              </p>
              <div class="imgWrapper">
                <img
                  src="assets/icons/info-yellow.svg"
                  class="p-mx-2 infoIcon"
                  alt="info"
                  (mouseover)="ourVision.show($event)" />
                <p-overlayPanel
                  #ourVision
                  [dismissable]="true"
                  [showTransitionOptions]="'200ms'"
                  (mouseleave)="ourVision.hide()">
                  <ng-template pTemplate>
                    <div class="p-mb-2">
                      <span class="textGrey p-text-bold p-mb-4">{{
                        t('informations.ourVision.header')
                      }}</span>
                    </div>
                    <div>
                      <span class="textGrey">{{
                        t('informations.ourVision.description')
                      }}</span>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </div>
            </div>
          </div>
          <div class="p-col-12 p-md-6 contact__section paddingTop">
            <p class="addTopBorderOnMobile">
              {{ t('informations.defend.description') }}
            </p>
            <p class="p-text-bold">{{ t('informations.defend.fullName') }}</p>
            <p>{{ t('informations.defend.address') }}</p>
            <p class="p-text-bold">
              {{ t('informations.defend.emailDesc') }}
              <a
                href="mailto:info@defendinsurance.pl"
                class="textYellow"
                >{{ t('informations.defend.email') }}</a
              >
            </p>
            <p class="p-text-bold">
              {{ t('informations.defend.phoneNoDesc')
              }}<span class="textYellow">{{
                t('informations.defend.phoneNo')
              }}</span>
            </p>
            <p>
              <a
                target="_blank"
                href="http://www.defendinsurance.pl"
                class="textYellow p-text-bold"
                >{{ t('informations.defend.homepage') }}</a
              >
            </p>
            <div class="p-mt-3">
              <img
                src="assets/icons/defend-logo.svg"
                class="defendLogo"
                alt="defend logo" />
            </div>
          </div>
          <div class="p-col-12 p-md-6 contact__section">
            <h1 class="addTopBorder">{{ t('header.2') }}</h1>
            <p>{{ t('informations.fortegra.description') }}</p>
            <p class="p-text-bold p-text-uppercase">
              {{ t('informations.fortegra.fullName') }}
            </p>
            <p>{{ t('informations.fortegra.address') }}</p>
            <a
              target="_blank"
              href="http://www.fortegra.com"
              class="textYellow p-text-bold"
              >{{ t('informations.fortegra.homepage') }}</a
            >
            <div class="p-mt-3">
              <img
                src="assets/icons/fortegra-logo.svg"
                class="defendLogo"
                alt="fortegra logo" />
            </div>
          </div>
          <div
            class="p-col-12 p-md-6 p-d-flex p-dir-col contact__section contact__downloads">
            <h1 class="addTopBorder">{{ t('header.3') }}</h1>
            <a
              target="_blank"
              href="https://gaponline.pl/download/owu/OWU GAP FTG.pdf"
              >{{ t('downloads.1') }}</a
            >
            <a
              target="_blank"
              href="https://gaponline.pl/download/owu/SWU GAP MAX FTG.pdf"
              >{{ t('downloads.2') }}</a
            >
            <a
              target="_blank"
              href="https://gaponline.pl/download/owu/SWU GAP MAX AC FTG.pdf"
              >{{ t('downloads.3') }}</a
            >
            <a
              target="_blank"
              href="https://gaponline.pl/download/owu/SWU GAP DIRECT FTG.pdf"
              >{{ t('downloads.4') }}</a
            >
            <a
              target="_blank"
              href="https://gaponline.pl/download/owu/SWU GAP TRUCK MAX FTG.pdf"
              >{{ t('downloads.5') }}</a
            >
            <a
              target="_blank"
              href="https://gaponline.pl/download/owu/SWU GAP TRUCK MAX AC FTG.pdf"
              >{{ t('downloads.6') }}</a
            >

            <ng-container *ngIf="showAll">
              <a
                target="_blank"
                href="https://gaponline.pl/download/pozostale/procedura zgloszenia szkody.pdf"
                >{{ t('downloads.7') }}</a
              >
              <a
                target="_blank"
                href="https://gaponline.pl/download/pozostale/formularz-szkoda gap.pdf"
                >{{ t('downloads.8') }}</a
              >
              <a
                target="_blank"
                href="https://gaponline.pl/download/pozostale/formularz-zmiana w umowie.pdf"
                >{{ t('downloads.9') }}</a
              >
              <a
                target="_blank"
                href="https://gaponline.pl/download/pozostale/formularz-anulowanie.pdf"
                >{{ t('downloads.10') }}</a
              >
              <a
                target="_blank"
                href="https://gaponline.pl/download/pozostale/procedura reklamacji.pdf"
                >{{ t('downloads.11') }}</a
              >
              <a
                target="_blank"
                href="https://gaponline.pl/download/pozostale/pelnomocnictwo.pdf"
                >{{ t('downloads.12') }}</a
              >
              <a
                target="_blank"
                href="https://gaponline.pl/download/pozostale/RODO.pdf"
                >{{ t('downloads.13') }}</a
              >
              <a
                target="_blank"
                href="https://gaponline.pl/download/pozostale/IPID.pdf"
                >{{ t('downloads.18') }}</a
              >
              <a
                target="_blank"
                href="https://gaponline.pl/download/pozostale/regulamin serwisu.pdf"
                >{{ t('downloads.14') }}</a
              >
              <a
                target="_blank"
                href="https://gaponline.pl/download/pozostale/polityka prywatnosci.pdf"
                >{{ t('downloads.15') }}</a
              >
            </ng-container>
            <a
              class="p-text-uppercase p-text-bold pointer"
              (click)="toggleShowAll()">
              <ng-container *ngIf="!showAll"
                >{{ t('downloads.16') }}
              </ng-container>
              <ng-container *ngIf="showAll">
                <span class="textYellow">{{ t('downloads.17') }}</span>
              </ng-container>
            </a>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-xl-5 contact__form">
        <div class="contact__form__wrapper">
          <div class="contact__form__header">
            <h1 class="p-text-uppercase addTopBorderOnMobile">
              {{ t('header.4') }}
            </h1>
            <p>{{ t('form.description') }}</p>
          </div>
          <form
            [formGroup]="contactFormGroup"
            (ngSubmit)="onSubmit()">
            <div class="form-group">
              <div class="p-d-md-flex">
                <input
                  class="contactInput"
                  id="name"
                  type="text"
                  pInputText
                  placeholder="{{ t('form.name') }}"
                  formControlName="name" />
              </div>
              <div class="p-d-md-flex">
                <input
                  class="contactInput"
                  id="brand"
                  type="text"
                  pInputText
                  placeholder="{{ t('form.brand') }}"
                  formControlName="brand" />
                <input
                  class="contactInput"
                  id="model"
                  type="text"
                  pInputText
                  placeholder="{{ t('form.model') }}"
                  formControlName="model" />
              </div>

              <div class="p-d-md-flex">
                <input
                  class="contactInput"
                  id="yearOfProduction"
                  type="text"
                  pInputText
                  placeholder="{{ t('form.yearOfProduction') }}"
                  formControlName="yearOfProduction" />
                <input
                  class="contactInput"
                  id="value"
                  type="text"
                  pInputText
                  placeholder="{{ t('form.value') }}"
                  formControlName="value" />
              </div>

              <div class="p-d-md-flex">
                <input
                  class="contactInput"
                  id="phoneNo"
                  type="text"
                  pInputText
                  placeholder="{{ t('form.phoneNo') }}"
                  formControlName="phoneNo" />
                <input
                  class="contactInput"
                  id="email"
                  type="text"
                  pInputText
                  placeholder="{{ t('form.email') }}"
                  formControlName="email" />
              </div>
              <div>
                <textarea
                  class="contactInput p-mb-1"
                  id="textMsg"
                  [rows]="3"
                  [cols]="30"
                  autoResize="autoResize"
                  pInputTextarea
                  placeholder="{{ t('form.textMsg') }}"
                  formControlName="textMsg"></textarea>
              </div>

              <div class="p-field-checkbox">
                <p-checkbox
                  [binary]="true"
                  [formControl]="contactFormGroup.controls['agreement1']"
                  id="contactCheckbox1"></p-checkbox>
                <label for="contactCheckbox1"
                  >{{ t('form.checkbox1Desc1')
                  }}<a
                    target="_blank"
                    href="https://gaponline.pl/gapDownloads/polityka-prywatnosci.pdf"
                    >{{ t('form.checkbox1Desc2') }}</a
                  ></label
                >
              </div>

              <div class="p-field-checkbox">
                <p-checkbox
                  [binary]="true"
                  [formControl]="contactFormGroup.controls['agreement2']"
                  id="contactCheckbox2"></p-checkbox>
                <label for="contactCheckbox2"
                  >{{ t('form.checkbox2Desc1') }}
                  <a
                    target="_blank"
                    href="https://gaponline.pl/gapDownloads/polityka-prywatnosci.pdf"
                    >{{ t('form.checkbox2Desc2') }}</a
                  >
                  {{ t('form.checkbox2Desc3') }}
                </label>
              </div>
              <div class="p-d-flex p-jc-center p-jc-md-end p-mt-4">
                <button
                  type="submit"
                  class="buttonYellow"
                  style="width: 251px"
                  [disabled]="!contactFormGroup.valid">
                  {{ t('form.button') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-container>

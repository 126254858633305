<ng-container *transloco="let t; read: 'home'">
  <div class="p-grid p-nogutter p-jc-center forWho">
    <div class="p-col-12 forWho__header">
      <h1 class="p-text-bold">
        {{ t('for-who.header.1') }}
        <span>{{ t('for-who.header.2') }}</span>
      </h1>
      <h1 class="p-text-normal">{{ t('for-who.header.3') }}</h1>
    </div>

    <div class="p-col-12 p-text-center forWho__charts">
      <div class="p-grid">
        <div class="p-col-12 p-md-6">
          <h2 class="textBlue">
            {{ t('for-who.content.1') }}
          </h2>
          <img
            src="assets/images/car1.png"
            alt="chart" />
        </div>
        <div class="p-col-12 p-md-6">
          <h2 class="textBlue">
            {{ t('for-who.content.2') }}
          </h2>
          <img
            src="assets/images/car2.png"
            alt="chart" />
        </div>
        <div class="p-col-12 forWho__texts">
          <p class="textBlue p-text-bold">
            <span class="textYellow">{{ t('for-who.content.yes') }}</span>
            {{ t('for-who.content.3') }}
          </p>
          <p class="textBlue p-text-bold">
            <span class="textYellow">{{ t('for-who.content.no') }}</span>
            {{ t('for-who.content.4') }}
          </p>
        </div>
      </div>
    </div>

    <div class="p-col-12 forWho__header">
      <h1 class="p-text-normal">{{ t('for-who.header.4') }}</h1>
    </div>

    <div class="p-col-12 forWho__vehicles">
      <ng-container *ngFor="let vehicle of vehicles">
        <!--          <img [src]=imgSrc+vehicle alt="vehicle">-->
        <div
          class="vehicleWrapper"
          style="background: url('assets/images/vehicles/{{
            vehicle.img
          }}'); background-size: cover;">
          <div class="vehicleType">
            <p>{{ vehicle.name }}</p>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="p-col-12 forWho__texts p-pt-2">
      <p>
        {{ t('for-who.content.5') }}
        <a
          routerLink="/"
          fragment="contact"
          class="textYellow p-text-bold textUnderlined"
          >{{ t('for-who.content.6') }}</a
        >
        {{ t('for-who.content.7') }}
      </p>
    </div>
  </div>
</ng-container>

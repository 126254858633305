import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-when-to-buy',
  templateUrl: './when-to-buy.component.html',
  styleUrls: ['./when-to-buy.component.scss'],
})
export class WhenToBuyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

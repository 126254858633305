import { Component, OnInit } from '@angular/core';
import {
  CooperationData,
  CooperationDataInterface,
} from '../../../../interfaces/contact-data.interface';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../../../services/api.service';
import { ConfirmationService } from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-cooperation',
  templateUrl: './cooperation.component.html',
  styleUrls: ['./cooperation.component.scss'],
})
export class CooperationComponent implements OnInit {
  cooperationDto: CooperationDataInterface;
  cooperationFormGroup: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private confirmationService: ConfirmationService,
    private transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.cooperationDto = new CooperationData();
    this.cooperationFormGroup = this.fb.group({
      name: [this.cooperationDto.name, [Validators.required]],
      taxNo: [
        this.cooperationDto.taxNo,
        [Validators.required, RxwebValidators.digit()],
      ],
      phoneNo: [
        this.cooperationDto.phoneNo,
        [Validators.required, RxwebValidators.digit()],
      ],
      email: [
        this.cooperationDto.email,
        [Validators.required, RxwebValidators.email()],
      ],
      textMsg: [this.cooperationDto.textMsg, [Validators.required]],
      agreement1: [this.cooperationDto.agreement1, [Validators.requiredTrue]],
      agreement2: [this.cooperationDto.agreement2, [Validators.requiredTrue]],
    });
  }

  onSubmit(): void {
    this.cooperationDto = new CooperationData(this.cooperationFormGroup.value);
    this.apiService
      .sendCooperationEmail(this.cooperationDto)
      .pipe(take(1))
      .subscribe(
        () => {
          this.initializeForm();
          this.confirmationService.confirm({
            message: this.transloco.translate('contact.confirmation.message'),
            rejectVisible: false,
            acceptLabel: this.transloco.translate(
              'contact.confirmation.accept'
            ),
          });
        },
        (error: HttpErrorResponse) => {
          if (error.status === 403) {
            const agreement1 = this.cooperationFormGroup.get('agreement1');
            if (!agreement1.value) {
              agreement1.markAsDirty();
              agreement1.markAllAsTouched();
            }

            const agreement2 = this.cooperationFormGroup.get('agreement2');
            if (!agreement2.value) {
              agreement1.markAsDirty();
              agreement1.markAllAsTouched();
            }
          }
        }
      );
  }
}

import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable()
export class PolicyCreatorInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const origin = req.clone();

    if (!req.url.includes(environment.apiBase)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      tap({
        error: async (error: HttpErrorResponse) => {
          // console.log('PolicyCreatorInterceptor error', error);
          // console.log('PolicyCreatorInterceptor req', req);

          if (error.status === 401) {
            // await this.policyCreatorService.fetchJwtToken().toPromise();
            return next.handle(origin);
          }
        },
      })
    );
  }
}

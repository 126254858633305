import { Injectable } from '@angular/core';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import {
  DepreciationItemC,
  depreciationTable,
} from '../../pages/apk/models/depreciation-table.class';

@Injectable({
  providedIn: 'root',
})
export class DepreciationChartService {
  private depreciationTable = depreciationTable;
  constructor() {}

  public getSeries(
    value: number,
    insurancePeriod: number,
    insuranceLimit: number,
  ): {
    chartSeries: ApexAxisChartSeries;
    chartSeriesCategories: string[];
    chartBarSeries: ApexAxisChartSeries;
  } {
    const response = {
      chartSeries: [
        {
          name: 'Wartość pojazdu',
          data: [value],
        },
      ],
      chartSeriesCategories: ['dzisiaj'],
      chartBarSeries: [
        {
          name: 'offenderInsurance',
          data: [],
        },
        {
          name: 'gapInsurance',
          data: [],
        },
        {
          name: 'insuranceLoss',
          data: [],
        },
      ],
    };

    const insurancePeriodYears = insurancePeriod / 12;

    for (let i = 1; i <= insurancePeriodYears; i++) {
      const priceDecreaseForPeriod = this.depreciationTable.find(
        (item) => item.month === i * 12,
      );
      response.chartSeries[0].data[i] = Math.round(
        value - value * priceDecreaseForPeriod.priceDecrease,
      );
      response.chartSeriesCategories.push(i + ` ${this.getPluralRules(i)}`);
    }

    console.log('getSeries', {
      input: {
        value,
        insurancePeriod,
        insuranceLimit,
      },
      output: {
        depreciationTable: this.depreciationTable,
      },
      response,
    });

    return response;
  }

  getPluralRules(insurancePeriod: number) {
    const enOrdinalRules = new Intl.PluralRules('en-US', { type: 'ordinal' });

    const suffixes = new Map([
      ['one', 'rok'],
      ['two', 'lata'],
      ['few', 'lata'],
      ['many', 'lat'],
    ]);
    const formatOrdinals = (n: number) => {
      const rule = enOrdinalRules.select(n);
      const suffix = suffixes.get(rule);
      return `${suffix}`;
    };

    return formatOrdinals(insurancePeriod);
  }
}

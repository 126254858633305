import { Option } from '../../../../interfaces/dropdown-option.interface';
import * as moment from 'moment';
import { CalculateOfferPayload } from '../../shared/interfaces/calculate-offer-payload.interface';
import { PolicyCreatorStateModel } from '../store/policy-creator.model';
import { checkForInstalments } from './check-for-instalments';
import { FirstStepPayload } from '../../shared/interfaces/first-step-payload.interface';

export function offerPayloadMapper(
  state: PolicyCreatorStateModel,
): CalculateOfferPayload {
  const product = state.portfolios
    .filter(
      (portfolio) =>
        portfolio.productDerivativeAlias ===
        state.firstStepPayload.selectedInsuranceVariant,
    )
    .find((portfolio) => {
      if (
        state.firstStepPayload.vehicleType.code === 'LCV' &&
        state.firstStepPayload.selectedInsuranceVariant !== 'D2C'
      ) {
        return portfolio.productCode.includes('LCV');
      } else {
        return !portfolio.productCode.includes('LCV');
      }
    });

  let usageTypeCode: string;
  const hasOptionSelected = state.firstStepPayload.vehicleType.options.find(
    (option) => option.isSelected,
  );

  if (hasOptionSelected) {
    usageTypeCode = 'BUSINESS';
  } else {
    usageTypeCode = 'INDIVIDUAL';
  }

  let vatReclaimable: string;

  if (state.firstStepPayload.selectedInsuranceVariant === 'D2C') {
    vatReclaimable = 'NO';
  }

  if (state.firstStepPayload.vehiclePriceType) {
    if (state.firstStepPayload.vehiclePriceType === 'Netto') {
      vatReclaimable = 'NO';
    }
    if (state.firstStepPayload.vehiclePriceType === 'Brutto') {
      vatReclaimable = 'NO';
    }
    if (state.firstStepPayload.vehiclePriceType === '50%') {
      usageTypeCode = 'BUSINESS';
      vatReclaimable = 'HALF';
    }
  }

  if (state.firstStepPayload.vehiclePolicyPriceType) {
    if (state.firstStepPayload.vehiclePolicyPriceType === 'Netto') {
      usageTypeCode = 'BUSINESS';
      vatReclaimable = 'YES';
    }
    if (state.firstStepPayload.vehiclePolicyPriceType === 'Brutto') {
      vatReclaimable = 'NO';
    }
    if (state.firstStepPayload.vehiclePolicyPriceType === '50%') {
      usageTypeCode = 'BUSINESS';
      vatReclaimable = 'HALF';
    }
  }

  const usageCode = (options: Option[]): string => {
    const selectedOptionsFound = options
      .filter((option) => option.isSelected)
      .map((option) => option.code);
    if (selectedOptionsFound.length) {
      return selectedOptionsFound[0];
    } else {
      return 'STANDARD';
    }
  };

  const vehiclePrice = (firstStepPayload: FirstStepPayload): number => {
    if (firstStepPayload.selectedInsuranceVariant === 'D2C') {
      return 1;
    }

    if (firstStepPayload.vehiclePolicyPrice) {
      return firstStepPayload.vehiclePolicyPrice <=
        firstStepPayload.vehiclePrice
        ? Number(firstStepPayload.vehiclePolicyPrice) * 100
        : Number(firstStepPayload.vehiclePrice) * 100;
    } else {
      return Number(firstStepPayload.vehiclePrice) * 100;
    }
  };

  const purchasePrice = (vehiclePrice) => {
    if (state.firstStepPayload.selectedInsuranceVariant === 'D2C') {
      return 1;
    }
    if (state.firstStepPayload.vehiclePriceType === 'Netto') {
      return Math.round((vehiclePrice * 1.23) / 100) * 100;
    }
    if (state.firstStepPayload.vehiclePriceType === 'Brutto') {
      return vehiclePrice;
    }
    if (state.firstStepPayload.vehiclePriceType === '50%') {
      return Math.round((vehiclePrice * 1.23) / 100) * 100;
    }
  };

  const purchasePriceNet = (vehiclePrice) => {
    if (state.firstStepPayload.vehiclePriceType === 'Netto') {
      return vehiclePrice;
    }
    if (state.firstStepPayload.vehiclePriceType === 'Brutto') {
      return Math.round(vehiclePrice / 1.23 / 100) * 100;
    }
    if (state.firstStepPayload.vehiclePriceType === '50%') {
      return Math.round(vehiclePrice / 1.115 / 100) * 100;
    }
  };

  const purchasePriceInputType = (firstStepPayload: FirstStepPayload) => {
    if (firstStepPayload.selectedInsuranceVariant === 'D2C') {
      return 'WITH_VAT';
    }

    if (firstStepPayload.vehiclePriceType) {
      return firstStepPayload.vehiclePriceType === 'Brutto'
        ? 'WITH_VAT'
        : 'WITHOUT_VAT';
    }

    if (firstStepPayload.vehiclePolicyPriceType) {
      return firstStepPayload.vehiclePolicyPriceType === 'Brutto'
        ? 'WITH_VAT'
        : 'WITHOUT_VAT';
    }
  };

  return {
    productCode: product.productCode,
    saleInitiatedOn: moment().format(),
    instalmentSplittingSchemeCode: checkForInstalments(state),
    vehicleSnapshot: {
      firstRegisteredOn: moment(state.firstStepPayload.vehicleRegDate).format(
        'YYYY-MM-DD',
      ),
      categoryCode: state.vehicleCategories.find(
        (category) =>
          category.id ===
          state.firstStepPayload.selectedVehicleModel.categoryId,
      ).code,
      purchasePrice: purchasePrice(vehiclePrice(state.firstStepPayload)),
      purchasePriceNet: purchasePriceNet(vehiclePrice(state.firstStepPayload)),
      purchasePriceInputType: purchasePriceInputType(state.firstStepPayload),
      usageTypeCode: usageTypeCode,
      purchasePriceVatReclaimableCode: vatReclaimable,
      usageCode: usageCode(state.firstStepPayload.vehicleType.options),
      modelCode: state.firstStepPayload.selectedVehicleModel.code,
    },
    options: {
      CLAIM_LIMIT: `${state.secondStepPayload.insuranceLimit}`,
      TERM: `${state.secondStepPayload.policyPeriod}`,
      PAYMENT_TERM: state.secondStepPayload.paymentType,
      PAYMENT_METHOD: state.paymentMethod ?? 'PM_BT',
    },
  };
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-is-gap',
  templateUrl: './what-is-gap.component.html',
  styleUrls: ['./what-is-gap.component.scss'],
})
export class WhatIsGapComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

<ng-container *transloco="let t; read: 'home'">
  <div class="p-grid p-nogutter p-jc-center validVehicles">
    <div class="p-col-12 p-text-center validVehicles__header">
      <h1>
        {{ t('validVehicles.header.part1')
        }}<span>{{ t('validVehicles.header.part2') }}</span>
      </h1>
    </div>

    <swiper class="slider">
      <div *ngFor="let vehicle of validVehicles">
        <div class="slider__item">
          <div class="slider__item__content">
            <div class="p-mb-3 slider__item__content__image">
              <img
                src="assets/images/valid-vehicles/{{ vehicle.img }}.png"
                [alt]="vehicle.name"
                class="benefit-image" />
            </div>
            <div class="slider__item__content__description">
              <p class="textBlue p-text-bold">{{ vehicle.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </swiper>
    <div class="p-col-12 p-d-flex p-jc-center backgroundWrapper">
      <div class="p-grid wrapper">
        <div class="p-d-none p-d-md-flex p-md-6 validVehicles__img">
          <img
            src="assets/images/traveller.png"
            alt="{{ t('validVehicles.where.header.part2') }}{{
              t('validVehicles.where.header.part3')
            }}" />
          <img
            class="bgImg p-d-none p-d-xl-block"
            src="assets/images/bg1-img.svg"
            alt="tear" />
        </div>
        <div class="p-col-12 p-col-nogutter p-md-6 p-d-flex p-ai-center">
          <div class="p-grid">
            <div class="p-col-12">
              <h1 style="white-space: pre-wrap">
                {{ t('validVehicles.where.header.part1')
                }}<span>{{ t('validVehicles.where.header.part2') }}</span>
                {{ t('validVehicles.where.header.part3') }}
              </h1>

              <div class="p-col-12 p-d-md-none validVehicles__img">
                <img
                  src="assets/images/traveller.png"
                  alt="{{ t('validVehicles.where.header.part2') }}{{
                    t('validVehicles.where.header.part3')
                  }}" />
              </div>

              <div
                class="validVehicles__where p-col-12 p-d-md-none p-d-xl-block">
                <div class="p-grid p-nogutter">
                  <div class="p-col-12 p-md-6 p-xl-12">
                    <p class="textBlue p-text-bold">
                      {{ t('validVehicles.where.right.1') }}
                    </p>
                    <p>{{ t('validVehicles.where.right.2') }}</p>
                    <p>{{ t('validVehicles.where.right.3') }}</p>
                    <p>{{ t('validVehicles.where.right.4') }}</p>
                  </div>
                  <div class="p-col-12 p-md-6 p-xl-12">
                    <p class="textBlue p-text-bold">
                      {{ t('validVehicles.where.right.5') }}
                    </p>
                    <p>{{ t('validVehicles.where.right.6') }}</p>
                    <p>{{ t('validVehicles.where.right.7') }}</p>
                    <p>{{ t('validVehicles.where.right.8') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="validVehicles__where p-d-none p-md-12 p-d-md-block p-d-xl-none">
          <div class="p-grid p-nogutter">
            <div class="p-col-12 p-md-6 p-xl-12 p-p-md-2">
              <p class="textBlue p-text-bold">
                {{ t('validVehicles.where.right.1') }}
              </p>
              <p>{{ t('validVehicles.where.right.2') }}</p>
              <p>{{ t('validVehicles.where.right.3') }}</p>
              <p>{{ t('validVehicles.where.right.4') }}</p>
            </div>
            <div class="p-col-12 p-md-6 p-xl-12 p-p-md-2">
              <p class="textBlue p-text-bold">
                {{ t('validVehicles.where.right.5') }}
              </p>
              <p>{{ t('validVehicles.where.right.6') }}</p>
              <p>{{ t('validVehicles.where.right.7') }}</p>
              <p>{{ t('validVehicles.where.right.8') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

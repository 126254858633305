export enum PaymentMethodEnum {
  bankTransfer = 'BANK_TRANSFER',
  paidByDealer = 'PAID_BY_DEALER',
  paidToDealer = 'PAID_TO_DEALER',
  firstYearPaidByDealer = '1ST_YEAR_PAID_BY_DEALER',
  payu = 'PAYU',
  payuInstallments = 'PREMIUM_INSTALLMENTS_VIA_PAYU',
}

export interface PaymentMethodOption {
  value: PaymentMethodEnum;
  label: string;
  disabled?: boolean;
}

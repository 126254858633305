import {
  AfterViewInit,
  Component,
  ElementRef,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

export interface Benefits {
  img: string;
  name: string;
}

@Component({
  selector: 'app-benefits-slider',
  templateUrl: './benefits-slider.component.html',
  styleUrls: ['./benefits-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BenefitsSliderComponent implements AfterViewInit {
  benefits: Benefits[];
  index = 0;

  constructor(
    private transloco: TranslocoService,
    private renderer2: Renderer2,
    private elem: ElementRef
  ) {
    this.benefits = [
      {
        img: '1',
        name: this.transloco.translate('home.benefits.slider.slide.1'),
      },
      {
        img: '2',
        name: this.transloco.translate('home.benefits.slider.slide.2'),
      },
      {
        img: '3',
        name: this.transloco.translate('home.benefits.slider.slide.3'),
      },
      {
        img: '4',
        name: this.transloco.translate('home.benefits.slider.slide.4'),
      },
      {
        img: '5',
        name: this.transloco.translate('home.benefits.slider.slide.5'),
      },
      {
        img: '6',
        name: this.transloco.translate('home.benefits.slider.slide.6'),
      },
      {
        img: '7',
        name: this.transloco.translate('home.benefits.slider.slide.7'),
      },
      {
        img: '8',
        name: this.transloco.translate('home.benefits.slider.slide.8'),
      },
    ];
  }

  ngAfterViewInit(): void {
    // const elements = this.elem.nativeElement.querySelectorAll('.swiper-pagination');
    //
    // this.renderer2.listen(elements[0], 'click', (event) => {
    //   console.log('AAAAAAAA');
    //   console.log(event);
    // })
  }
}

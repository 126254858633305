export function calculatePremiumForAnnual(
  premium: number,
  policyPeriod: string,
  premiumDiscounted?: number
): {
  instalment: number;
  paymentPeriod: number;
  premiumCalculated: number;
  premiumDiscounted: number;
} {
  const instalment = premium;
  const premiumCalculated =
    Math.floor(instalment) * (Number(policyPeriod.replace('T_', '')) / 12);
  return {
    instalment,
    paymentPeriod: Number(policyPeriod.replace('T_', '')) / 12,
    premiumCalculated,
    premiumDiscounted: premiumDiscounted ?? null,
  };
}

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import '@angular/common/locales/global/pl';
import {
  LOCALE_ID,
  NgModule,
  APP_INITIALIZER,
  ErrorHandler,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { FacebookModule } from 'ngx-facebook-fb';
import {
  SWIPER_CONFIG,
  SwiperConfigInterface,
  SwiperModule,
} from 'ngx-swiper-wrapper';
import { CheckboxModule } from 'primeng/checkbox';
import { SliderModule } from 'primeng/slider';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SentryInterceptor } from './interceptors/sentry.interceptor';
import { DepreciationChartModule } from './modules/depreciation-chart/depreciation-chart.module';
import { ApkService } from './pages/apk/apk.service';
import { BenefitsSliderComponent } from './pages/home/benefits-slider/benefits-slider.component';
import { BenefitsComponent } from './pages/home/benefits/benefits/benefits.component';
import { ContactComponent } from './pages/home/contact/contact.component';
import { CooperationComponent } from './pages/home/cooperation/cooperation.component';
import { DocumentsComponent } from './pages/home/documents/documents.component';
import { AnswerQuestionComponent } from './pages/home/faq/answer-question/answer-question.component';
import { FaqComponent } from './pages/home/faq/faq.component';
import { ForWhoComponent } from './pages/home/for-who/for-who.component';
import { HomeComponent } from './pages/home/home.component';
import { NotAGapComponent } from './pages/home/not-a-gap/not-a-gap.component';
import { ValidVehiclesComponent } from './pages/home/valid-vehicles/valid-vehicles.component';
import { WhatIsGapComponent } from './pages/home/what-is-gap/what-is-gap.component';
import { WhenToBuyComponent } from './pages/home/when-to-buy/when-to-buy.component';
import { NavBarComponent } from './pages/nav-bar/nav-bar.component';
import { PolicyCreatorInterceptor } from './pages/policy-creator/policy-creator-interceptor';
import { SharedModule } from './pages/shared/shared.module';
import { ApiService } from './services/api.service';
import { GaService } from './services/ga.service';
import { MessengerService } from './services/messenger.service';
import { TranslocoRootModule } from './transloco/transloco-root.module';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 40,
  spaceBetween: 40,
  slidesPerView: 1,
  breakpoints: {
    500: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 5,
    },
  },
  mousewheel: false,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: (index, className) => {
      return `<span class="${className}"></span>`;
    },
  },
  // pagination: { el: '.swiper-pagination', clickable: true },
  autoplay: true,
  navigation: true,
  // loop: true,
  // loopAdditionalSlides: 8
};

const sentryProviders = environment.SENTRY_MONITORING
  ? [
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: SentryInterceptor,
        multi: true,
      },
    ]
  : [];

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    BenefitsComponent,
    WhatIsGapComponent,
    BenefitsSliderComponent,
    NotAGapComponent,
    ForWhoComponent,
    ValidVehiclesComponent,
    WhenToBuyComponent,
    DocumentsComponent,
    FaqComponent,
    ContactComponent,
    CooperationComponent,
    AnswerQuestionComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CheckboxModule,
    FacebookModule.forRoot(),
    HttpClientModule,
    // NgxHotjarRouterModule,
    NgxsModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    SharedModule,
    SliderModule,
    SwiperModule,
    TransferHttpCacheModule,
    TranslocoRootModule,
    DepreciationChartModule,
  ],
  providers: [
    ApiService,
    ApkService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    { provide: LOCALE_ID, useValue: 'pl' },
    {
      provide: 'googleTagManagerId',
      useValue: environment.GTMID,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PolicyCreatorInterceptor,
      multi: true,
    },
    MessengerService,
    ...sentryProviders,
    GaService,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

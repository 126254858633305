import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

export interface ValidVehicles {
  img: string;
  name: string;
}

@Component({
  selector: 'app-valid-vehicles',
  templateUrl: './valid-vehicles.component.html',
  styleUrls: ['./valid-vehicles.component.scss'],
})
export class ValidVehiclesComponent implements OnInit {
  validVehicles: ValidVehicles[];

  constructor(private transloco: TranslocoService) {
    this.validVehicles = [
      {
        img: '1',
        name: this.transloco.translate('home.validVehicles.slide.1'),
      },
      {
        img: '2',
        name: this.transloco.translate('home.validVehicles.slide.2'),
      },
      {
        img: '3',
        name: this.transloco.translate('home.validVehicles.slide.3'),
      },
      {
        img: '4',
        name: this.transloco.translate('home.validVehicles.slide.4'),
      },
      {
        img: '5',
        name: this.transloco.translate('home.validVehicles.slide.5'),
      },
      {
        img: '6',
        name: this.transloco.translate('home.validVehicles.slide.6'),
      },
      {
        img: '7',
        name: this.transloco.translate('home.validVehicles.slide.7'),
      },
      {
        img: '8',
        name: this.transloco.translate('home.validVehicles.slide.8'),
      },
    ];
  }

  ngOnInit(): void {}
}

import { CalculateOfferResponse } from '../../shared/interfaces/calculate-offer-response.interface';
import { CreationLockResponse } from '../../shared/interfaces/creation-lock-response.interface';
import { PolicyCreatorStateModel } from './policy-creator.model';

export interface OfferStateModel {
  offer: CalculateOfferResponse;
  offerLocked: CreationLockResponse;
}

export const defaults: OfferStateModel = {
  offer: null,
  offerLocked: null,
};

export interface OfferStateSnapshot {
  policyCreatorState: PolicyCreatorStateModel;
  offer: CalculateOfferResponse;
  offerLocked: CreationLockResponse;
}

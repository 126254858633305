<ng-container *transloco="let t; read: 'home'">
  <div class="p-grid p-nogutter whenToBuy p-jc-center">
    <div class="wrapper p-grid p-nogutter p-jc-center">
      <div class="p-col-12 p-text-center whenToBuy__header">
        <h1>
          {{ t('whenToBuy.header.part1')
          }}<span>{{ t('whenToBuy.header.part2') }}</span>
        </h1>
      </div>

      <div class="p-col-12 p-md-6 p-p-md-2">
        <p>
          {{ t('whenToBuy.content.left.1') }}
          <span class="textBlue p-text-bold">{{
            t('whenToBuy.content.left.2')
          }}</span>
        </p>
        <p>
          {{ t('whenToBuy.content.left.3') }}
          <span class="textBlue p-text-bold">{{
            t('whenToBuy.content.left.4')
          }}</span>
          {{ t('whenToBuy.content.left.5') }}
        </p>
        <p>
          {{ t('whenToBuy.content.left.6') }}
          <span class="textBlue p-text-bold">{{
            t('whenToBuy.content.left.7')
          }}</span>
          {{ t('whenToBuy.content.left.8') }}
        </p>
      </div>

      <div class="p-col-12 p-md-6 p-p-md-2">
        <p class="textBlue p-text-bold">{{ t('whenToBuy.content.right.1') }}</p>
        <p>{{ t('whenToBuy.content.right.2') }}</p>
        <p class="textBlue p-text-bold">{{ t('whenToBuy.content.right.3') }}</p>
        <button
          type="button"
          class="buttonYellow p-mt-2"
          routerLink="/cta"
          fragment="ctaHeader">
          <img
            src="assets/icons/bucket.svg"
            alt="bucket" />
          {{ t('buyGapButton') }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

export enum PaymentTermEnum {
  LumpSum = 'LumpSum',
  Annual = 'Annual',
  AnnualMonth = 'Annual',
}

export interface PaymentTermOption {
  value: PaymentTermEnum;
  label: string;
  disabled?: boolean;
}

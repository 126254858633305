import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SetWarrantyFlag } from '../warranty-creator/store/warranty-creator.actions';
import { WarrantyCreatorState } from '../warranty-creator/store/warranty-creator.state';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnChanges {
  @Select(WarrantyCreatorState.isWarranty)
  isWarranty$: Observable<boolean>;

  isOpen = false;
  activeFragment = this.activatedRoute.fragment.pipe(share());
  environment = environment;

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private store: Store,
  ) {}

  ngOnChanges() {
    try {
      const warrantySegment = !!this.router
        .getCurrentNavigation()
        .extractedUrl.root.children[
          'primary'
        ].segments?.findIndex((segment) => segment.path === 'new-warranty');
      this.store.dispatch(new SetWarrantyFlag(warrantySegment));
    } catch (e) {
      console.log(e);
    }
  }

  toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }

  closeMenu(): void {
    this.isOpen = false;
  }

  redirectToWarrantiesApk() {
    window.location.replace(environment.apkUrl);
  }
}

export interface GapCalculatorInterface {
  // policyVariant: string;
  vehiclePurchasePrice?: number;
  claimLimit?: number;
  vehicleRegDate: number;
  insTerm: number;
}

export class GapCalculator implements GapCalculatorInterface {
  vehiclePurchasePrice?: number;
  claimLimit?: number;
  vehicleRegDate: number;
  insTerm: number;

  constructor() {
    this.vehicleRegDate = null;
    this.insTerm = null;
  }
}

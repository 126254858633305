<ng-container *transloco="let t; read: 'home'">
  <div class="p-grid p-nogutter documents p-jc-center">
    <div class="p-grid wrapper p-jc-center">
      <div class="p-col-12 p-text-center documents__header">
        <h1>
          {{ t('documents.header.part1')
          }}<span>{{ t('documents.header.part2') }}</span>
        </h1>
      </div>
      <div class="p-col-12 p-md-6 p-xl-3">
        <div class="p-shadow-9 documents__tale">
          <p class="textBlue p-text-bold">{{ t('documents.tale.1.header') }}</p>
          <a
            href="https://gaponline.pl/gapDownloads/owu-gap.pdf"
            download="owu-gap.pdf">
            <button class="buttonWhite">
              <span class="p-text-bold">{{
                t('documents.tale.1.button')
              }}</span>
            </button>
          </a>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-3">
        <div class="p-shadow-9 documents__tale">
          <p class="textBlue p-text-bold">{{ t('documents.tale.2.header') }}</p>
          <a
            href="https://gaponline.pl/gapDownloads/swu-gap-max.pdf"
            download="swu-gap-max.pdf">
            <button class="buttonWhite">
              <span class="p-text-bold">{{
                t('documents.tale.2.button')
              }}</span>
            </button>
          </a>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-3">
        <div class="p-shadow-9 documents__tale">
          <p class="textBlue p-text-bold">{{ t('documents.tale.3.header') }}</p>
          <a
            href="https://gaponline.pl/gapDownloads/swu-gap-max-ac.pdf"
            download="swu-gap-max-ac.pdf">
            <button class="buttonWhite">
              <span class="p-text-bold">{{
                t('documents.tale.3.button')
              }}</span>
            </button>
          </a>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-3">
        <div class="p-shadow-9 documents__tale">
          <p class="textBlue p-text-bold">{{ t('documents.tale.4.header') }}</p>
          <a
            href="https://gaponline.pl/gapDownloads/swu-gap-direct.pdf"
            download="swu-gap-direct.pdf">
            <button class="buttonWhite">
              <span class="p-text-bold">{{
                t('documents.tale.4.button')
              }}</span>
            </button>
          </a>
        </div>
      </div>
      <div class="p-col-12 p-text-center">
        <p>{{ t('documents.description') }}</p>
      </div>
    </div>
  </div>
</ng-container>

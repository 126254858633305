export interface ContactDataInterface {
  name: string;
  brand: string;
  yearOfProduction: number;
  phoneNo: number;
  model: string;
  value: number;
  email: string;
  textMsg: string;
  agreement1: boolean;
  agreement2: boolean;
}

export class ContactData implements ContactDataInterface {
  name: string;
  brand: string;
  yearOfProduction: number;
  phoneNo: number;
  model: string;
  value: number;
  email: string;
  textMsg: string;
  agreement1: boolean;
  agreement2: boolean;

  constructor(data?: ContactDataInterface) {
    this.name = data ? data.name : null;
    this.brand = data ? data.brand : null;
    this.yearOfProduction = data ? Number(data.yearOfProduction) : null;
    this.phoneNo = data ? Number(data.phoneNo) : null;
    this.model = data ? data.model : null;
    this.value = data ? Number(data.value) : null;
    this.email = data ? data.email : null;
    this.textMsg = data ? data.textMsg : null;
    this.agreement1 = data ? data.agreement1 : false;
    this.agreement2 = data ? data.agreement2 : false;
  }
}

export interface CooperationDataInterface {
  name: string;
  taxNo: number;
  phoneNo: number;
  email: string;
  textMsg: string;
  agreement1: boolean;
  agreement2: boolean;
}

export class CooperationData implements CooperationDataInterface {
  name: string;
  taxNo: number;
  phoneNo: number;
  email: string;
  textMsg: string;
  agreement1: boolean;
  agreement2: boolean;

  constructor(data?: ContactDataInterface) {
    this.name = data ? data.name : null;
    this.phoneNo = data ? Number(data.phoneNo) : null;
    this.email = data ? data.email : null;
    this.textMsg = data ? data.textMsg : null;
    this.agreement1 = data ? data.agreement1 : false;
    this.agreement2 = data ? data.agreement2 : false;
  }
}

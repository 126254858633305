<ng-container *transloco="let t; read: 'home'">
  <div class="p-grid p-nogutter p-jc-center notAGap">
    <div class="p-grid p-nogutter wrapper p-jc-center">
      <div class="p-col-12 notAGap__header">
        <h1>
          {{ t('not-a-gap.header.1') }}
          <span>{{ t('not-a-gap.header.2') }}</span>
        </h1>
      </div>
      <div class="p-col-12 notAGap__texts">
        <p>{{ t('not-a-gap.content.1') }}</p>
        <p class="textBlue p-text-bold">
          {{ t('not-a-gap.content.2') }}
          <span class="textYellow">{{ t('not-a-gap.content.3') }}</span>
        </p>
      </div>
      <div class="p-col-12 p-text-center notAGap__charts">
        <div class="p-grid">
          <div class="p-col-12 p-md-6">
            <h2 class="textBlue">
              {{ t('not-a-gap.header.3') }}
            </h2>
            <img
              src="assets/images/chart1.png"
              class="p-p-1"
              alt="chart" />
          </div>
          <div class="p-col-12 p-md-6">
            <h2 class="textBlue">
              {{ t('not-a-gap.header.4') }}
            </h2>
            <img
              src="assets/images/chart2.png"
              alt="chart" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import {
  Extras,
  WarrantyCalculateOfferPayload,
} from '../../shared/interfaces/calculate-offer-payload.interface';
import { CalculateOfferResponse } from '../../shared/interfaces/calculate-offer-response.interface';
import { CreationLockResponse } from '../../shared/interfaces/creation-lock-response.interface';
import {
  EnabledPortfolio,
  EnabledPortfoliosResponse,
  EnabledPortfolioWithOffers,
} from '../../shared/interfaces/enabled-portfolios-response.interface';
import { WarrantySecondStepPayload } from '../../shared/interfaces/second-step-payload.interface';
import { EXCLUDED_FROM_WARRANTY } from '../common/const/excluded-portfolios';
import { PriceToDisplay } from '../common/interfaces/price-to-display.interface';
import { assignNewDiscountAmount } from '../helpers/assign-new-discount-amount';
import { calculatePremiumForInstalments } from '../helpers/calculate-premium-for-instalments';
import { WarrantyCreatorService } from '../warranty-creator.service';
import { IdefendWarningCode } from './enums/idefend-warning-code.enum';
import { WarrantyCreatorState } from './warranty-creator.state';
import {
  ApplyAffiliationDiscount,
  ApplyDiscountCode,
  CalculateWarrantyOffer,
  GetPolicyByNumber,
  GetWarrantyPolicy,
  LockOffer,
  ResetWarrantyOfferState,
  SavePolicy,
  SavePortfoliosWithOffers,
  SelectWarrantyOffer,
  SetEnabledPortfolios,
  SetLoadingState,
  SignOffer,
} from './warranty-offer.actions';
import { defaults, WarrantyOfferStateModel } from './warranty-offer.model';

@State<WarrantyOfferStateModel>({
  name: 'warrantyOfferState',
  defaults,
})
@Injectable()
export class WarrantyOfferState {
  constructor(
    private warrantyCreatorService: WarrantyCreatorService,
    private store: Store,
  ) {}

  @Selector()
  static state(state: WarrantyOfferStateModel): WarrantyOfferStateModel {
    return state;
  }

  @Selector()
  static isLoading({ isLoading }: WarrantyOfferStateModel): boolean {
    return isLoading;
  }

  @Selector()
  static warrantyOfferLocked({
    warrantyOfferLocked,
  }: WarrantyOfferStateModel): CreationLockResponse {
    return warrantyOfferLocked;
  }

  @Selector()
  static warrantyOfferSelected({
    enabledPortfoliosWithOffers,
  }: WarrantyOfferStateModel): EnabledPortfolioWithOffers {
    return enabledPortfoliosWithOffers.find(
      (portfolio) => portfolio.isSelected,
    );
  }

  @Selector()
  static enabledPortfolios({
    enabledPortfolios,
  }: WarrantyOfferStateModel): EnabledPortfolio[] {
    return enabledPortfolios;
  }

  @Selector()
  static enabledPortfoliosWithOffers({
    enabledPortfoliosWithOffers,
  }: WarrantyOfferStateModel): EnabledPortfolioWithOffers[] {
    return enabledPortfoliosWithOffers;
  }

  @Action(SetEnabledPortfolios, { cancelUncompleted: true })
  public setEnabledPortfolios(
    { patchState }: StateContext<WarrantyOfferStateModel>,
    { payload }: SetEnabledPortfolios,
  ) {
    const rejectedProducts = this.store
      .selectSnapshot(WarrantyCreatorState.getProductOffersBySodanId)
      .rejectedProducts.map((product) => product.code);

    return this.warrantyCreatorService.setEnabledPortfolios(payload).pipe(
      tap((enabledPortfolios: EnabledPortfoliosResponse) => {
        const enabledPortfoliosOnly: EnabledPortfolio[] =
          enabledPortfolios.enabledPortfolios.filter((portfolio) => {
            return (
              portfolio.enabled &&
              // WARRANTY_PORTFOLIOS.includes(portfolio.productGroupCode) &&
              !EXCLUDED_FROM_WARRANTY.includes(portfolio.productCode) &&
              !rejectedProducts.includes(portfolio.productCode)
            );
          });

        const enabledPortfoliosInactive: EnabledPortfolio[] =
          enabledPortfolios.enabledPortfolios.filter((portfolio) => {
            return !portfolio.enabled;
          });
        patchState({
          enabledPortfolios: enabledPortfoliosOnly,
          enabledPortfoliosInactive: enabledPortfoliosInactive,
          isLoading: false,
        });
      }),
    );
  }

  @Action(SavePortfoliosWithOffers, { cancelUncompleted: true })
  public savePortfoliosWithOffers(
    { getState, patchState }: StateContext<WarrantyOfferStateModel>,
    { portfolios }: SavePortfoliosWithOffers,
  ) {
    const enabledPortfoliosWithOffers = getState().enabledPortfoliosWithOffers;
    const selectedPortfolioOffer = enabledPortfoliosWithOffers.find(
      (portfolio) => {
        return portfolio.isSelected;
      },
    );

    const portfoliosDto = portfolios.map((portfolio) => {
      if (
        selectedPortfolioOffer &&
        selectedPortfolioOffer.productCode === portfolio.productCode
      ) {
        return {
          ...portfolio,
          isSelected: true,
        };
      }
      return portfolio;
    });

    patchState({ enabledPortfoliosWithOffers: portfoliosDto });
  }

  @Action(SelectWarrantyOffer)
  public selectWarrantyOffer(
    { getState, patchState }: StateContext<WarrantyOfferStateModel>,
    { productCode }: SelectWarrantyOffer,
  ): CalculateOfferResponse | void {
    const { enabledPortfoliosWithOffers } = getState();
    const portfoliosToUpdate = enabledPortfoliosWithOffers.map((portfolio) => {
      portfolio.isSelected = portfolio.productCode === productCode;
      return portfolio;
    });

    patchState({ enabledPortfoliosWithOffers: portfoliosToUpdate });
  }

  /* Applies the calculation to the enabledPortfolios state */
  @Action(CalculateWarrantyOffer, { cancelUncompleted: false })
  public calculateWarrantyOffer({
    getState,
    patchState,
  }: StateContext<WarrantyOfferStateModel>): void {
    patchState({ isLoading: true });
    const enabledPortfolios = getState().enabledPortfolios;
    const {
      annualMileage,
      insuranceLimitCode,
      deductible,
      paymentForm,
      paymentType,
      policyPeriod,
      additionalOptions,
    } = this.store.selectSnapshot(WarrantyCreatorState.secondStepPayload);

    const vehicleSnapshot = this.store.selectSnapshot(
      WarrantyCreatorState.getVehicleSnapshot,
    );
    const promises = [];
    const extras = additionalOptions?.reduce((acc, curr) => {
      if (curr === 'RENTAL' || curr === 'CNG_LPG') {
        return {
          ...acc,
          [curr]: true,
        };
      }
    }, {}) as Extras;

    const crystalExtras: Extras = additionalOptions?.reduce((acc, curr) => {
      if (curr === 'RENTAL' || curr === 'CNG_LPG') {
        return {
          ...acc,
          [curr]: true,
        };
      }
    }, {}) as Extras;

    const infotainmentExtra = additionalOptions?.find((extra) => {
      if (extra.match('INFOTAINMENT')) {
        return extra;
      }
    });

    enabledPortfolios.forEach((portfolio, index) => {
      const payload: WarrantyCalculateOfferPayload = {
        productCode: portfolio.productCode,
        endorsementTypeCode: 'NEW_BUSINESS',
        saleInitiatedOn: moment().format(),
        options: {
          ANNUAL_MILEAGE: annualMileage,
          CLAIM_LIMIT: insuranceLimitCode,
          DEDUCTIBLE: deductible,
          PAYMENT_METHOD: paymentForm.value,
          PAYMENT_TERM: paymentType,
          TERM: policyPeriod,
        },
        vehicleSnapshot: vehicleSnapshot,
      };

      // NOTE: Excluded from crystal
      if (portfolio.productCode !== '5_CP_CRY_GEN') {
        if (infotainmentExtra) {
          payload.options['INFOTAINMENT'] = infotainmentExtra;
        }
        payload.extras = extras;
      }

      if (portfolio.productCode === '5_CP_CRY_GEN') {
        payload.extras = crystalExtras;
      }

      promises.push(this.warrantyCreatorService.getOffer(payload).toPromise());
    });

    Promise.all(promises).then((warrantyOffers: CalculateOfferResponse[]) => {
      const enabledPortfoliosWithOffers =
        getState().enabledPortfoliosWithOffers;
      const isAnyOfferSelected = enabledPortfoliosWithOffers.find(
        (offer) =>
          offer.isSelected &&
          enabledPortfolios.find(
            (portfolio) => portfolio.productCode === offer.productCode,
          ),
      );
      const portfoliosWithOffersToPatch: EnabledPortfolioWithOffers[] =
        warrantyOffers.map((offer, index) => {
          if (offer.warnings && offer.warnings.length) {
            console.log('offer warnings', offer.warnings);
          }
          return {
            ...enabledPortfoliosWithOffers.find(
              (portfolio) => portfolio.productCode === offer.productCode,
            ),
            calculation: offer,
            isSelected:
              enabledPortfoliosWithOffers.find(
                (enabledPortfolio) =>
                  enabledPortfolio.productCode === offer.productCode,
              )?.isSelected ||
              (!isAnyOfferSelected && index === 0),
            productCode: offer.productCode,
            prices: {
              ...this.setPrices(
                offer,
                this.store.selectSnapshot(
                  WarrantyCreatorState.secondStepPayload,
                ),
              ),
              isSelected: false,
            },
          };
        });

      patchState({
        enabledPortfoliosWithOffers: portfoliosWithOffersToPatch,
        isLoading: false,
      });
    });
  }

  private setPrices(
    offer: CalculateOfferResponse,
    secondStepPayload: WarrantySecondStepPayload,
  ): PriceToDisplay {
    let prices: PriceToDisplay = null;

    if (offer && secondStepPayload) {
      // standard
      if (
        secondStepPayload.paymentForm?.value === 'PM_ONLINE' ||
        secondStepPayload.paymentForm?.value === 'PM_BT'
      ) {
        prices = this.handleIdefendCalculationWarnings(offer.warnings, {
          premiumCalculated: offer.premiumSuggested,
          instalment: null,
          paymentPeriod: null,
          premiumDiscounted: offer.premiumDiscounted,
          productCode: offer.productCode,
        });
      }

      // instalments
      if (secondStepPayload.paymentForm?.value === 'PM_PAYU_M') {
        prices = this.handleIdefendCalculationWarnings(offer.warnings, {
          premiumCalculated: offer.premiumSuggested,
          instalment: Number(secondStepPayload.policyPeriod.replace('T_', '')),
          paymentPeriod: Number(
            secondStepPayload.policyPeriod.replace('T_', ''),
          ),
          premiumDiscounted: offer.premiumDiscounted,
          productCode: offer.productCode,
        });

        const pricesForInstalment = {
          ...calculatePremiumForInstalments(
            prices.premiumCalculated,
            Number(secondStepPayload.policyPeriod.replace('T_', '')),
            prices.premiumDiscounted,
          ),
          productCode: offer.productCode,
        };

        console.log('settings prices for instalments');
        prices = pricesForInstalment;
      }
    }

    return prices;
  }

  @Action(SignOffer)
  public signOffer(
    { getState, patchState }: StateContext<WarrantyOfferStateModel>,
    { id, payload }: SignOffer,
  ) {
    const warrantyOfferLockedState = getState().warrantyOfferLocked;
    return this.warrantyCreatorService.signOffer(id, payload).pipe(
      take(1),
      tap({
        next: () => {
          patchState({
            warrantyOfferLocked: {
              ...warrantyOfferLockedState,
              signatureState: 'confirmed',
            },
          });
        },
      }),
    );
  }

  @Action(LockOffer)
  public lockOffer(
    ctx: StateContext<WarrantyOfferStateModel>,
    { payload }: LockOffer,
  ): Observable<CreationLockResponse> {
    return this.warrantyCreatorService.lockOffer(payload).pipe(
      tap({
        next: (warrantyOffer) => {
          ctx.patchState({ warrantyOfferLocked: warrantyOffer });
          return warrantyOffer;
        },
        error: (warrantyOffer) => {
          ctx.patchState({ warrantyOfferLocked: null });
          return warrantyOffer;
        },
      }),
    );
  }

  @Action(GetPolicyByNumber)
  public getPolicyByNumber(
    ctx: StateContext<WarrantyOfferStateModel>,
    { policyNo }: GetPolicyByNumber,
  ) {
    return this.warrantyCreatorService.getPolicyByNumber(policyNo).pipe(
      take(1),
      tap({
        next: (res) => {
          if (res && res.length > 0) {
            ctx.patchState({
              warrantyOfferLocked: {
                ...res[0],
              },
            });
          }
        },
      }),
    );
  }

  @Action(GetWarrantyPolicy)
  public getWarrantyPolicy(
    ctx: StateContext<WarrantyOfferStateModel>,
    { id }: GetWarrantyPolicy,
  ): Observable<CreationLockResponse> {
    return this.warrantyCreatorService.getPolicy(id).pipe(
      tap({
        next: (policy) => {
          ctx.patchState({
            warrantyOfferLocked: {
              ...policy,
            },
          });
          return policy;
        },
        error: () => {
          ctx.patchState({ warrantyOfferLocked: null });
          return null;
        },
      }),
    );
  }

  @Action(SavePolicy)
  public savePolicy(
    ctx: StateContext<WarrantyOfferStateModel>,
    { policy }: SavePolicy,
  ) {
    ctx.patchState({
      warrantyOfferLocked: {
        ...policy,
      },
    });
  }

  @Action(ResetWarrantyOfferState)
  public resetState({ setState }: StateContext<WarrantyOfferStateModel>) {
    return setState(defaults);
  }

  @Action(ApplyDiscountCode)
  public applyDiscountCode(
    { getState, patchState }: StateContext<WarrantyOfferStateModel>,
    { code, amount }: ApplyDiscountCode,
  ) {
    const warrantyOffers = getState().enabledPortfoliosWithOffers.map(
      (warrantyOffer) => {
        // discountCode -> state.enabledPortfoliosWithOffers[0].calculation.affiliateCode
        // discountAmount -> state.enabledPortfoliosWithOffers[0].prices.discountAmount
        // premiumSuggested -> state.enabledPortfoliosWithOffers[0].calculation.premiumSuggested
        // premiumMin -> state.enabledPortfoliosWithOffers[0].calculation.premiumMin
        // premiumDiscounted -> state.enabledPortfoliosWithOffers[0].prices.premiumDiscounted

        if (!warrantyOffer.calculation.affiliateCode) {
          warrantyOffer.calculation.affiliateCode = code;
          warrantyOffer.prices.discountAmount = amount;
          // Number(amount) > Number(warrantyOffer.discountAmount)
          //   ? amount
          //   : warrantyOffer.discountAmount;
        }

        if (!amount) {
          warrantyOffer.calculation.affiliateCode = null;
          warrantyOffer.prices.discountAmount = null;
          return warrantyOffer;
        }

        if (warrantyOffer.calculation.premiumSuggested) {
          const discountValue =
            warrantyOffer.calculation.premiumSuggested -
            (warrantyOffer.calculation.premiumSuggested * Number(amount)) / 100;

          if (discountValue >= warrantyOffer.calculation.premiumMin) {
            warrantyOffer.prices.premiumDiscounted = discountValue;
          } else {
            warrantyOffer.prices.premiumDiscounted =
              warrantyOffer.calculation.premiumMin;
          }
        }

        return warrantyOffer;
      },
    );

    patchState({
      enabledPortfoliosWithOffers: warrantyOffers,
    });
  }

  @Action(ApplyAffiliationDiscount)
  public applyAffiliationDiscount(
    { getState, patchState }: StateContext<WarrantyOfferStateModel>,
    { link, amount }: ApplyAffiliationDiscount,
  ) {
    const warrantyOffers = getState().warrantyOffers.map((warrantyOffer) => {
      if (!amount) {
        return {
          ...warrantyOffer,
          discountAmount: null,
          premiumDiscounted: null,
        };
      }

      if (warrantyOffer.premiumSuggested && !!amount) {
        const discountValue =
          warrantyOffer.premiumSuggested -
          (warrantyOffer.premiumSuggested *
            Number(warrantyOffer.discountAmount)) /
            100;
        if (discountValue >= warrantyOffer.premiumMin) {
          warrantyOffer.premiumDiscounted = discountValue;
        }
      }

      return {
        ...warrantyOffer,
        discountLink: link,
        discountAmount: assignNewDiscountAmount(amount, warrantyOffer),
      };
    });

    patchState({
      warrantyOffers,
    });
  }

  @Action(SetLoadingState)
  public setLoadingState(
    { patchState }: StateContext<WarrantyOfferStateModel>,
    { isLoading }: SetLoadingState,
  ) {
    patchState({ isLoading });
  }

  private handleIdefendCalculationWarnings(
    warnings: {
      code: IdefendWarningCode;
      message: string;
    }[],
    prices: PriceToDisplay,
  ): PriceToDisplay {
    let dto: PriceToDisplay = { ...prices };
    if (warnings && warnings.length) {
      warnings.find((warning) => {
        // #Warning: ANNUAL_LIMIT_EXCEEDED
        if (warning.code === IdefendWarningCode.ANNUAL_LIMIT_EXCEEDED) {
          const priceLimitRegex = /[0-9]*\.[0-9]+,[0-9]+/gm;
          const priceLimit = warning.message.match(priceLimitRegex)[0];
          const dotRegex = /\./g;
          const commaRegex = /,/g;
          const withoutDots =
            // @ts-ignore
            priceLimit.replaceAll(dotRegex, '');
          const withoutComma = withoutDots.replace(commaRegex, '');
          const priceLimitNumber = Number(withoutComma);

          console.warn(`annual limit exceeded, max value: ${priceLimitNumber}`);

          dto = {
            ...prices,
            warnings: warnings,
            premiumCalculated: priceLimitNumber,
            premiumDiscounted: prices.premiumDiscounted,
          };
        }
      });
    }

    return dto;
  }

  private hasOfferWarnings(
    warnings: {
      code: IdefendWarningCode;
      message: string;
    }[],
  ) {
    if (warnings && warnings.length) {
      return warnings.map((warning) => warning.code);
    }
    return false;
  }
}

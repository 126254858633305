import * as moment from 'moment';
import { FirstStepPayload } from '../../shared/interfaces/first-step-payload.interface';
import { FourthStepPayload } from '../../shared/interfaces/fourth-step-payload.interface';
import { WarrantySecondStepPayload } from '../../shared/interfaces/second-step-payload.interface';
import {
  SummaryData,
  SummaryEntry,
} from '../../shared/interfaces/summary-data.interface';
import {
  PersonalDataFormFields,
  ThirdStepPayload,
} from '../../shared/interfaces/third-step-payload.interface';
import { CurrencyPipe } from '../../shared/pipes/currency.pipe';
import { DatePipe } from '../../shared/pipes/date.pipe';
import { NumberPipe } from '../../shared/pipes/number.pipe';

export function mapSummaryData(
  firstStepPayload: FirstStepPayload,
  secondStepPayload: WarrantySecondStepPayload,
  thirdStepPayload: ThirdStepPayload,
  fourthStepPayload: FourthStepPayload
): SummaryData {
  try {
    const debug = false;
    const datePipe = new DatePipe();
    const currencyPipe = new CurrencyPipe();
    const numberPipe = new NumberPipe();

    const handlePaymentType = (type: string): string => {
      switch (type) {
        case 'PT_LS': {
          return 'Jednorazowa za cały okres ubezpieczenia';
        }
        case 'PT_A': {
          return 'Ratalna roczna';
        }
        case 'PT_LS_M': {
          return 'Ratalna miesięczna (wymagana karta kredytowa/debetowa)';
        }
        default: {
          return '';
        }
      }
    };

    const handlePolicyPeriodPostfix = (policyPeriod: number): string => {
      const policyPeriodInYears = policyPeriod / 12;
      let postfix = 'rok';
      if (
        policyPeriodInYears === 2 ||
        policyPeriodInYears === 3 ||
        policyPeriodInYears === 4
      ) {
        postfix = 'lata';
      }
      if (policyPeriodInYears > 4) {
        postfix = 'lat';
      }
      return `${policyPeriodInYears} ${postfix}`;
    };

    const getClientData = (): PersonalDataFormFields => {
      let clientData;
      if (thirdStepPayload.insured.sameAsInsurer) {
        clientData = thirdStepPayload.insurer;
      } else {
        clientData = thirdStepPayload.insured;
      }
      return clientData;
    };
    const getInsuredName = (): string => {
      let clientData;
      if (thirdStepPayload.insured.sameAsInsurer) {
        clientData = thirdStepPayload.insurer;
      } else {
        clientData = thirdStepPayload.insured;
      }

      return (
        `${clientData.firstName || ''} ${clientData.lastName || ''}` || null
      );
    };

    const getClientAddress = (): string => {
      const clientData = getClientData();
      return `${clientData.address1} ${clientData.address2 ?? ''}, ${
        clientData.postalCode
      }, ${clientData.city}`;
    };

    const getInsuredData = (): PersonalDataFormFields => {
      if (thirdStepPayload.insured.sameAsInsurer) {
        return thirdStepPayload.insurer;
      } else {
        return thirdStepPayload.insured;
      }
    };
    // End helpers functions-----------------------------------------------------

    // FIXME: mapper shouldn't rely on hardcoded values
    const insuranceInfoValues: SummaryEntry[] = [
      {
        label: 'Typ ubezpieczenia',
        value: '',
      },
      {
        label: 'Długość ubezpieczenia',
        value: handlePolicyPeriodPostfix(
          (firstStepPayload.mfrWarrantyPeriod?.id || 0) +
            Number(secondStepPayload.policyPeriod.replace('T_', ''))
        ),
      },
      {
        label: 'Limit odszkodowania',
        value: secondStepPayload.insuranceLimitCode,
      },
      {
        label: 'Data rozpoczęcia okresu ubezpieczenia',
        value: datePipe.transform(new Date()),
      },
      {
        label: 'Data końcowa ubezpieczenia',
        value: datePipe.transform(
          moment()
            .add(
              (firstStepPayload.mfrWarrantyPeriod?.id || 0) +
                Number(secondStepPayload.policyPeriod.replace('T_', '')),
              'months'
            )
            .toDate()
        ),
      },
      {
        label: 'Cena ubezpieczenia',
        value: currencyPipe.transform(fourthStepPayload.premiumSuggested / 100),
      },
      {
        label: 'Rodzaj płatności',
        value: handlePaymentType(secondStepPayload.paymentType),
      },
    ];

    if (debug) console.log('1. insuranceInfoValues', insuranceInfoValues);

    const vehicleInfoValues: SummaryEntry[] = [
      {
        label: 'Wartość bieżąca pojazdu',
        value: firstStepPayload.vehiclePrice
          ? currencyPipe.transform(firstStepPayload.vehiclePrice)
          : null,
      },
      {
        label: 'Data zakupu pojazdu',
        value: firstStepPayload.vehicleBuyDate
          ? datePipe.transform(firstStepPayload.vehicleBuyDate)
          : null,
      },
      {
        label: 'Kategoria pojazdu',
        value: firstStepPayload.vehicleType.name,
      },
      {
        label: 'Numer VIN',
        value: fourthStepPayload.vin.toUpperCase(),
      },
      {
        label: 'Data pierwszej rejestracji',
        value: datePipe.transform(firstStepPayload.vehicleRegDate),
      },
      {
        label: 'Aktualny przebieg',
        value: `${numberPipe.transform(firstStepPayload.mileage)} km`,
      },
      {
        label: 'Marka pojazdu',
        value: firstStepPayload.selectedVehicleMake.name,
      },
      {
        label: 'Model pojazdu',
        value: firstStepPayload.selectedVehicleModel.name,
      },
    ];

    const insurerInfoValues: SummaryEntry[] = [
      {
        label: 'Typ osoby',
        value:
          thirdStepPayload.insurer.clientType === 'individual'
            ? 'Osoba fizyczna'
            : 'Działalność gospodarcza',
      },
      {
        label: 'Imię i nazwisko',
        value:
          thirdStepPayload.insurer.firstName ||
          thirdStepPayload.insurer.lastName
            ? `${thirdStepPayload.insurer.firstName ?? ''} ${
                thirdStepPayload.insurer.lastName ?? ''
              }`
            : '',
      },
      {
        label: 'Nazwa',
        value: thirdStepPayload.insurer.companyName || '',
      },
      {
        label: 'PESEL / NIP',
        value: thirdStepPayload.insurer.idNumber,
      },
      {
        label: 'Numer telefonu',
        value: `${thirdStepPayload.insurer.phonePrefix}${thirdStepPayload.insurer.phone}`,
      },
      {
        label: 'E-mail',
        value: thirdStepPayload.insurer.email,
      },
      {
        label: 'Adres',
        value: `${thirdStepPayload.insurer.address1} ${
          thirdStepPayload.insurer.address2 ?? ''
        }, ${thirdStepPayload.insurer.postalCode}, ${
          thirdStepPayload.insurer.city
        }`,
      },
    ];

    const insuredInfoValues: SummaryEntry[] = thirdStepPayload.insured
      .sameAsInsured
      ? insurerInfoValues
      : [
          {
            label: 'Typ osoby',
            value:
              thirdStepPayload.insured.clientType === 'individual'
                ? 'Osoba fizyczna'
                : 'Działalność gospodarcza',
          },
          {
            label: 'Imię i nazwisko',
            value: `${getInsuredData().firstName || ''} ${
              getInsuredData().lastName || ''
            }`,
          },
          {
            label: 'Nazwa',
            value: getInsuredData().companyName || '',
          },
          {
            label: 'PESEL / NIP',
            value: getInsuredData().idNumber || '',
          },
          {
            label: 'Numer telefonu',
            value: `${getInsuredData().phonePrefix} ${getInsuredData().phone}`,
          },
          {
            label: 'E-mail',
            value: getInsuredData().email,
          },
          {
            label: 'Adres',
            value: `${getInsuredData().address1} ${
              getInsuredData().address2 ?? ''
            }, ${getInsuredData().postalCode}, ${getInsuredData().city}`,
          },
          {
            label: 'Ubezpieczający jest również',
            value: thirdStepPayload.insured.sameAsInsurer
              ? 'Ubezpieczony'
              : null,
          },
        ];

    if (debug) console.log('3. insuredInfoValues', insuredInfoValues);

    const ownerInfoValues: SummaryEntry[] = [
      {
        label: 'Typ osoby',
        value:
          thirdStepPayload.insured.clientType === 'individual'
            ? 'Osoba fizyczna'
            : 'Działalność gospodarcza',
      },
      {
        label: 'Imię i nazwisko',
        value: getInsuredName(),
      },
      {
        label: 'Nazwa',
        value: getClientData().companyName || '',
      },
      {
        label: 'PESEL / NIP',
        value: getClientData().idNumber,
      },
      {
        label: 'Adres',
        value: getClientAddress(),
      },
    ];

    if (debug) console.log('5. insurerInfoValues', insurerInfoValues);

    return {
      insuranceInfo: {
        label: 'Informacje o ubezpieczeniu',
        values: insuranceInfoValues,
      },
      vehicleInfo: {
        label: 'Informacje o pojeździe',
        values: vehicleInfoValues,
      },
      insurerInfo: {
        label: 'Informacje o ubezpieczającym',
        values: insurerInfoValues,
      },
      insuredInfo: thirdStepPayload.insured.sameAsInsurer
        ? {
            label: '',
            values: [],
          }
        : {
            label: 'Informacje o ubezpieczonym',
            values: insuredInfoValues,
          },
      ownerInfo: {
        label: 'Informacje o właścicielu',
        values: ownerInfoValues,
      },
    };
  } catch (e) {
    console.error('error mapping summary data', e);
  }
}

<ng-container *transloco="let t; read: 'home'">
  <div class="whatIsGap p-grid p-nogutter p-jc-center">
    <div class="p-grid p-nogutter p-jc-center whatIsGap__wrapper">
      <div class="p-col-12">
        <h1 class="textBlue p-text-center p-text-uppercase title">
          {{ t('whatIsGap.header1')
          }}<span class="textYellow">{{ t('whatIsGap.header2') }}</span>
        </h1>
        <div class="p-grid p-nogutter">
          <div class="p-sm-12 p-xl-4 whatIsGap__section">
            <div class="p-grid">
              <div class="p-sm-12 p-md-6 p-xl-12 whatIsGap__section__column">
                <div class="whatIsGap__section__header">
                  <h1 class="textYellow">01</h1>
                  <p class="textBlue">{{ t('whatIsGap.section1.header') }}</p>
                </div>
                <div class="whatIsGap__section__content">
                  <p>{{ t('whatIsGap.section1.content') }}</p>
                </div>
              </div>
              <div class="p-sm-12 p-md-6 p-xl-12 whatIsGap__section__img">
                <img
                  src="assets/images/s1-img.png"
                  alt="s1-img" />
              </div>
            </div>
          </div>

          <div class="p-sm-12 p-xl-4 whatIsGap__section">
            <div class="p-grid">
              <div class="p-sm-12 p-md-6 p-xl-12 whatIsGap__section__column">
                <div class="whatIsGap__section__header">
                  <h1 class="textYellow">02</h1>
                  <p class="textBlue">{{ t('whatIsGap.section2.header') }}</p>
                </div>
                <div class="whatIsGap__section__content">
                  <p>
                    {{ t('whatIsGap.section2.content1') }}
                    <a
                      target="_blank"
                      href="https://gaponline.pl/gapDownloads/dane-wypadki.png"
                      class="textYellow p-text-bold"
                      >{{ t('whatIsGap.section2.content2') }}</a
                    >
                    {{ t('whatIsGap.section2.content3') }}
                  </p>
                </div>
              </div>
              <div class="p-sm-12 p-md-6 p-xl-12 whatIsGap__section__img">
                <img
                  src="assets/images/s2-img.png"
                  alt="s2-img" />
              </div>
            </div>
          </div>

          <div class="p-sm-12 p-xl-4 whatIsGap__section">
            <div class="p-grid">
              <div class="p-sm-12 p-md-6 p-xl-12 whatIsGap__section__column">
                <div class="whatIsGap__section__header">
                  <h1 class="textYellow">03</h1>
                  <p class="textBlue">{{ t('whatIsGap.section3.header') }}</p>
                </div>
                <div class="whatIsGap__section__content">
                  <p>{{ t('whatIsGap.section3.content') }}</p>
                </div>
              </div>
              <div class="p-sm-12 p-md-6 p-xl-12 whatIsGap__section__img">
                <img
                  src="assets/images/s3-img.png"
                  alt="s3-img" />
              </div>
            </div>
          </div>
        </div>

        <div class="p-grid p-nogutter whatIsGap__section paddingTop">
          <div
            class="p-d-none p-d-md-flex p-md-6 whatIsGap__section__img width50">
            <img
              class="imgBig"
              src="assets/images/suv-img.png"
              alt="suv-img" />
            <img
              class="bgImg p-d-none p-d-xl-block"
              src="assets/images/bg1-img.svg"
              alt="tear" />
          </div>

          <div class="p-col-12 p-md-6 whatIsGap__section__gapHeader">
            <h1 class="textBlue p-text-normal">
              {{ t('whatIsGap.gap.header.1') }}
            </h1>
            <h1 class="textBlue">
              {{ t('whatIsGap.gap.header.2') }}
              <span class="textYellow">{{ t('whatIsGap.gap.header.3') }}</span>
            </h1>
            <p class="textBlue p-text-bold">
              {{ t('whatIsGap.gap.header.4') }}
            </p>
            <div class="p-d-flex">
              <button
                type="button"
                class="buttonYellow p-my-2 p-mr-2 p-px-xl-2"
                routerLink="/cta"
                fragment="ctaHeader">
                {{ t('whatIsGap.gap.header.button') }}
              </button>
              <button
                type="button"
                class="buttonYellow p-d-none p-d-xl-inline-block p-my-2 p-px-xl-2"
                routerLink="/cta"
                fragment="ctaHeader">
                <img
                  src="assets/icons/bucket.svg"
                  alt="bucket" />
                {{ t('tab.content.form.buy-gap') }}
              </button>
            </div>

            <div class="p-d-flex p-d-md-none whatIsGap__section__img">
              <img
                class="imgBig"
                src="assets/images/suv-img.png"
                alt="suv-img" />
            </div>

            <div class="p-d-md-none p-d-xl-block">
              <p class="textBlue p-text-bold">
                {{ t('whatIsGap.gap.content.1') }}
              </p>
              <p class="textBlue p-text-bold">
                {{ t('whatIsGap.gap.content.2') }}
              </p>
              <p>
                {{ t('whatIsGap.gap.content.3') }}
                <span class="textBlue p-text-bold">{{
                  t('whatIsGap.gap.content.4')
                }}</span>
                {{ t('whatIsGap.gap.content.5') }}
              </p>
            </div>
          </div>
          <div class="p-d-none p-d-md-block p-md-12 p-d-xl-none">
            <p class="textBlue p-text-bold">
              {{ t('whatIsGap.gap.content.1') }}
            </p>
            <p class="textBlue p-text-bold">
              {{ t('whatIsGap.gap.content.2') }}
            </p>
            <p>
              {{ t('whatIsGap.gap.content.3') }}
              <span class="textBlue p-text-bold">{{
                t('whatIsGap.gap.content.4')
              }}</span>
              {{ t('whatIsGap.gap.content.5') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

import { CalculateOfferResponse } from '../../shared/interfaces/calculate-offer-response.interface';
import { CreationLockResponse } from '../../shared/interfaces/creation-lock-response.interface';
import {
  EnabledPortfolio,
  EnabledPortfolioWithOffers,
} from '../../shared/interfaces/enabled-portfolios-response.interface';

export interface WarrantyOfferStateModel {
  enabledPortfolios: EnabledPortfolio[];
  enabledPortfoliosInactive: EnabledPortfolio[];
  enabledPortfoliosWithOffers: EnabledPortfolioWithOffers[];
  warrantyOffers: CalculateOfferResponse[];
  warrantyOfferLocked: CreationLockResponse;
  isLoading: boolean;
}

export const defaults: WarrantyOfferStateModel = {
  enabledPortfolios: [],
  enabledPortfoliosInactive: [],
  enabledPortfoliosWithOffers: [],
  warrantyOffers: [],
  warrantyOfferLocked: null,
  isLoading: false,
};

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'CurrencyPipe' })
export class CurrencyPipe implements PipeTransform {
  private formatter = new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  transform(value: number): string {
    return this.formatter.format(value);
  }
}

import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ContactData,
  ContactDataInterface,
} from '../../../../interfaces/contact-data.interface';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ApiService } from '../../../services/api.service';
import { take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  contactDto: ContactDataInterface;
  contactFormGroup: UntypedFormGroup;
  showAll = false;

  constructor(
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private confirmationService: ConfirmationService,
    private transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.contactDto = new ContactData();
    this.contactFormGroup = this.fb.group({
      name: [this.contactDto.name, [Validators.required]],
      brand: [this.contactDto.brand, [Validators.required]],
      yearOfProduction: [
        this.contactDto.yearOfProduction,
        [
          Validators.required,
          RxwebValidators.digit(),
          Validators.min(1950),
          Validators.max(Number(moment().format('YYYY'))),
        ],
      ],
      phoneNo: [
        this.contactDto.phoneNo,
        [Validators.required, RxwebValidators.digit()],
      ],
      model: [this.contactDto.model, [Validators.required]],
      value: [
        this.contactDto.value,
        [Validators.required, RxwebValidators.digit()],
      ],
      email: [
        this.contactDto.email,
        [Validators.required, RxwebValidators.email()],
      ],
      textMsg: [this.contactDto.textMsg, [Validators.required]],
      agreement1: [this.contactDto.agreement1, [Validators.requiredTrue]],
      agreement2: [this.contactDto.agreement2, [Validators.requiredTrue]],
    });
  }

  onSubmit(): void {
    this.contactDto = new ContactData(this.contactFormGroup.value);
    this.apiService
      .sendContactEmail(this.contactDto)
      .pipe(take(1))
      .subscribe(
        () => {
          this.initializeForm();
          this.confirmationService.confirm({
            message: this.transloco.translate('contact.confirmation.message'),
            rejectVisible: false,
            acceptLabel: this.transloco.translate(
              'contact.confirmation.accept'
            ),
          });
        },
        (error: HttpErrorResponse) => {
          if (error.status === 403) {
            const agreement1 = this.contactFormGroup.get('agreement1');
            if (!agreement1.value) {
              agreement1.markAsDirty();
              agreement1.markAllAsTouched();
            }

            const agreement2 = this.contactFormGroup.get('agreement2');
            if (!agreement2.value) {
              agreement1.markAsDirty();
              agreement1.markAllAsTouched();
            }
          }
        }
      );
  }

  toggleShowAll(): void {
    this.showAll = !this.showAll;
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Portfolio } from '../../../interfaces/idefend/portfolio.interface';
import { PortfolioGroups } from '../../../interfaces/idefend/portfolio-group.interface';
import { Currency } from '../../../interfaces/idefend/currency.interface';
import { LocalizationSetting } from '../../../interfaces/idefend/localization-settings.interface';
import { SodanReplayHeads } from '../../../interfaces/idefend/sodan-replay-heads.interface';
import { PolicyGroupPayload } from '../../../interfaces/idefend/policy-groups-payload.interface';
import { PolicyGroupResponse } from '../../../interfaces/idefend/policy-groups-response.interface';
import { QuestionManagementReplyPayload } from '../../../interfaces/idefend/question-reply-payload.interface';
import { ReplyResponse } from '../../../interfaces/idefend/question-reply-response.interface';
import { PaginationParams } from '../../../interfaces/idefend/pagination-param.interface';
import { VehiclesCategory } from '../../../interfaces/idefend/vehilces-category.interface';
import { VehicleUsage } from '../../../interfaces/idefend/vehicle-usage.interface';
import { combineLatest, Observable } from 'rxjs';
import { VehicleMake } from '../../../interfaces/idefend/vehicle-make.interface';
import { VehicleModel } from '../../../interfaces/idefend/vehicle-model.interface';
import { CalculateOfferPayload } from '../shared/interfaces/calculate-offer-payload.interface';
import { CalculateOfferResponse } from '../shared/interfaces/calculate-offer-response.interface';
import { CreationLockPayload } from '../shared/interfaces/creation-lock-payload.interface';
import { CreationLockResponse } from '../shared/interfaces/creation-lock-response.interface';
import { ConfirmSignaturePayload } from '../../../interfaces/idefend/confirm-signature-payload.interface';
import { take } from 'rxjs/operators';
import { mapOfferLockPayload } from './helpers/offer-lock-payload-mapper';
import { GetPolicy, LockOffer } from './store/offer.action';
import { Store } from '@ngxs/store';
import { OfferState } from './store/offer.state';
import { PolicyCreatorState } from './store/policy-creator.state';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PolicyCreatorService {
  private apiBase = environment.apiBase;
  public characterPattern = /\p{L}/u;
  public digitOrSymbolPattern = /\P{L}/gu;
  public isDialogOpen = false;

  intervalId;

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router,
  ) {}

  handleCityValidation(event: KeyboardEvent) {
    const pattern =
      /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/gm;
    // @ts-ignore-next-line
    let value = event.target.value;

    while (!pattern.test(value)) {
      value = value.slice(0, -1);
    }

    // @ts-ignore-next-line
    event.target.value = value;
  }

  filterNonAlphaChars(event) {
    if (event.key.length === 1) {
      if (!this.characterPattern.test(event.key)) {
        event.target.value = event.target.value.slice(
          0,
          event.target.value.length - 1,
        );
      }
    }

    if (this.digitOrSymbolPattern.test(event.target.value)) {
      event.target.value = event.target.value.slice(
        0,
        event.target.value.search(this.digitOrSymbolPattern),
      );
    }
  }

  clearInterval(): void {
    clearInterval(this.intervalId);
  }

  fetchJwtToken(): Observable<void> {
    return this.http.get<void>(`${this.apiBase}/idefend/jwt-token`);
  }

  /* Initial APK steps */
  getPortfolios(): Observable<Portfolio[]> {
    return this.http.get<Portfolio[]>(`${this.apiBase}/idefend/portfolios`);
  }

  getGroups(): Observable<PortfolioGroups[]> {
    return this.http.get<PortfolioGroups[]>(
      `${this.apiBase}/idefend/product-portfolio/groups`,
    );
  }

  getCurrency(id: number): Observable<Currency> {
    return this.http.get<Currency>(`${this.apiBase}/idefend/currencies/${id}`);
  }

  getLocalizationSettings(id: number): Observable<LocalizationSetting> {
    return this.http.get<LocalizationSetting>(
      `${this.apiBase}/idefend/localization-settings/${id}`,
    );
  }

  setSodanReplyHeads(): Observable<SodanReplayHeads> {
    return this.http.post<SodanReplayHeads>(
      `${this.apiBase}/idefend/sodan-reply-heads`,
      {},
    );
  }

  setPolicyGroups(
    payload: PolicyGroupPayload,
  ): Observable<PolicyGroupResponse> {
    return this.http.post<PolicyGroupResponse>(
      `${this.apiBase}/idefend/policy-groups`,
      payload,
    );
  }

  patchQuestionReply(
    payload: QuestionManagementReplyPayload,
    query: { locale: string },
    id: number,
  ): Observable<ReplyResponse> {
    return this.http.put<ReplyResponse>(
      `${this.apiBase}/idefend/question-management/replies/${id}`,
      payload,
      {
        params: query,
      },
    );
  }
  /* Initial APK steps */

  getVehicleCategories(
    query: PaginationParams,
  ): Observable<VehiclesCategory[]> {
    const queryDto = new HttpParams({
      // @ts-ignore-next-line
      fromObject: query,
    });

    return this.http.get<VehiclesCategory[]>(
      `${this.apiBase}/idefend/vehicles/categories`,
      {
        params: queryDto,
      },
    );
  }

  getVehicleUsages(query: PaginationParams): Observable<VehicleUsage[]> {
    const queryDto = new HttpParams({
      // @ts-ignore-next-line
      fromObject: query,
    });

    return this.http.get<VehicleUsage[]>(
      `${this.apiBase}/idefend/vehicles/usages`,
      {
        params: queryDto,
      },
    );
  }

  getVehicleMakes(code?: string): Observable<VehicleMake[]> {
    const queryDto = new HttpParams({
      // @ts-ignore-next-line
      fromObject: {
        categoryCode: code ?? '',
      },
    });

    return this.http.get<VehicleMake[]>(
      `${this.apiBase}/idefend/vehicles/makes`,
      {
        params: queryDto,
      },
    );
  }

  getVehicleModels(): Observable<VehicleModel[]> {
    return this.http.get<VehicleModel[]>(
      `${this.apiBase}/idefend/vehicles/models`,
    );
  }

  getOffer(payload: CalculateOfferPayload): Observable<CalculateOfferResponse> {
    return this.http.post<CalculateOfferResponse>(
      `${this.apiBase}/idefend/policies/creation/calculate-offer`,
      payload,
    );
  }

  lockOffer(payload: CreationLockPayload): Observable<CreationLockResponse> {
    return this.http.post<CreationLockResponse>(
      `${this.apiBase}/idefend/policies/creation/lock`,
      payload,
    );
  }

  /* TODO: check response */
  signOffer(id: number, payload: ConfirmSignaturePayload): Observable<unknown> {
    return this.http.put(
      `${this.apiBase}/idefend/policies/${id}/confirm-signature`,
      payload,
    );
  }

  getPolicyByNumber(policyNo: string) {
    return this.http.get<CreationLockResponse[]>(
      `${this.apiBase}/idefend/policies?policyNo=${policyNo}`,
    );
  }

  getPolicy(id: number): Observable<CreationLockResponse> {
    return this.http.get<CreationLockResponse>(
      `${this.apiBase}/idefend/policies/${id}`,
    );
  }

  getAttachments(policyId: number, documentType: string): Observable<any> {
    return this.http.get(
      `${this.apiBase}/idefend/policies/${policyId}/document-download/${documentType}`,
    );
  }

  handlePolicySigning(): void {
    const policyCreatorState$ = this.store.select(PolicyCreatorState.state);
    const offer$ = this.store.select(OfferState.offer);
    const offerLocked$ = this.store.select(OfferState.offerLocked);

    combineLatest([policyCreatorState$, offer$])
      .pipe(take(1))
      .subscribe(([policyCreatorState, offerState]) => {
        const payload = mapOfferLockPayload(policyCreatorState, offerState);

        if (payload) {
          this.store
            .dispatch(new LockOffer(payload))
            .pipe(take(1))
            .subscribe(() => {
              offerLocked$.pipe(take(1)).subscribe((offerLocked) => {
                const paymentMethod = this.store.selectSnapshot(
                  PolicyCreatorState.fifthStepPayload,
                );
                if (paymentMethod !== 'PM_BT') {
                  window.open(offerLocked.paymentInitiationUrl, '_blank');
                }

                if (paymentMethod === 'PM_BT') {
                  this.router.navigateByUrl(`/cta/upload/${offerLocked.id}`);
                }

                // FIXME: remove and refactor if not needed
                // this.intervalId = setInterval(() => {
                //   this.store.dispatch(new GetPolicy(offerLocked.id));
                // }, 5000);
              });
            });
        }
      });
  }

  // FIXME: not used?
  missingUploadTypes(id: number) {
    return this.http.get(
      `${this.apiBase}/idefend/policies/${id}/missing-upload-types`,
    );
  }

  uploadPolicyDocuments(policyId: number, payload: FormData): Observable<void> {
    return this.http.post<void>(
      `${this.apiBase}/idefend/policies/${policyId}/uploads`,
      payload,
    );
  }

  removePolicyDocument(policyId: number, documentId: number) {
    // TODO
  }

  getDiscountCode(discountCode: string): Observable<any> {
    return this.http.get(`${this.apiBase}/discount/code/${discountCode}`);
  }

  getDiscountFromLink(discountLink: string): Observable<any> {
    return this.http.get(`${this.apiBase}/check-link/${discountLink}`);
  }
}

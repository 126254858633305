import { Injectable } from '@angular/core';
import { ScriptModel } from '../../interfaces/trust-badge.interface';
import { Observable, Observer } from 'rxjs';

@Injectable()
export class MessengerService {
  private scripts: ScriptModel[] = [];

  public load(script: ScriptModel): Observable<ScriptModel> {
    return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
      const existingScript = this.scripts.find((s) => s.name === script.name);

      // Complete if already loaded
      if (existingScript && existingScript.loaded) {
        observer.next(existingScript);
        observer.complete();
        return;
      }

      // Add the script
      this.scripts = [...this.scripts, script];

      const existingContainer = document.getElementById('fb-root');
      const documentBody = document.getElementsByTagName('body');

      if (!existingContainer) {
        const fbRoot = this.createMessengerElement('div', 'fb-root');

        documentBody[0].appendChild(fbRoot);
        // checkoutContainer.style.display = 'none';
      }

      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.innerHTML = `window.fbAsyncInit = function() {FB.init({xfbml: true,version: 'v9.0'});};(function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0];if (d.getElementById(id)) return;js = d.createElement(s); js.id = id;js.src = 'https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js';fjs.parentNode.insertBefore(js, fjs);}(document, 'script', 'facebook-jssdk'));`;

      scriptElement.onload = () => {
        script.loaded = true;
        observer.next(script);
        observer.complete();
      };

      scriptElement.onerror = (error: any) => {
        observer.error("Couldn't load script " + script.src);
      };

      documentBody[0].appendChild(scriptElement);
    });
  }

  private createMessengerElement(tag: string, id: string): any {
    const element = document.createElement(tag);
    element.id = id;

    return element;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-a-gap',
  templateUrl: './not-a-gap.component.html',
  styleUrls: ['./not-a-gap.component.scss'],
})
export class NotAGapComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

<ng-container *transloco="let t">
  <ng-container
    *ngIf="control && (control.touched || (control.dirty && control.invalid))">
    <ng-container *ngIf="errors$ | async as validationErrors">
      <div *ngFor="let error of validationErrors">
        <small
          *ngIf="error.message; else translatedErrorMessage"
          class="p-invalid inputMsg"
          >{{ error.message }}</small
        >
        <ng-template #translatedErrorMessage>
          <small
            *ngIf="error.value"
            class="p-invalid inputMsg">
            {{ t('formValidators.' + error.keyError) }}
          </small>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

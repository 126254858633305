import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-answer-question',
  templateUrl: './answer-question.component.html',
  styleUrls: ['./answer-question.component.scss'],
})
export class AnswerQuestionComponent implements OnInit {
  sectionNumber: number;
  questionNumber: number;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.sectionNumber = this.config.data.section;
    this.questionNumber = this.config.data.question;
  }

  close(): void {
    this.ref.close();
  }
}

import { PolicyCreatorStateModel } from '../store/policy-creator.model';

export function checkForInstalments(state: PolicyCreatorStateModel): string {
  if (!state.paymentMethod) {
    return null;
  }

  if (state.paymentMethod === 'PM_PAYU_M') {
    if (!state.secondStepPayload.paymentPeriod) {
      return 'ALL_REGULAR';
    }

    return state.secondStepPayload.paymentPeriod === 12
      ? 'ALL_REGULAR'
      : `${state.secondStepPayload.paymentPeriod}_REGULAR`;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface ValidationMessage {
  keyError: string;
  value: any;
  message?: string;
}

@Component({
  selector: 'app-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationMessageComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() target: any;

  public errors$: Subject<ValidationMessage[]> = new Subject();
  private ngOnDestroy$: Subject<void> = new Subject();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.target) {
      /* PrimNG Inputs */
      if (this.target.onBlur) {
        this.target.onBlur.pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => {
          setTimeout(() => {
            this.cdr.detectChanges();
            this.errors$.next(this.mapErrors(this.control.errors));
          });
        });
        return;
      }

      /* Native Inputs */
      const nativeInput = new ElementRef(this.target);
      nativeInput.nativeElement.addEventListener('blur', () => {
        setTimeout(() => {
          this.cdr.detectChanges();
          this.errors$.next(this.mapErrors(this.control.errors));
        });
      });
    } else {
      if (!this.control.pristine) {
        console.warn('## FALLBACK MISSING ##');
        console.log(this.control);
      }
    }
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
    this.errors$.next(null);
    this.errors$.complete();
  }

  mapErrors(errors: ValidationErrors): ValidationMessage[] {
    if (errors !== null) {
      return Object.keys(errors).map((keyError) => {
        return {
          keyError: keyError,
          message: errors[keyError].message,
          value: errors[keyError],
        };
      });
    }
    return null;
  }
}

import * as moment from 'moment';

interface Option {
  value: string;
  label: string;
}

export interface PolicyPriceCalculation {
  productId: string;
  vehicleRegDate: Date;
  vehiclePurchaseDate: Date; // missing in few cases
  vehiclePurchasePrice: number; // <-- vehicleValueCalculation?: number;
  paymentTerm?: Option;
  selectedPaymentTerm?: string;
  insTerm: number;
  vatIncluded: number;
  vatReclaimable: boolean;
  claimLimit: number;
  vehicleValueCalculation?: string;
}

export class PolicyPriceCalculationC {
  productId: string;
  vehicleRegDate: string;
  vehiclePurchaseDate: string; // missing in few cases
  vehiclePurchasePrice: string; // <-- vehicleValueCalculation?: number;
  paymentTerm: string;
  insTerm: string;
  vatIncluded: string;
  vatReclaimable: string;
  claimLimit: string;

  constructor(data: PolicyPriceCalculation) {
    this.productId = '2';
    this.vehicleRegDate = moment(data.vehicleRegDate).format('YYYY-MM-DD');
    if (data.vehicleValueCalculation) {
      this.vehiclePurchasePrice = data.vehicleValueCalculation.toString();
    } else {
      this.vehiclePurchasePrice = data.vehiclePurchasePrice
        ? data.vehiclePurchasePrice.toString()
        : '1';
    }

    if (data.vatReclaimable) {
      this.vatReclaimable = data.vatReclaimable.toString();
    } else {
      this.vatReclaimable = 'false';
    }

    if (data.vehiclePurchaseDate) {
      this.vehiclePurchaseDate = moment(data.vehiclePurchaseDate).format(
        'YYYY-MM-DD'
      );
    } else {
      this.vehiclePurchaseDate = moment(data.vehicleRegDate).format(
        'YYYY-MM-DD'
      );
    }

    if (data.paymentTerm && data.paymentTerm.value) {
      this.paymentTerm = data.paymentTerm.value;
    } else if (data.selectedPaymentTerm) {
      this.paymentTerm = data.selectedPaymentTerm;
    }
    this.insTerm = data.insTerm ? data.insTerm.toString() : '12';
    this.vatIncluded = data.vatIncluded ? data.vatIncluded.toString() : '0';
    this.claimLimit = data.claimLimit ? data.claimLimit.toString() : '10000';
  }
}

<ng-container *transloco="let t; read: 'nav'">
  <div class="navBar">
    <a
      class="navBar__item"
      routerLink="/"
      fragment="whatIsGap"
      [class.active]="(activeFragment | async) === 'whatIsGap'">
      {{ t('home') }}
    </a>
    <a
      class="navBar__item"
      routerLink="/"
      fragment="benefits"
      [class.active]="(activeFragment | async) === 'benefits'">
      {{ t('how-it-works') }}
    </a>
    <a
      class="navBar__item"
      routerLink="/"
      fragment="forWho"
      [class.active]="(activeFragment | async) === 'forWho'">
      {{ t('types-of-policies') }}
    </a>
    <a
      class="navBar__item logo"
      routerLink="/"
      fragment="home"
      (click)="closeMenu()">
      <img
        src="assets/images/gap_logo.svg"
        alt="logo"
        class="logo" />
    </a>
    <a
      class="navBar__item"
      routerLink="/"
      fragment="faq"
      [class.active]="(activeFragment | async) === 'faq'">
      {{ t('faq') }}
    </a>
    <a
      class="navBar__item"
      routerLink="/"
      fragment="contact"
      [class.active]="(activeFragment | async) === 'contact'">
      {{ t('contact') }}
    </a>

    <ng-container *ngIf="isWarranty$ | async; else standardBuy">
      <button
        type="button"
        class="p-d-none p-d-xl-inline-block"
        [ngClass]="'buttonGreen'"
        (click)="redirectToWarrantiesApk()">
        <img
          src="assets/icons/bucket.svg"
          alt="bucket" />
        {{ t('buy-gap') }}
      </button>
    </ng-container>

    <ng-template #standardBuy>
      <button
        type="button"
        class="p-d-none p-d-xl-inline-block"
        [ngClass]="'buttonYellow'"
        routerLink="/cta"
        fragment="ctaHeader">
        <img
          src="assets/icons/bucket.svg"
          alt="bucket" />
        {{ t('buy-gap') }}
      </button>
    </ng-template>

    <a
      class="fbButton"
      target="_blank"
      href="https://www.facebook.com/ubezpieczeniaGAP/">
      <img
        class="p-mx-0 p-mt-1"
        src="assets/icons/fb-icon.svg"
        alt="bucket" />
    </a>
    <button
      type="button"
      class="p-d-xl-none menuToggle"
      (click)="toggleMenu()">
      <img
        *ngIf="!isOpen"
        src="assets/icons/menu.svg"
        alt="menu" />
      <img
        *ngIf="isOpen"
        src="assets/icons/cross.svg"
        alt="menu" />
    </button>
    <div
      *ngIf="isOpen"
      class="menuList p-d-xl-none">
      <a
        class="menuList__item"
        routerLink="/"
        fragment="whatIsGap"
        [class.active]="(activeFragment | async) === 'home'"
        (click)="toggleMenu()">
        {{ t('home') }}
      </a>
      <a
        class="menuList__item"
        routerLink="/"
        fragment="benefits"
        [class.active]="(activeFragment | async) === 'benefits'"
        (click)="toggleMenu()">
        {{ t('how-it-works') }}
      </a>
      <a
        class="menuList__item"
        routerLink="/"
        fragment="forWho"
        [class.active]="(activeFragment | async) === 'forWho'"
        (click)="toggleMenu()">
        {{ t('types-of-policies') }}
      </a>
      <a
        class="menuList__item"
        routerLink="/"
        fragment="faq"
        [class.active]="(activeFragment | async) === 'faq'"
        (click)="toggleMenu()">
        {{ t('faq') }}
      </a>
      <a
        class="menuList__item"
        routerLink="/"
        fragment="contact"
        [class.active]="(activeFragment | async) === 'contact'"
        (click)="toggleMenu()">
        {{ t('contact') }}
      </a>
      <button
        type="button"
        [ngClass]="(isWarranty$ | async) ? 'buttonGreen' : 'buttonYellow'"
        routerLink="/cta"
        fragment="ctaHeader"
        (click)="toggleMenu()">
        <img
          src="assets/icons/bucket.svg"
          alt="bucket" />
        {{ t('buy-gap') }}
      </button>
    </div>
  </div>
</ng-container>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'NumberPipe' })
export class NumberPipe implements PipeTransform {
  private formatter = new Intl.NumberFormat('pl-PL', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  transform(value: number): string {
    return this.formatter.format(value);
  }
}

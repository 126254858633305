import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DropdownInputInterface } from '../../../../interfaces/dropdown-input.interface';
import { Currency } from '../../../../interfaces/idefend/currency.interface';
import { LocalizationSetting } from '../../../../interfaces/idefend/localization-settings.interface';
import { PolicyGroupResponse } from '../../../../interfaces/idefend/policy-groups-response.interface';
import { PortfolioGroups } from '../../../../interfaces/idefend/portfolio-group.interface';
import { Portfolio } from '../../../../interfaces/idefend/portfolio.interface';
import { SodanReplayHeads } from '../../../../interfaces/idefend/sodan-replay-heads.interface';
import { StepsValidators } from '../../../../interfaces/idefend/steps-validators.interface';
import { VehicleMake } from '../../../../interfaces/idefend/vehicle-make.interface';
import { VehicleModel } from '../../../../interfaces/idefend/vehicle-model.interface';
import { VehicleUsage } from '../../../../interfaces/idefend/vehicle-usage.interface';
import { VehiclesCategory } from '../../../../interfaces/idefend/vehilces-category.interface';
import { CalculateOfferPayload } from '../../shared/interfaces/calculate-offer-payload.interface';
import { FirstStepPayload } from '../../shared/interfaces/first-step-payload.interface';
import { FourthStepPayload } from '../../shared/interfaces/fourth-step-payload.interface';
import { SecondStepPayload } from '../../shared/interfaces/second-step-payload.interface';
import { SummaryData } from '../../shared/interfaces/summary-data.interface';
import { ThirdStepPayload } from '../../shared/interfaces/third-step-payload.interface';
import { offerPayloadMapper } from '../helpers/offer-payload-mapper';
import { mapSummaryData } from '../helpers/summary-data-mapper';
import { PolicyCreatorService } from '../policy-creator.service';
import { OfferState } from './offer.state';
import {
  FilterVehicleModelsByCategory,
  FilterVehicleModelsByMake,
  GetCurrency,
  GetGroups,
  GetLocalizationSettings,
  GetPortfolios,
  GetVehicleCategories,
  GetVehicleMakes,
  GetVehicleModels,
  GetVehicleUsages,
  PatchPaymentMethod,
  PatchFirstStepFormValues,
  PatchFourthStepFormValues,
  PatchQuestionReply,
  PatchSecondStepFormValues,
  PatchThirdStepFormValues,
  ResetPolicyCreatorState,
  SetActiveStep,
  SetPolicyGroups,
  SetEmptySodanReplyHeads,
  SetStepsValidators,
} from './policy-creator.actions';
import { defaults, PolicyCreatorStateModel } from './policy-creator.model';

@State<PolicyCreatorStateModel>({
  name: 'policyCreatorState',
  defaults,
})
@Injectable()
export class PolicyCreatorState {
  constructor(
    private store: Store,
    private policyCreatorService: PolicyCreatorService,
  ) {}

  @Selector()
  static state(state): PolicyCreatorStateModel {
    return state;
  }

  @Selector()
  static portfolios({ portfolios }: PolicyCreatorStateModel): Portfolio[] {
    return portfolios;
  }

  @Selector()
  static groups({ groups }: PolicyCreatorStateModel): PortfolioGroups[] {
    return groups;
  }

  @Selector()
  static currency({ currency }: PolicyCreatorStateModel): Currency {
    return currency;
  }

  @Selector()
  static localizationSettings({
    localizationSettings,
  }: PolicyCreatorStateModel): LocalizationSetting {
    return localizationSettings;
  }

  @Selector()
  static sodanReplyHeads({
    sodanReplyHeads,
  }: PolicyCreatorStateModel): SodanReplayHeads {
    return sodanReplyHeads;
  }

  @Selector()
  static policyGroups({
    policyGroups,
  }: PolicyCreatorStateModel): PolicyGroupResponse {
    return policyGroups;
  }

  @Selector()
  static vehicleCategories({
    vehicleCategories,
  }: PolicyCreatorStateModel): VehiclesCategory[] {
    return vehicleCategories;
  }

  @Selector()
  static vehicleUsages({
    vehicleUsages,
  }: PolicyCreatorStateModel): VehicleUsage[] {
    return vehicleUsages;
  }

  @Selector()
  static vehicleType({
    firstStepPayload,
  }: PolicyCreatorStateModel): DropdownInputInterface {
    return firstStepPayload.vehicleType;
  }

  @Selector()
  static vehicleRegDate({ firstStepPayload }: PolicyCreatorStateModel): string {
    return firstStepPayload.vehicleRegDate;
  }

  @Selector()
  static vehicleMakes({
    vehicleMakes,
  }: PolicyCreatorStateModel): VehicleMake[] {
    return vehicleMakes;
  }

  @Selector()
  static vehicleModels({
    vehicleModels,
  }: PolicyCreatorStateModel): VehicleModel[] {
    return vehicleModels;
  }

  @Selector()
  static vehicleModelsFilter({
    vehicleModelsFilter,
  }: PolicyCreatorStateModel): VehicleModel[] {
    return vehicleModelsFilter;
  }

  @Selector()
  static haveAcPolicy({ firstStepPayload }: PolicyCreatorStateModel): boolean {
    return firstStepPayload.haveACPolicy;
  }

  @Selector()
  static boughtSince120Days({
    firstStepPayload,
  }: PolicyCreatorStateModel): boolean {
    return firstStepPayload.boughtSince120Days;
  }

  @Selector()
  static vehicleBuyDate({ firstStepPayload }: PolicyCreatorStateModel): string {
    return firstStepPayload.vehicleBuyDate;
  }

  @Selector()
  static selectedInsuranceVariant({
    firstStepPayload,
  }: PolicyCreatorStateModel): string {
    return firstStepPayload.selectedInsuranceVariant;
  }

  @Selector()
  static firstStepPayload({
    firstStepPayload,
  }: PolicyCreatorStateModel): FirstStepPayload {
    return firstStepPayload;
  }

  @Selector()
  static secondStepPayload({
    secondStepPayload,
  }: PolicyCreatorStateModel): SecondStepPayload {
    return secondStepPayload;
  }

  @Selector()
  static thirdStepPayload({
    thirdStepPayload,
  }: PolicyCreatorStateModel): ThirdStepPayload {
    return thirdStepPayload;
  }

  @Selector()
  static fourthStepPayload({
    fourthStepPayload,
  }: PolicyCreatorStateModel): FourthStepPayload {
    return fourthStepPayload;
  }

  @Selector()
  static fifthStepPayload({ paymentMethod }: PolicyCreatorStateModel): string {
    return paymentMethod;
  }

  @Selector()
  static getSummaryData(data: PolicyCreatorStateModel): SummaryData {
    return mapSummaryData(data);
  }

  @Selector()
  static fetchCalculateOfferPayload(
    state: PolicyCreatorStateModel,
  ): CalculateOfferPayload {
    return offerPayloadMapper(state);
  }

  @Selector()
  static getActiveStep(state: PolicyCreatorStateModel): number {
    return state.activeStep;
  }

  @Selector()
  static getStepsValidators(state: PolicyCreatorStateModel): StepsValidators {
    return state.stepsValidator;
  }

  @Action(GetPortfolios)
  public getPortfolios({ patchState }: StateContext<PolicyCreatorStateModel>) {
    return this.policyCreatorService.getPortfolios().pipe(
      tap((portfolios) => {
        patchState({
          portfolios,
        });
      }),
    );
  }

  @Action(GetGroups)
  public getGroups({ patchState }: StateContext<PolicyCreatorStateModel>) {
    return this.policyCreatorService.getGroups().pipe(
      tap((groups) => {
        patchState({ groups: groups });
      }),
    );
  }

  @Action(GetCurrency)
  public getCurrency(
    { patchState }: StateContext<PolicyCreatorStateModel>,
    { id }: GetCurrency,
  ) {
    return this.policyCreatorService.getCurrency(id).pipe(
      tap((currency) => {
        patchState({ currency });
      }),
    );
  }

  @Action(GetLocalizationSettings)
  public getLocalizationSettings(
    { patchState }: StateContext<PolicyCreatorStateModel>,
    { id }: GetLocalizationSettings,
  ) {
    return this.policyCreatorService.getLocalizationSettings(id).pipe(
      tap((localizationSettings) => {
        patchState({ localizationSettings });
      }),
    );
  }

  @Action(SetEmptySodanReplyHeads)
  public setEmptySodanReplyHeads(ctx: StateContext<PolicyCreatorStateModel>) {
    return this.policyCreatorService.setSodanReplyHeads().pipe(
      tap((sodanReplyHeads) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          sodanReplyHeads: sodanReplyHeads,
        });
      }),
    );
  }

  @Action(SetPolicyGroups)
  public setPolicyGroups(
    { patchState }: StateContext<PolicyCreatorStateModel>,
    { payload }: SetPolicyGroups,
  ) {
    return this.policyCreatorService.setPolicyGroups(payload).pipe(
      tap((policyGroups) => {
        patchState({ policyGroups });
      }),
    );
  }

  @Action(PatchQuestionReply)
  public patchQuestionReply(
    { getState, patchState }: StateContext<PolicyCreatorStateModel>,
    { payload, query, id }: PatchQuestionReply,
  ) {
    return this.policyCreatorService
      .patchQuestionReply(payload, query, id)
      .pipe(
        tap((replyResponse) => {
          const sodanReplyHeadsDto = getState().sodanReplyHeads;
          sodanReplyHeadsDto.replies = sodanReplyHeadsDto.replies.map(
            (reply) => {
              if (reply.id === replyResponse.id) {
                return replyResponse;
              }
              return reply;
            },
          );
          patchState({
            sodanReplyHeads: sodanReplyHeadsDto,
          });
        }),
      );
  }

  @Action(GetVehicleCategories)
  public getVehicleCategories(
    { patchState }: StateContext<PolicyCreatorStateModel>,
    { query }: GetVehicleCategories,
  ) {
    return this.policyCreatorService.getVehicleCategories(query).pipe(
      tap((vehicleCategories) => {
        patchState({ vehicleCategories });
      }),
    );
  }

  @Action(GetVehicleUsages)
  public getVehicleUsages(
    { patchState }: StateContext<PolicyCreatorStateModel>,
    { query }: GetVehicleUsages,
  ) {
    return this.policyCreatorService.getVehicleUsages(query).pipe(
      tap((vehicleUsages) => {
        patchState({ vehicleUsages });
      }),
    );
  }

  @Action(GetVehicleMakes)
  public getVehicleMakes({
    patchState,
  }: StateContext<PolicyCreatorStateModel>) {
    return this.policyCreatorService.getVehicleMakes().pipe(
      tap((vehicleMakes) => {
        patchState({ vehicleMakes });
      }),
    );
  }

  @Action(GetVehicleModels)
  public getVehicleModels({
    patchState,
  }: StateContext<PolicyCreatorStateModel>) {
    return this.policyCreatorService.getVehicleModels().pipe(
      tap((vehicleModels) => {
        patchState({ vehicleModels });
      }),
    );
  }

  @Action(FilterVehicleModelsByCategory)
  public filterVehicleModelsByCategory(
    { getState, patchState }: StateContext<PolicyCreatorStateModel>,
    { categoryId, categoryCode }: FilterVehicleModelsByCategory,
  ) {
    const vehicleMakesReq =
      this.policyCreatorService.getVehicleMakes(categoryCode);
    const vehicleModelsReq = this.policyCreatorService.getVehicleModels();

    return combineLatest([vehicleMakesReq, vehicleModelsReq]).pipe(
      tap(([vehicleMakes, vehicleModels]) => {
        const vehicleModelsFilter = vehicleModels.filter(
          (model) => model.categoryId === categoryId,
        );
        patchState({
          vehicleMakes: vehicleMakes,
          vehicleModels: vehicleModelsFilter,
        });
      }),
    );
  }

  @Action(FilterVehicleModelsByMake)
  public filterVehicleModelsByMake(
    { getState, patchState }: StateContext<PolicyCreatorStateModel>,
    { selectedVehicleMake }: FilterVehicleModelsByMake,
  ) {
    const vehicleModelsFilter = getState().vehicleModels.filter(
      (model) => model.makeId === selectedVehicleMake.id,
    );
    patchState({ vehicleModelsFilter: vehicleModelsFilter });
  }

  @Action(PatchFirstStepFormValues)
  public patchFirstStepFormValues(
    { getState, patchState }: StateContext<PolicyCreatorStateModel>,
    { firstStepPayload }: PatchFirstStepFormValues,
  ) {
    const state = getState();
    const payload = {
      ...firstStepPayload,
    };

    if (firstStepPayload.vehiclePrice && !firstStepPayload.vehiclePriceType) {
      payload.vehiclePriceType = state.firstStepPayload.vehiclePolicyPriceType;
    }
    patchState({ firstStepPayload: payload });
  }

  @Action(PatchSecondStepFormValues)
  public patchSecondStepFormValues(
    { patchState }: StateContext<PolicyCreatorStateModel>,
    { secondStepPayload }: PatchSecondStepFormValues,
  ) {
    patchState({ secondStepPayload });
  }

  @Action(PatchThirdStepFormValues)
  public patchThirdStepFormValues(
    { patchState }: StateContext<PolicyCreatorStateModel>,
    { thirdStepPayload }: PatchThirdStepFormValues,
  ) {
    patchState({ thirdStepPayload });
  }

  @Action(PatchFourthStepFormValues)
  public patchFourthStepFormValues(
    { patchState }: StateContext<PolicyCreatorStateModel>,
    { fourthStepPayload }: PatchFourthStepFormValues,
  ) {
    const premiumSuggested = this.store.selectSnapshot(
      OfferState.offer,
    )?.premiumSuggested;
    if (premiumSuggested) {
      patchState({
        fourthStepPayload: {
          ...fourthStepPayload,
          premiumSuggested: premiumSuggested,
        },
      });
    }
  }

  @Action(PatchPaymentMethod)
  public patchFifthStepFormValues(
    { patchState }: StateContext<PolicyCreatorStateModel>,
    { paymentMethod }: PatchPaymentMethod,
  ) {
    patchState({ paymentMethod });
  }

  @Action(SetActiveStep)
  public setActiveStep(
    { patchState }: StateContext<PolicyCreatorStateModel>,
    { step }: SetActiveStep,
  ) {
    patchState({
      activeStep: step,
    });
  }

  @Action(ResetPolicyCreatorState)
  public resetState({ setState }: StateContext<PolicyCreatorStateModel>) {
    return setState(defaults);
  }

  @Action(SetStepsValidators)
  public setStepsValidators(
    { getState, patchState }: StateContext<PolicyCreatorStateModel>,
    { stepValidator }: SetStepsValidators,
  ) {
    const stepsValidatorDto = getState().stepsValidator;
    return patchState({
      stepsValidator: {
        ...stepsValidatorDto,
        ...stepValidator,
      },
    });
  }
}

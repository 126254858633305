<ng-container *transloco="let t; read: 'home'">
  <!-- crashes the SSR -->
  <!--  <p-confirmDialog icon="pi pi-check"></p-confirmDialog>-->
  <div class="home">
    <div class="scrollContainer">
      <div
        class="scrollContainer__link"
        id="home"></div>
    </div>
    <div class="p-grid p-nogutter p-mt-0 p-jc-center">
      <div class="p-col-12 p-md-6 p-xl-5 home__left">
        <img
          class="bgImg p-d-none p-d-xl-block"
          src="assets/images/bg1-img.svg"
          alt="tear" />
        <div class="calculator p-shadow-9">
          <div
            class="calculator__headers p-grid p-nogutter p-text-center p-ai-center">
            <div
              class="p-col-4 header"
              [ngClass]="
                typeOfPolicy === 'invoice' ? 'header--isActive' : 'header'
              "
              (click)="selectTypeOfPolicy('invoice')"
              (mouseleave)="opInvoice.hide()">
              <p>{{ t('tab.header1.short') }}</p>
              <div class="imgWrapper">
                <img
                  src="assets/icons/info.svg"
                  class="p-mx-2"
                  alt="info"
                  (mouseover)="opInvoice.show($event)" />
                <p-overlayPanel
                  #opInvoice
                  [dismissable]="true"
                  [showTransitionOptions]="'200ms'"
                  (mouseleave)="opInvoice.hide()">
                  <ng-template pTemplate>
                    <div class="p-mb-2">
                      <span class="textGrey"
                        ><span class="p-text-bold">{{
                          t('tab.header1.long')
                        }}</span>
                        {{ t('tab.header1.content') }}
                      </span>
                    </div>
                    <a
                      routerLink="/"
                      fragment="benefits"
                      >{{ t('tab.showMore') }}</a
                    >
                  </ng-template>
                </p-overlayPanel>
              </div>
            </div>
            <div
              class="p-col-4 header"
              [ngClass]="
                typeOfPolicy === 'casco' ? 'header--isActive' : 'header'
              "
              (click)="selectTypeOfPolicy('casco')"
              (mouseleave)="opCasco.hide()">
              <p>{{ t('tab.header2.short') }}</p>
              <div class="imgWrapper">
                <img
                  src="assets/icons/info.svg"
                  class="p-mx-2"
                  alt="info"
                  (mouseover)="opCasco.show($event)" />
                <p-overlayPanel
                  class="centralPanel"
                  #opCasco
                  [dismissable]="true"
                  [showTransitionOptions]="'200ms'"
                  (mouseleave)="opCasco.hide()">
                  <ng-template pTemplate>
                    <div class="p-mb-2">
                      <span class="textGrey"
                        ><span class="p-text-bold">{{
                          t('tab.header2.long')
                        }}</span>
                        {{ t('tab.header2.content') }}
                      </span>
                    </div>
                    <a
                      routerLink="/"
                      fragment="benefits"
                      >{{ t('tab.showMore') }}</a
                    >
                  </ng-template>
                </p-overlayPanel>
              </div>
            </div>
            <div
              class="p-col-4 header"
              [ngClass]="
                typeOfPolicy === 'direct' ? 'header--isActive' : 'header'
              "
              (click)="selectTypeOfPolicy('direct')"
              (mouseleave)="opDirect.hide()">
              <p>{{ t('tab.header3.short') }}</p>
              <div class="imgWrapper">
                <img
                  src="assets/icons/info.svg"
                  class="p-mx-2"
                  alt="info"
                  (mouseover)="opDirect.show($event)" />
                <p-overlayPanel
                  class="rightSidePanel"
                  #opDirect
                  [dismissable]="true"
                  [showTransitionOptions]="'200ms'"
                  (mouseleave)="opDirect.hide()">
                  <ng-template pTemplate>
                    <div class="p-mb-2">
                      <span class="textGrey"
                        ><span class="p-text-bold">{{
                          t('tab.header3.long')
                        }}</span>
                        {{ t('tab.header3.content') }}
                      </span>
                    </div>
                    <a
                      routerLink="/"
                      fragment="benefits"
                      >{{ t('tab.showMore') }}</a
                    >
                  </ng-template>
                </p-overlayPanel>
              </div>
            </div>
          </div>
          <div class="calculator__content">
            <div class="texts p-text-center">
              <p class="texts__header">{{ t('tab.content.header') }}</p>
              <p>{{ t('tab.content.text1') }}</p>
              <p>{{ t('tab.content.text2') }}</p>
              <p>{{ t('tab.content.text3') }}</p>
            </div>

            <form
              [formGroup]="gapCalculatorFormGroup"
              (ngSubmit)="onSubmit()">
              <div class="form-group">
                <div
                  class="inputs inputs__upper p-d-md-flex"
                  [ngStyle]="{
                    'align-items':
                      typeOfPolicy === 'direct' ? 'flex-end' : 'unset',
                  }">
                  <div
                    class="p-field"
                    *ngIf="
                      typeOfPolicy === 'invoice' || typeOfPolicy === 'casco'
                    ">
                    <label for="vehiclePurchasePrice">{{
                      t('tab.content.form.price')
                    }}</label>

                    <p-inputNumber
                      data-cy="vehiclePurchasePrice"
                      id="vehiclePurchasePrice"
                      mode="currency"
                      currency="PLN"
                      locale="pl"
                      [placeholder]="t('benefits.slider.example.right.2')"
                      formControlName="vehiclePurchasePrice"
                      minFractionDigits="0"
                      maxFractionDigits="0"></p-inputNumber>
                    <app-validation-message
                      [control]="
                        gapCalculatorFormGroup.get('vehiclePurchasePrice')
                      "></app-validation-message>
                    <small
                      id="vehiclePurchasePrice-help"
                      *ngIf="
                        !this.gapCalculatorFormGroup.controls
                          .vehiclePurchasePrice.invalid ||
                        !this.gapCalculatorFormGroup.controls
                          .vehiclePurchasePrice.dirty
                      ">
                      <ng-container *ngIf="typeOfPolicy === 'invoice'">
                        {{ t('tab.content.form.priceDesc') }}
                      </ng-container>
                      <ng-container *ngIf="typeOfPolicy === 'casco'">
                        {{ t('tab.content.form.priceDescCasco') }}
                      </ng-container>
                    </small>
                  </div>
                  <div
                    class="p-field"
                    *ngIf="typeOfPolicy === 'direct'">
                    <label for="claimLimit">{{
                      t('tab.content.form.claimLimit')
                    }}</label>
                    <p-dropdown
                      [options]="claimLimits"
                      class="dropdown"
                      id="claimLimit"
                      formControlName="claimLimit"
                      (onChange)="handleLimitSelection($event)"
                      optionLabel="name"
                      placeholder="20 000 zł"
                      dropdownIcon="pi pi-sort-down"></p-dropdown>
                  </div>
                  <div
                    class="p-field"
                    *ngIf="typeOfPolicy !== 'direct'">
                    <label for="vehicleRegDate">{{
                      t('tab.content.form.year')
                    }}</label>
                    <p-dropdown
                      [options]="years"
                      class="dropdown"
                      data-cy="vehicleRegDate"
                      id="vehicleRegDate"
                      formControlName="vehicleRegDate"
                      (onChange)="handleYearSelection($event)"
                      optionLabel="year"
                      [placeholder]="setActualYearPlaceholder()"
                      dropdownIcon="pi pi-sort-down"></p-dropdown>
                  </div>
                  <div
                    class="p-field"
                    *ngIf="typeOfPolicy === 'direct'">
                    <label for="vehicleRegDateDirect">{{
                      t('tab.content.form.year')
                    }}</label>
                    <p-dropdown
                      [options]="yearsDirect"
                      class="dropdown"
                      id="vehicleRegDateDirect"
                      formControlName="vehicleRegDate"
                      (onChange)="handleYearSelection($event)"
                      optionLabel="year"
                      [placeholder]="setActualYearPlaceholder()"
                      dropdownIcon="pi pi-sort-down"></p-dropdown>
                  </div>
                </div>

                <div
                  class="p-grid p-nogutter p-dir-col inputs inputs__lower"
                  *ngIf="insTermOptions">
                  <p class="label">{{ t('tab.content.form.period') }}</p>
                  <div
                    class="radiobuttonRow"
                    *ngIf="typeOfPolicy !== 'direct'">
                    <ng-container
                      *ngFor="let insTerm of insTermOptions; index as i">
                      <input
                        [id]="'instTerm' + i"
                        type="radio"
                        formControlName="insTerm"
                        [value]="insTerm" />
                      <label [for]="'instTerm' + i">{{ insTerm }}</label>
                    </ng-container>
                  </div>
                  <div
                    class="radiobuttonRow"
                    *ngIf="typeOfPolicy === 'direct'">
                    <ng-container
                      *ngFor="let insTerm of insTermOptionsDirect; index as i">
                      <input
                        [id]="'instTerm' + i"
                        type="radio"
                        formControlName="insTerm"
                        [value]="insTerm" />
                      <label [for]="'instTerm' + i">{{ insTerm }}</label>
                    </ng-container>
                  </div>
                </div>

                <div class="p-d-flex calculate">
                  <div
                    class="p-d-md-flex calculate__buttons"
                    [ngClass]="
                      calculatedPremium || loadingCalculation
                        ? 'p-jc-between'
                        : 'p-jc-center'
                    ">
                    <button
                      type="submit"
                      data-cy="submit"
                      class="buttonWhite p-m-1 p-m-md-0"
                      [disabled]="
                        !gapCalculatorFormGroup.valid ||
                        (typeOfPolicy === 'direct' &&
                          !this.gapCalculatorFormGroup.controls.claimLimit
                            .value) ||
                        (typeOfPolicy !== 'direct' &&
                          !this.gapCalculatorFormGroup.controls
                            .vehiclePurchasePrice.value)
                      ">
                      {{ t('tab.content.form.calculate') }}
                    </button>

                    <div
                      *ngIf="loadingCalculation"
                      class="spinnerWrapper">
                      <p-progressSpinner
                        [style]="{ width: '40px', height: '40px' }"
                        styleClass="custom-spinner"
                        strokeWidth="8"
                        fill="#EEEEEE"
                        animationDuration=".5s">
                      </p-progressSpinner>
                    </div>

                    <ng-container *ngIf="calculatedPremium">
                      <div class="dataButton p-m-1 p-m-md-0">
                        <p>
                          {{ calculatedPremium | currency: '' : '' : '1.0-0' }}
                          zł
                        </p>
                        <button
                          type="button"
                          class="buttonYellow"
                          routerLink="/cta"
                          fragment="ctaHeader">
                          <img
                            class="p-d-none p-d-xl-inline-block"
                            src="assets/icons/bucket.svg"
                            alt="bucket" />
                          {{ t('tab.content.form.buy-gap') }}
                        </button>
                      </div>
                    </ng-container>
                  </div>
                  <div
                    *ngIf="calculatedPremium && typeOfPolicy !== 'direct'"
                    class="calculate__description">
                    <p class="textSmall textGrey p-text-right">
                      {{ t('tab.content.form.calculationDesc') }}
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        class="p-col-12 p-md-6 home__right"
        [formGroup]="gapSliderFormGroup">
        <img
          class="bgImg p-d-none p-d-xl-block"
          src="assets/images/bg2-img.png"
          alt="tear" />
        <div class="p-grid p-nogutter home__right__header">
          <div class="p-col-12 p-xl-6 p-p-0 p-mb-xl-2">
            <h1>{{ t('policies.header') }}</h1>

            <p class="p-text-light description">{{ t('policies.question') }}</p>
          </div>
          <div class="p-col-12 p-xl-6 dataButtonWrapper">
            <label class="textGrey p-d-md-none">{{
              t('policies.label')
            }}</label>
            <label class="p-d-none p-d-md-block">{{
              t('policies.label')
            }}</label>
            <div class="dataButton p-my-2">
              <p-inputNumber
                mode="currency"
                currency="PLN"
                locale="pl"
                [placeholder]="t('benefits.slider.example.right.2')"
                minFractionDigits="0"
                maxFractionDigits="0"
                formControlName="vehiclePurchasePrice"></p-inputNumber>
              <app-validation-message
                [control]="
                  gapSliderFormGroup.get('vehiclePurchasePrice')
                "></app-validation-message>

              <button
                type="submit"
                class="buttonBlue"
                (click)="handleSliding()">
                {{ t('policies.calculate') }}
              </button>
            </div>
          </div>
        </div>

        <div class="carChart">
          <div class="carChart__sliderWrapper">
            <img
              src="assets/images/car_img.jpg"
              alt="car" />
            <div class="monthOfUse">
              <p>
                {{ t('policies.monthOfUse')
                }}{{ this.gapSliderFormGroup.controls.monthOfUse.value }}
              </p>
            </div>
            <div class="totalLoss">
              <p>{{ t('policies.totalLose') }}</p>
            </div>
            <!--          <p-slider formControlName="monthOfUse" [min]="0" [max]="60" (onChange)="handleSliding()"></p-slider>-->
            <p-slider
              formControlName="monthsArray"
              [range]="true"
              [min]="0"
              [max]="59"
              (onChange)="handleSliding()"></p-slider>
          </div>
          <div class="p-grid p-nogutter carChart__description">
            <div class="p-col-12 p-md-6 carChart__description__left">
              <p class="textSmall">{{ t('policies.ocAcCompensation') }}</p>
              <h3
                class="textBlue p-text-bold"
                *ngIf="this.gapSliderFormGroup.valid">
                {{
                  this.gapSliderFormGroup.controls.policyValue.value
                    | number: '1.0-0'
                }}
                zł
              </h3>
            </div>
            <div class="p-col-12 p-md-6 carChart__description__right">
              <p class="textSmall">{{ t('policies.gapCompensation') }}</p>
              <h3
                class="textYellow p-text-bold"
                *ngIf="this.gapSliderFormGroup.valid">
                {{
                  this.gapSliderFormGroup.controls.gapValue.value
                    | number: '1.0-0'
                }}
                zł
              </h3>
            </div>
            <div class="p-col-8 p-md-12">
              <p class="textSmall textGrey p-text-light">
                {{ t('policies.avarageValues') }}
                <a
                  target="_blank"
                  href="https://gaponline.pl/gapDownloads/C-SUV.jpg"
                  class="textYellow p-text-bold textUnderlined"
                  >{{ t('policies.suv') }}</a
                >
              </p>
            </div>
            <div class="p-col-12">
              <p class="textBlue p-text-bold">{{ t('policies.dontWait') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-grid p-nogutter p-jc-center">
      <div class="scrollContainer">
        <div
          class="scrollContainer__link"
          id="whatIsGap"
          style="top: -50px"></div>
      </div>
      <app-what-is-gap class="w100"></app-what-is-gap>
    </div>
    <div class="p-grid p-nogutter p-jc-center">
      <div class="scrollContainer">
        <div
          class="scrollContainer__link"
          id="benefits"
          style="top: -85px"></div>
      </div>
      <app-benefits></app-benefits>
    </div>
    <div class="p-grid p-nogutter p-jc-center">
      <app-benefits-slider></app-benefits-slider>
    </div>
    <div class="p-grid p-nogutter p-jc-center">
      <app-not-a-gap></app-not-a-gap>
    </div>
    <div class="p-grid p-nogutter p-jc-center">
      <div class="scrollContainer">
        <div
          class="scrollContainer__link forWho"
          id="forWho"></div>
      </div>
      <app-for-who></app-for-who>
    </div>
    <div class="p-grid p-nogutter p-jc-center">
      <app-valid-vehicles></app-valid-vehicles>
    </div>
    <div class="p-grid p-nogutter p-jc-center">
      <app-when-to-buy></app-when-to-buy>
    </div>
    <div class="p-grid p-nogutter p-jc-center">
      <app-documents></app-documents>
    </div>
    <div class="p-grid p-nogutter p-jc-center">
      <div class="scrollContainer">
        <div
          class="scrollContainer__link faq"
          id="faq"></div>
      </div>
      <app-faq class="w100"></app-faq>
    </div>
    <div class="p-grid p-nogutter p-jc-center">
      <div class="scrollContainer">
        <div
          class="scrollContainer__link"
          id="contact"
          style="top: -60px"></div>
      </div>
      <app-contact class="w100"></app-contact>
    </div>
    <div class="p-grid p-nogutter p-jc-center">
      <app-cooperation class="w100"></app-cooperation>
    </div>
  </div>
</ng-container>

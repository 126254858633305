<ng-container *transloco="let t">
  <div class="p-grid faq p-nogutter p-jc-center">
    <div class="p-col-12 p-d-flex p-jc-center backgroundWrapper">
      <div class="p-grid wrapper p-jc-center">
        <div class="p-col-12 p-text-center faq__header">
          <h1>
            {{ t('faq.header.part1') }}<span>{{ t('faq.header.part2') }}</span>
          </h1>
        </div>

        <div
          class="p-col-12 p-md-6 p-xl-4"
          *ngFor="let tale of faqTales; index as i">
          <div class="p-shadow-9 faq__tale">
            <div>
              <p class="textBlue p-text-bold p-text-center">
                {{ tale.header }}
              </p>
            </div>
            <a
              class="textYellow textUnderlined"
              (click)="initializeAnswer(i + 1, 1)"
              >{{ tale.question1 }}</a
            >
            <a
              class="textYellow textUnderlined"
              (click)="initializeAnswer(i + 1, 2)"
              >{{ tale.question2 }}</a
            >
            <a
              class="textYellow textUnderlined"
              (click)="initializeAnswer(i + 1, 3)"
              *ngIf="tale.question3"
              >{{ tale.question3 }}</a
            >
            <button
              class="triangleButton"
              (click)="initializeAnswer(i + 1, 10)">
              <img
                src="assets/icons/triangle.svg"
                alt="" />
            </button>
          </div>
        </div>

        <div class="p-col-12 p-md-6 p-xl-12 p-d-flex p-ai-center p-jc-center">
          <button
            class="buttonYellow p-m-3"
            (click)="initializeAnswer(10, 10)">
            {{ t('faq.button') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

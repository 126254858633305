import { AbstractControl, ValidatorFn } from '@angular/forms';

export function positiveIntOnly(): ValidatorFn {
  const patternInt = /^[0-9]*$/;

  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = patternInt.test(control.value);
    return patternInt.test(control.value) ? null : { positiveIntOnly: true };
  };
}

export function maxCarWeight(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    return value < 3501 ? null : { maxCarWeight: true };
  };
}

export function truckMinWeightValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    return value > 3500 ? null : { truckMinWeightValidator: true };
  };
}

export function truckMaxWeightValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    return value <= 44000 ? null : { truckMaxWeightValidator: true };
  };
}

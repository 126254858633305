<ng-container *transloco="let t; read: 'cooperation'">
  <div class="cooperation p-grid p-nogutter p-jc-center">
    <div class="p-grid p-nogutter wrapper">
      <div class="p-col-12 p-xl-4">
        <div class="p-grid">
          <div class="p-col-12 p-md-6 p-xl-12">
            <h1>{{ t('header') }}</h1>
            <p class="p-pr-3">{{ t('description') }}</p>
          </div>
          <div class="p-col-12 p-md-6 p-xl-12">
            <p class="p-text-bold paddingTop">{{ t('description2') }}</p>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-xl-8 cooperation__form">
        <form
          [formGroup]="cooperationFormGroup"
          (ngSubmit)="onSubmit()">
          <div class="form-group">
            <div class="p-d-md-flex">
              <input
                class="cooperationInput"
                type="text"
                pInputText
                placeholder="{{ t('name') }}"
                formControlName="name" />
              <input
                class="cooperationInput"
                type="text"
                pInputText
                placeholder="{{ t('taxNo') }}"
                formControlName="taxNo" />
            </div>
            <div class="p-d-md-flex">
              <input
                class="cooperationInput"
                type="text"
                pInputText
                placeholder="{{ t('phoneNo') }}"
                formControlName="phoneNo" />
              <input
                class="cooperationInput"
                type="text"
                pInputText
                placeholder="{{ t('email') }}"
                formControlName="email" />
            </div>

            <div>
              <textarea
                class="cooperationInput p-mb-4"
                [rows]="2"
                [cols]="30"
                autoResize="autoResize"
                pInputTextarea
                placeholder="{{ t('textMsg') }}"
                formControlName="textMsg"></textarea>
            </div>

            <div class="p-field-checkbox">
              <p-checkbox
                [binary]="true"
                [formControl]="cooperationFormGroup.controls['agreement1']"
                id="cooperationCheckbox1"></p-checkbox>
              <label for="cooperationCheckbox1"
                >{{ t('checkbox1Desc1') }}
                <a
                  target="_blank"
                  href="https://gaponline.pl/gapDownloads/polityka-prywatnosci.pdf"
                  class="textBlue"
                  >{{ t('checkbox1Desc2') }}</a
                ></label
              >
            </div>

            <div class="p-field-checkbox">
              <p-checkbox
                [binary]="true"
                [formControl]="cooperationFormGroup.controls['agreement2']"
                id="cooperationCheckbox2"></p-checkbox>
              <label for="cooperationCheckbox2"
                >{{ t('checkbox2Desc1') }}
                <a
                  target="_blank"
                  class="textBlue"
                  href="https://gaponline.pl/gapDownloads/polityka-prywatnosci.pdf"
                  >{{ t('checkbox2Desc2') }}</a
                >
                {{ t('checkbox2Desc3') }}
              </label>
            </div>
            <div class="p-d-flex p-jc-center p-jc-md-end p-mt-4">
              <button
                type="submit"
                class="buttonYellow"
                style="width: 251px"
                [disabled]="!cooperationFormGroup.valid">
                {{ t('button') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="footer p-grid">
    <div class="wrapper p-grid p-ai-center">
      <a
        target="_blank"
        href="https://gaponline.pl/gapDownloads/polityka-prywatnosci.pdf"
        >{{ t('footer') }}</a
      >
    </div>
  </div>
</ng-container>

import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

export interface Vehicles {
  img: string;
  name: string;
}

@Component({
  selector: 'app-for-who',
  templateUrl: './for-who.component.html',
  styleUrls: ['./for-who.component.scss'],
})
export class ForWhoComponent implements OnInit {
  vehicles: Vehicles[];
  imgSrc = 'assets/images/vehicles/';

  constructor(private transloco: TranslocoService) {
    this.vehicles = [
      {
        img: '1.png',
        name: this.transloco.translate('home.for-who.vehicle.1'),
      },
      {
        img: '2.png',
        name: this.transloco.translate('home.for-who.vehicle.2'),
      },
      {
        img: '3.png',
        name: this.transloco.translate('home.for-who.vehicle.3'),
      },
      {
        img: '4.png',
        name: this.transloco.translate('home.for-who.vehicle.4'),
      },
      {
        img: '5.png',
        name: this.transloco.translate('home.for-who.vehicle.5'),
      },
      {
        img: '6.png',
        name: this.transloco.translate('home.for-who.vehicle.6'),
      },
      {
        img: '7.png',
        name: this.transloco.translate('home.for-who.vehicle.7'),
      },
      {
        img: '8.png',
        name: this.transloco.translate('home.for-who.vehicle.8'),
      },
      {
        img: '9.png',
        name: this.transloco.translate('home.for-who.vehicle.9'),
      },
    ];
  }

  ngOnInit(): void {}
}

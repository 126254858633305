<ng-container *transloco="let t; read: 'home'">
  <div class="p-grid p-nogutter p-jc-center benefitsSlider">
    <div class="p-col-12 p-text-center benefitsSlider__header">
      <h1 class="p-text-uppercase">
        {{ t('benefits.header.part1')
        }}<span>{{ t('benefits.header.part2') }}</span>
      </h1>
      <h1 class="p-text-normal">{{ t('benefits.header.part4') }}</h1>
    </div>

    <swiper class="slider">
      <div *ngFor="let benefit of benefits">
        <div class="slider__item">
          <div class="slider__item__content">
            <div class="p-mb-3 slider__item__content__image">
              <img
                src="assets/images/benefit/{{ benefit.img }}.png"
                [alt]="benefit.name"
                class="benefit-image" />
            </div>
            <div class="slider__item__content__description">
              <p class="textBlue p-text-bold">{{ benefit.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </swiper>
    <div class="p-grid p-jc-center p-nogutter backgroundWrapper">
      <div class="p-grid p-nogutter wrapper">
        <div class="p-col-12 p-md-6 p-xl-6">
          <div class="p-col-12 p20">
            <h1 class="p-text-normal">
              {{ t('benefits.slider.example.header.part1') }}
            </h1>
            <h1>
              {{ t('benefits.slider.example.header.part2')
              }}<span>{{ t('benefits.slider.example.header.part3') }}</span>
            </h1>
            <div class="p-col-12 p-d-md-none benefitsSlider__img">
              <img
                src="assets/images/suv2.png"
                alt="{{ t('benefits.slider.example.header.part2') }}{{
                  t('benefits.slider.example.header.part3')
                }}" />
            </div>
            <table class="benefitsSlider__example">
              <tr>
                <td class="benefitsSlider__example__left">
                  <p>{{ t('benefits.slider.example.left.1') }}</p>
                </td>
                <td class="benefitsSlider__example__right">
                  <h3 class="textBlue p-text-bold">
                    {{ t('benefits.slider.example.right.1') }}
                  </h3>
                </td>
              </tr>
              <tr>
                <td class="benefitsSlider__example__left">
                  <p>{{ t('benefits.slider.example.left.2') }}</p>
                </td>
                <td class="benefitsSlider__example__right">
                  <h3 class="textBlue p-text-normal">
                    {{ t('benefits.slider.example.right.2') }}
                  </h3>
                </td>
              </tr>
              <tr>
                <td class="benefitsSlider__example__left">
                  <p>{{ t('benefits.slider.example.left.3') }}</p>
                </td>
                <td class="benefitsSlider__example__right">
                  <h3 class="textBlue p-text-normal">
                    {{ t('benefits.slider.example.right.3') }}
                  </h3>
                </td>
              </tr>
            </table>
            <h3 class="textBlue p-text-normal p-pt-3 p-pb-2">
              {{ t('benefits.slider.example.left.4') }}
            </h3>
            <table class="benefitsSlider__example">
              <tr>
                <td class="benefitsSlider__example__left">
                  <p>{{ t('benefits.slider.example.left.5') }}</p>
                </td>
                <td class="benefitsSlider__example__right">
                  <h3 class="textBlue p-text-normal">
                    {{ t('benefits.slider.example.right.5') }}
                  </h3>
                </td>
              </tr>
              <tr>
                <td class="benefitsSlider__example__left">
                  <p>{{ t('benefits.slider.example.left.6') }}</p>
                </td>
                <td class="benefitsSlider__example__right">
                  <h3 class="p-text-bold textYellow">
                    {{ t('benefits.slider.example.right.6') }}
                  </h3>
                </td>
              </tr>
              <tr>
                <td class="benefitsSlider__example__left">
                  <p class="p-text-bold textBlue p-text-uppercase">
                    {{ t('benefits.slider.example.left.7') }}
                  </p>
                </td>
                <td class="benefitsSlider__example__right">
                  <h3 class="p-text-bold textYellow">
                    {{ t('benefits.slider.example.right.7') }}
                  </h3>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="p-d-none p-d-md-flex p-md-6 p-xl-6 benefitsSlider__img">
          <img
            src="assets/images/suv2.png"
            alt="{{ t('benefits.slider.example.header.part2') }}{{
              t('benefits.slider.example.header.part3')
            }}" />
          <img
            src="assets/images/bg3-img.png"
            class="backgroundImg"
            alt="tear" />
        </div>
      </div>
    </div>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ButtonComponent } from './button/button.component';
import { DialogComponent } from './dialog/dialog.component';
import { DropdownInputComponent } from './dropdown-input/dropdown-input.component';
import { FormHeaderComponent } from './form-header/form-header.component';
import { InfoboxAsideComponent } from './infobox-aside/infobox-aside.component';
import { LinesSeparatorComponent } from './lines-separator/lines-separator.component';
import { CurrencyPipe } from './pipes/currency.pipe';
import { DatePipe } from './pipes/date.pipe';
import { NumberPipe } from './pipes/number.pipe';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ValidationMessageComponent } from './validation-message/validation-message.component';

@NgModule({
  declarations: [
    ButtonComponent,
    CurrencyPipe,
    DatePipe,
    DropdownInputComponent,
    FormHeaderComponent,
    InfoboxAsideComponent,
    LinesSeparatorComponent,
    NumberPipe,
    ValidationMessageComponent,
    DialogComponent,
    TooltipComponent,
  ],
  exports: [
    AccordionModule,
    ButtonComponent,
    ConfirmDialogModule,
    CurrencyPipe,
    DatePipe,
    DropdownInputComponent,
    DropdownModule,
    FormHeaderComponent,
    FormsModule,
    InfoboxAsideComponent,
    InputMaskModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    LinesSeparatorComponent,
    NumberPipe,
    OverlayPanelModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    SwiperModule,
    TabViewModule,
    ToastModule,
    TranslocoModule,
    ValidationMessageComponent,
    MatTooltipModule,
    TooltipComponent,
  ],
  imports: [
    AccordionModule,
    CommonModule,
    ConfirmDialogModule,
    DropdownModule,
    FormsModule,
    InputMaskModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    OverlayPanelModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    SwiperModule,
    TabViewModule,
    ToastModule,
    TranslocoModule,
    MatTooltipModule,
  ],
  providers: [ConfirmationService, MessageService],
})
export class SharedModule {}

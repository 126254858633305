import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Breadcrumb } from '@sentry/angular';

import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://7eff90f385ed38821b9269e99d13e473@o4507391160745984.ingest.de.sentry.io/4507391164022864',
  enabled: environment['SENTRY_MONITORING'],
  attachStacktrace: true,
  maxBreadcrumbs: 500,
  normalizeDepth: 10,
  normalizeMaxBreadth: 5000,
  environment: environment['production'] ? 'production' : 'development',
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'xhr') {
      const breadcrumbDto: Breadcrumb = {
        ...breadcrumb,
        data: {
          ...breadcrumb.data,
          payload: JSON.parse(hint.input),
          response: JSON.parse(hint.xhr.response),
          responseUrl: hint.xhr.responseURL,
        },
      };
      return breadcrumbDto;
    }
    return breadcrumb;
  },
  sendClientReports: false,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.extraErrorDataIntegration({ depth: 10 }),
    Sentry.breadcrumbsIntegration({
      console: true,
      dom: true,
      fetch: true,
      history: true,
      sentry: true,
      xhr: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/gaponline\.click\/api/,
    /^https:\/\/ec2-3-126-153-136.eu-central-1.compute.amazonaws\.com\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}

const bootstrap = () =>
  document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });

bootstrap();

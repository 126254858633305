import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CalculateOfferResponse } from '../../../../../libs/idefend/src';
import { environment } from '../../environments/environment';
import { ContactDataInterface, CooperationDataInterface } from '../../interfaces/contact-data.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiBase = environment.apiBase;

  constructor(private http: HttpClient) {}

  getCalculation(dto): Observable<CalculateOfferResponse> {
    return this.http.post<CalculateOfferResponse>(
      `${this.apiBase}/idefend/policies/creation/calculate-offer`,
      dto,
    );
  }

  sendContactEmail(dto: ContactDataInterface): Observable<void> {
    return this.http.post<void>(`${this.apiBase}/mailer/contact`, dto);
  }

  sendCooperationEmail(dto: CooperationDataInterface): Observable<void> {
    return this.http.post<void>(`${this.apiBase}/mailer/cooperation`, dto);
  }

  checkAffiliationLink(link: string): Observable<any> {
    return this.http.get<void>(`${this.apiBase}/check-link/${link}`);
  }
}

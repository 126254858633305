import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DepreciationChartComponent } from './depreciation-chart.component';
import { DepreciationChartService } from './depreciation-chart.service';

@NgModule({
  declarations: [DepreciationChartComponent],
  imports: [CommonModule, NgApexchartsModule],
  exports: [DepreciationChartComponent],
  providers: [DepreciationChartService],
})
export class DepreciationChartModule {}

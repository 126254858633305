import { CalculateOfferResponse } from '../../shared/interfaces/calculate-offer-response.interface';

export function assignNewDiscountAmount(
  amount: string,
  offerState: CalculateOfferResponse
): string {
  // Check if have discount already
  if (
    offerState &&
    offerState.discountAmount &&
    Number(offerState.discountAmount)
  ) {
    if (Number(amount) > Number(offerState.discountAmount)) {
      return amount;
    } else {
      return offerState.discountAmount;
    }
  }
  return amount;
}

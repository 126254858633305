export function checkForInstalments(secondStepPayload): string {
  if (!secondStepPayload.paymentForm) {
    return null;
  }

  if (secondStepPayload.paymentForm?.value === 'PM_PAYU_M') {
    return 'ALL_REGULAR';
    // FIXME: Remove or fix if allowed payment period different than regular
    // if (!state.secondStepPayload.paymentPeriod) {
    //   return 'ALL_REGULAR';
    // }
    //
    // return state.secondStepPayload.paymentPeriod === 12
    //   ? 'ALL_REGULAR'
    //   : `${state.secondStepPayload.paymentPeriod}_REGULAR`;
  }
}

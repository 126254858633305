export interface GapSliderInterface {
  vehiclePurchasePrice: number;
  monthOfUse: number;
  monthsArray: number[];
  policyValue: number;
  gapValue: number;
}

export class GapSlider implements GapSliderInterface {
  vehiclePurchasePrice: number;
  monthOfUse: number;
  monthsArray: number[];
  policyValue: number;
  gapValue: number;

  constructor() {
    this.vehiclePurchasePrice = null;
    this.monthOfUse = null;
    this.monthsArray = null;
    this.policyValue = null;
    this.gapValue = null;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'DatePipe' })
export class DatePipe implements PipeTransform {
  private formatter = new Intl.DateTimeFormat('pl', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  transform(date: string | Date): string {
    return this.formatter.format(new Date(date));
  }
}

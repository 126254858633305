<ng-container *transloco="let t; read: 'home'">
  <div class="benefits p-grid p-nogutter p-jc-center">
    <div class="benefits__wrapper p-grid p-nogutter p-jc-center">
      <div class="p-col-12 p-text-center">
        <h1 class="p-text-uppercase">
          {{ t('benefits.header.part1')
          }}<span>{{ t('benefits.header.part2') }}</span>
        </h1>
        <h1 class="p-text-normal">{{ t('benefits.header.part3') }}</h1>
      </div>

      <div class="p-col-12 p-xl-4">
        <div class="p-grid p-nogutter">
          <div class="p-col-12 p-md-6 p-xl-12">
            <div class="benefits__tale p-shadow-9 p-md-m-1">
              <img
                class="picture"
                src="assets/images/calculator.png"
                alt="calculator" />
              <p>
                {{ t('benefits.content.col1') }}
                <a
                  routerLink="/"
                  fragment="home"
                  class="textYellow p-d-md-block p-d-xl-inline-flex p-pl-2"
                  >{{ t('benefits.content.checkThis') }}</a
                >
              </p>
              <div class="calculationLink">
                <img
                  src="assets/icons/calculator.svg"
                  alt="calculator" />
                <a
                  routerLink="/"
                  fragment="home"
                  class="textYellow"
                  >{{ t('benefits.content.calculate') }}</a
                >
              </div>
            </div>
          </div>
          <div class="p-col-12 p-md-6 p-xl-12">
            <div class="benefits__tale p-shadow-9 p-md-m-1">
              <img
                class="picture"
                src="assets/images/jaguar.png"
                alt="jaguar" />
              <p>
                {{ t('benefits.content.col2.part1') }}
                <a
                  target="_blank"
                  href="https://gaponline.pl/gapDownloads/utrata-wartosci.jpg"
                  class="textYellow"
                  >{{ t('benefits.content.checkThis') }}</a
                >
                {{ t('benefits.content.col2.part2') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-xl-8 p-grid">
        <div class="benefits__videoTab">
          <div class="benefits__videoTab__content">
            <div *ngIf="typeOfPolicy === 'invoice'">
              <!--              width="560" height="315" original values for iframe -->
              <iframe
                src="https://www.youtube.com/embed/D-O_t3OXXiI"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
            <div *ngIf="typeOfPolicy === 'casco'">
              <iframe
                src="https://www.youtube.com/embed/FO452vk1b9s"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
            <div *ngIf="typeOfPolicy === 'direct'">
              <iframe
                src="https://www.youtube.com/embed/ev6rTYMKdss"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
          </div>
          <div
            class="benefits__videoTab__header p-grid p-nogutter p-text-center p-ai-center">
            <div
              class="p-col-4 header"
              [ngClass]="
                typeOfPolicy === 'invoice' ? 'header--isActive' : 'header'
              "
              (click)="selectTypeOfPolicy('invoice')"
              (mouseleave)="opInvoice.hide()">
              <p>{{ t('tab.header1.videoLong') }}</p>
              <div class="imgWrapper">
                <img
                  src="assets/icons/info.svg"
                  class="p-mx-2"
                  alt="info"
                  (mouseover)="opInvoice.show($event)" />
                <p-overlayPanel
                  #opInvoice
                  [dismissable]="true"
                  [showTransitionOptions]="'200ms'"
                  (mouseleave)="opInvoice.hide()">
                  <ng-template pTemplate>
                    <div>
                      <span class="textGrey"
                        ><span class="p-text-bold">{{
                          t('tab.header1.long')
                        }}</span>
                        {{ t('tab.header1.content') }}
                      </span>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </div>
              <span class="p-text-light p-d-xl-block">{{
                t('tab.header1.short')
              }}</span>
            </div>
            <div
              class="p-col-4 header"
              [ngClass]="
                typeOfPolicy === 'casco' ? 'header--isActive' : 'header'
              "
              (click)="selectTypeOfPolicy('casco')"
              (mouseleave)="opCasco.hide()">
              <p>{{ t('tab.header2.videoLong') }}</p>
              <div class="imgWrapper">
                <img
                  src="assets/icons/info.svg"
                  class="p-mx-2"
                  alt="info"
                  (mouseover)="opCasco.show($event)" />
                <p-overlayPanel
                  class="centralPanel"
                  #opCasco
                  [dismissable]="true"
                  [showTransitionOptions]="'200ms'"
                  (mouseleave)="opCasco.hide()">
                  <ng-template pTemplate>
                    <div>
                      <span class="textGrey"
                        ><span class="p-text-bold">{{
                          t('tab.header2.long')
                        }}</span>
                        {{ t('tab.header2.content') }}
                      </span>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </div>
              <span class="p-text-light p-d-xl-block">{{
                t('tab.header2.short')
              }}</span>
            </div>
            <div
              class="p-col-4 header"
              [ngClass]="
                typeOfPolicy === 'direct' ? 'header--isActive' : 'header'
              "
              (click)="selectTypeOfPolicy('direct')"
              (mouseleave)="opDirect.hide()">
              <p>{{ t('tab.header3.videoLong') }}</p>
              <div class="imgWrapper">
                <img
                  src="assets/icons/info.svg"
                  class="p-mx-2"
                  alt="info"
                  (mouseover)="opDirect.show($event)" />
                <p-overlayPanel
                  class="rightSidePanel"
                  #opDirect
                  [dismissable]="true"
                  [showTransitionOptions]="'200ms'"
                  (mouseleave)="opDirect.hide()">
                  <ng-template pTemplate>
                    <div>
                      <span class="textGrey"
                        ><span class="p-text-bold">{{
                          t('tab.header3.long')
                        }}</span>
                        {{ t('tab.header3.content') }}
                      </span>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </div>
            </div>
          </div>
        </div>
        <div class="benefits__description">
          <p>
            <span>{{ t('benefits.content.col3.part1') }}</span
            >{{ t('benefits.content.col3.part2') }}
            <span>{{ t('benefits.content.col3.part3') }}</span
            >{{ t('benefits.content.col3.part4') }}
            <span>{{ t('benefits.content.col3.part5') }}</span
            >{{ t('benefits.content.col3.part6') }}
            <a
              class="textYellow textUnderlined p-text-bold"
              target="_blank"
              href="https://gaponline.pl/gapDownloads/dane-wypadki.png">
              {{ t('benefits.content.col3.part7')
              }}{{ t('benefits.content.col3.part8') }}
            </a>
            {{ t('benefits.content.col3.part9') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>

import * as moment from 'moment';
import { CalculateOfferPayload } from '../../shared/interfaces/calculate-offer-payload.interface';
import { FirstStepPayload } from '../../shared/interfaces/first-step-payload.interface';
import { WarrantyCreatorState } from '../store/warranty-creator.state';
import { checkForInstalments } from './check-for-instalments';

export function offerPayloadMapper(
  portfolios,
  vehicleCategories,
  firstStepPayload,
  secondStepPayload,
  nodes,
): CalculateOfferPayload {
  try {
    if (!firstStepPayload || !secondStepPayload) {
      return;
    }

    const product = portfolios
      .filter(
        (portfolio) =>
          portfolio.productDerivativeAlias ===
          firstStepPayload.selectedInsuranceVariant,
      )
      .find((portfolio) => {
        if (firstStepPayload.vehicleType.code === 'LCV') {
          return portfolio.vehicleCategories.find(
            (category) => category.code === 'LCV',
          );
        } else {
          return !portfolio.productCode.includes('LCV');
        }
      });

    return {
      productCode: product.productCode,
      saleInitiatedOn: moment().format(),
      instalmentSplittingSchemeCode: checkForInstalments(secondStepPayload),
      vehicleSnapshot: {
        firstRegisteredOn: moment(firstStepPayload.vehicleRegDate).format(
          'YYYY-MM-DD',
        ),
        categoryCode: vehicleCategories.find(
          (category) =>
            category.id === firstStepPayload.selectedVehicleModel.categoryId,
        ).code,
        purchasePrice: getPurchasePrice(firstStepPayload),
        purchasePriceNet: getPurchasePriceNet(firstStepPayload),
        purchasePriceInputType: purchasePriceInputType(firstStepPayload),
        usageTypeCode: getUsageTypeCode(firstStepPayload),
        purchasePriceVatReclaimableCode: getVatReclaimable(firstStepPayload),
        usageCode: getUsageCode(firstStepPayload),
        makeCode: firstStepPayload.selectedVehicleMake.code,
        modelCode: firstStepPayload.selectedVehicleModel.code,
        engineSize:
          firstStepPayload.engineType.code === 'ELECTRIC'
            ? 0
            : firstStepPayload.engineSize,
        purchasedOn: moment(firstStepPayload.vehicleBuyDate).format(
          'YYYY-MM-DD',
        ),
        mfgWarrantyInceptedOn: firstStepPayload.manufacturerWarranty
          ? moment(firstStepPayload.mfrWarrantyInceptedOn).format('YYYY-MM-DD')
          : moment('1970-01-01').format('YYYY-MM-DD'),
        mfgWarrantyMileage: firstStepPayload.manufacturerWarranty
          ? Number(firstStepPayload.mfrWarrantyMileage.code)
          : 0,
        mfgWarrantyTerm: firstStepPayload.manufacturerWarranty
          ? firstStepPayload.mfrWarrantyPeriod?.id
          : 0,
        mileage: firstStepPayload.mileage,
        driveTypeCode: firstStepPayload.driveType === '2x4' ? '2WD' : '4WD',
        engineTypeCode: firstStepPayload.engineType['code'],
      },
      options: {
        ANNUAL_MILEAGE: secondStepPayload.annualMileage,
        CLAIM_LIMIT: `${secondStepPayload.insuranceLimitCode}`,
        DEDUCTIBLE: 'XS_0',
        PAYMENT_METHOD: secondStepPayload.paymentForm?.value,
        PAYMENT_TERM: secondStepPayload.paymentType,
        TERM: `${secondStepPayload.policyPeriod}`,
      },
      extras: {
        CNG_LPG: secondStepPayload.additionalOptions?.includes('CNG_LPG'),
        INFOTAINMENT:
          secondStepPayload.additionalOptions?.includes('INFOTAINMENT'),
        RENTAL: secondStepPayload.additionalOptions?.includes('RENTAL'),
      },
    };
  } catch (e) {
    console.error('error mapping offer payload', e);
  }
}

export function getUsageTypeCode(firstStepPayload: FirstStepPayload): string {
  let usageTypeCode: string;
  const hasOptionSelected = firstStepPayload.vehicleType.options.find(
    (option) => option.isSelected,
  );

  if (
    firstStepPayload.vehiclePriceType &&
    firstStepPayload.vehiclePriceType === '50%'
  ) {
    usageTypeCode = 'BUSINESS';
  }

  if (hasOptionSelected) {
    usageTypeCode = 'BUSINESS';
  } else {
    usageTypeCode = 'INDIVIDUAL';
  }

  if (firstStepPayload.vehiclePolicyPriceType) {
    if (
      firstStepPayload.vehiclePolicyPriceType === 'Netto' ||
      firstStepPayload.vehiclePolicyPriceType === '50%'
    ) {
      usageTypeCode = 'BUSINESS';
    }
  }
  return usageTypeCode;
}

export function getVatReclaimable(firstStepPayload: FirstStepPayload): string {
  let vatReclaimable: string;
  if (firstStepPayload.selectedInsuranceVariant === 'D2C') {
    vatReclaimable = 'NO';
  }
  if (firstStepPayload.vehiclePriceType) {
    if (firstStepPayload.vehiclePriceType === 'Netto') {
      vatReclaimable = 'NO';
    }
    if (firstStepPayload.vehiclePriceType === 'Brutto') {
      vatReclaimable = 'NO';
    }
    if (firstStepPayload.vehiclePriceType === '50%') {
      vatReclaimable = 'HALF';
    }
  }
  if (firstStepPayload.vehiclePolicyPriceType) {
    if (firstStepPayload.vehiclePolicyPriceType === 'Netto') {
      vatReclaimable = 'YES';
    }
    if (firstStepPayload.vehiclePolicyPriceType === 'Brutto') {
      vatReclaimable = 'NO';
    }
    if (firstStepPayload.vehiclePolicyPriceType === '50%') {
      vatReclaimable = 'HALF';
    }
  }
  return vatReclaimable;
}

export function getPurchasePrice(firstStepPayload: FirstStepPayload): number {
  if (firstStepPayload.selectedInsuranceVariant === 'D2C') {
    return 1;
  }
  if (firstStepPayload.vehiclePriceType === 'Netto') {
    return ((firstStepPayload.vehiclePrice * 1.23) / 100) * 10000;
  }
  if (firstStepPayload.vehiclePriceType === 'Brutto') {
    return firstStepPayload.vehiclePrice * 100;
  }
  if (firstStepPayload.vehiclePriceType === '50%') {
    return Math.round((firstStepPayload.vehiclePrice * 1.23) / 100) * 10000;
  }
}

export function getPurchasePriceNet(
  firstStepPayload: FirstStepPayload,
): number {
  if (firstStepPayload.vehiclePriceType === 'Netto') {
    return Number((firstStepPayload.vehiclePrice * 100).toFixed(0));
  }
  if (firstStepPayload.vehiclePriceType === 'Brutto') {
    return Number(
      ((firstStepPayload.vehiclePrice / 1.23 / 100) * 10000).toFixed(0),
    );
  }
  if (firstStepPayload.vehiclePriceType === '50%') {
    return Number(
      (Math.round(firstStepPayload.vehiclePrice / 1.115 / 100) * 10000).toFixed(
        0,
      ),
    );
  }
}

export function purchasePriceInputType(
  firstStepPayload: FirstStepPayload,
): string {
  if (firstStepPayload.selectedInsuranceVariant === 'D2C') {
    return 'WITH_VAT';
  }

  if (firstStepPayload.vehiclePriceType) {
    return firstStepPayload.vehiclePriceType === 'Brutto'
      ? 'WITH_VAT'
      : 'WITHOUT_VAT';
  }

  if (firstStepPayload.vehiclePolicyPriceType) {
    return firstStepPayload.vehiclePolicyPriceType === 'Brutto'
      ? 'WITH_VAT'
      : 'WITHOUT_VAT';
  }
}

export function getUsageCode(firstStepPayload: FirstStepPayload): string {
  const selectedOptionsFound = firstStepPayload.vehicleType.options
    .filter((option) => option.isSelected)
    .map((option) => option.code);
  if (selectedOptionsFound.length) {
    return selectedOptionsFound[0];
  } else {
    return 'STANDARD';
  }
}

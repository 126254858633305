import { FirstStepPayload } from '../../shared/interfaces/first-step-payload.interface';
import { FourthStepPayload } from '../../shared/interfaces/fourth-step-payload.interface';
import { WarrantySecondStepPayload } from '../../shared/interfaces/second-step-payload.interface';

export function checkForNullValues(
  payload: FirstStepPayload | WarrantySecondStepPayload | FourthStepPayload,
  excludedKeys = []
): {
  nullables: boolean;
  nullKeys: string[];
} {
  let nullables = false;
  const nullKeys = [];

  for (const [key, value] of Object.entries(payload)) {
    if (!excludedKeys.includes(key)) {
      if (value === null) {
        nullKeys.push(key);
      }
      nullables = value === null;
    }
  }

  return {
    nullables,
    nullKeys,
  };
}

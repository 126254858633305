import { Currency } from '../../../../interfaces/idefend/currency.interface';
import { QuestionReplyHead } from '../../../../interfaces/idefend/interfaces';
import { LocalizationSetting } from '../../../../interfaces/idefend/localization-settings.interface';
import { PolicyGroupResponse } from '../../../../interfaces/idefend/policy-groups-response.interface';
import { PortfolioGroups } from '../../../../interfaces/idefend/portfolio-group.interface';
import { Portfolio } from '../../../../interfaces/idefend/portfolio.interface';
import { SodanReplayHeads } from '../../../../interfaces/idefend/sodan-replay-heads.interface';
import { StepsValidators } from '../../../../interfaces/idefend/steps-validators.interface';
import { VehicleMake } from '../../../../interfaces/idefend/vehicle-make.interface';
import { VehicleModel } from '../../../../interfaces/idefend/vehicle-model.interface';
import { VehicleUsage } from '../../../../interfaces/idefend/vehicle-usage.interface';
import { VehiclesCategory } from '../../../../interfaces/idefend/vehilces-category.interface';
import { CalculateOfferResponse } from '../../shared/interfaces/calculate-offer-response.interface';
import { FirstStepPayload } from '../../shared/interfaces/first-step-payload.interface';
import { FourthStepPayload } from '../../shared/interfaces/fourth-step-payload.interface';
import { WarrantySecondStepPayload } from '../../shared/interfaces/second-step-payload.interface';
import { ThirdStepPayload } from '../../shared/interfaces/third-step-payload.interface';

export interface WarrantyOption {
  code: string;
  description: string;
  id: number;
  name: string;
}

export interface WarrantyCreatorStateModel {
  isWarranty: boolean;
  portfolios: Portfolio[];
  groups: PortfolioGroups[];
  currency: Currency;
  localizationSettings: LocalizationSetting;
  sodanReplyHeads: SodanReplayHeads;
  policyGroups: PolicyGroupResponse;
  vehicleCategories: VehiclesCategory[];
  vehicleUsages: VehicleUsage[];
  vehicleMakes: VehicleMake[];
  vehicleModels: VehicleModel[];
  vehicleModelsFilter: VehicleModel[];
  offer: CalculateOfferResponse;
  activeStep: number;
  stepsValidator: StepsValidators;
  engineTypeOptions: WarrantyOption[];
  nodes: any[];
  sellerNodeCode: string;
  productOffers: QuestionReplyHead;
  firstStepPayload: FirstStepPayload;
  secondStepPayload: WarrantySecondStepPayload;
  thirdStepPayload: ThirdStepPayload;
  fourthStepPayload: FourthStepPayload;
  manufacturerWarranty: boolean;
  isLoading: boolean;
  isSodanLoading: boolean;
}

export const defaults: WarrantyCreatorStateModel = {
  isWarranty: false,
  portfolios: [],
  groups: [],
  currency: null,
  localizationSettings: null,
  sodanReplyHeads: null,
  policyGroups: null,
  vehicleCategories: [],
  vehicleUsages: [],
  vehicleMakes: [],
  vehicleModels: [],
  vehicleModelsFilter: [],
  offer: null,
  activeStep: null,
  stepsValidator: null,
  engineTypeOptions: [],
  nodes: [],
  sellerNodeCode: null,
  productOffers: null,
  firstStepPayload: null,
  secondStepPayload: null,
  thirdStepPayload: null,
  fourthStepPayload: null,
  manufacturerWarranty: null,
  isLoading: false,
  isSodanLoading: false,
};

// NOTE: this is production environment for warranties
export const environment = {
  baseUrl: 'https://form.gwarancjeonline.pl',
  apiBase: 'https://gaponline.click/api',
  apkUrl: 'https://gwarancjeonline.pl',
  production: true,
  hmr: false,
  hotjar: '2094485',
  homeFtp: false,
  GTMID: 'GT-K5QVTZ74',
  GAID: 'G-SBZYLQKFNV',
  trustBadgeId: '',
  SENTRY_MONITORING: false,
  SENTRY_AUTH_TOKEN:
    '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',
  classicGap: false,
};

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class GaService {
  /* FIXME: there is another implementation for GTM used in classic gap,
   *   see the gtmService: GoogleTagManagerService in app component.ts */
  /*private headScript = `<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer',${environment.GTMID});</script>`;*/

  /* FIXME: there is another implementation for GTM used in classic gap,
   *   see the gtmService: GoogleTagManagerService in app component.ts */
  /*private bodyScriptSrc = `https://www.googletagmanager.com/ns.html?id=${environment.GTMID}`;*/
  /*private height = '0';
  private width = '0';
  private style = 'display:none;visibility:hidden';*/

  private gaIdScript = `<script async src="https://www.googletagmanager.com/gtag/js?id=${environment.GAID}"></script>`;
  private gaDataLayerScript = `<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', ${environment.GAID});
</script>`;

  constructor() {}

  init() {
    if (!environment.GAID) {
      return;
    }

    document.head.insertAdjacentHTML('beforeend', this.gaIdScript);
    document.head.insertAdjacentHTML('beforeend', this.gaDataLayerScript);

    /* FIXME: there is another implementation for GTM used in classic gap,
     *   see the gtmService: GoogleTagManagerService in app component.ts */
    /*document.head.insertAdjacentHTML('beforeend', this.headScript);
    const bodyScript = document.createElement('noscript');
    const bodyIframe = document.createElement('iframe');
    bodyIframe.src = this.bodyScriptSrc;
    bodyIframe.height = this.height;
    bodyIframe.width = this.width;
    bodyIframe.setAttribute('style', this.style);
    bodyScript.appendChild(bodyIframe);
    document.body.appendChild(bodyScript);*/
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'apk',
    loadChildren: () =>
      import('./pages/apk/apk.module').then((m) => m.ApkModule),
  },
  {
    path: 'cta',
    loadChildren: () =>
      import('./pages/cta/cta.module').then((m) => m.CtaModule),
  },
  {
    path: 'new-policy',
    loadChildren: () =>
      import('./pages/policy-creator/policy-creator.module').then(
        (m) => m.PolicyCreatorModule
      ),
  },
  {
    path: 'new-warranty',
    loadChildren: () =>
      import('./pages/warranty-creator/warranty-creator.module').then(
        (m) => m.WarrantyCreatorModule
      ),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      enableTracing: false,
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { catchError, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { WarrantyCreatorState } from '../pages/warranty-creator/store/warranty-creator.state';
import { WarrantyOfferState } from '../pages/warranty-creator/store/warranty-offer.state';
import { WarrantyCreatorService } from '../pages/warranty-creator/warranty-creator.service';

@Injectable()
export class SentryInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private warrantyCreatorService: WarrantyCreatorService,
    private activatedRoute: ActivatedRoute,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (environment.SENTRY_MONITORING) {
          Sentry.captureException(error, {
            data: {
              url: request.url,
              payload: request.body,
              error: error.error,
              warrantyCreatorState: this.store.selectSnapshot(
                WarrantyCreatorState.state,
              ),
              warrantyOfferState: this.store.selectSnapshot(
                WarrantyOfferState.state,
              ),
            },
            captureContext: {
              extra: {
                url: request.url,
                payload: request.body,
                error: error.error,
                warrantyCreatorState: this.store.selectSnapshot(
                  WarrantyCreatorState.state,
                ),
                warrantyOfferState: this.store.selectSnapshot(
                  WarrantyOfferState.state,
                ),
              },
            },
          });

          // Workaround for disabling envelopes in sentry
          Sentry.onLoad(() => {
            const envelopes = document.querySelectorAll(
              '.sentry-error-embed-wrapper',
            );
            envelopes.forEach((envelope) => {
              envelope['style'].display = 'none';
            });
          });
        }
        return throwError(error);
      }),
    );
  }
}

<ng-container *transloco="let t">
  <div class="p-grid p-dir-col answerQuestion">
    <div
      class="p-col-12 p-d-flex"
      *ngIf="questionNumber === 10">
      <button
        class="closeButton"
        (click)="close()">
        <img
          src="assets/icons/cross.svg"
          alt="menu" />
      </button>
    </div>
    <p
      class="p-text-bold p-px-md-4"
      *ngIf="questionNumber < 10">
      {{ sectionNumber }}.{{ questionNumber }}.{{
        t('faq.' + sectionNumber + '.question.' + questionNumber)
      }}
    </p>

    <div
      class="p-col-12 answerQuestion__content"
      [ngStyle]="{ height: questionNumber === 10 ? '460px' : '100%' }">
      <div class="p-text-left p-pl-md-4 p-pb-md-4 scrollWrapper">
        <ng-container *ngIf="sectionNumber === 1 && questionNumber === 1">
          <p>
            <b>{{ t('faq.1.answer.1.part1') }}</b>
            {{ t('faq.1.answer.1.part2') }}
            <b>{{ t('faq.1.answer.1.part3') }}</b>
            {{ t('faq.1.answer.1.part4') }}
            <b>{{ t('faq.1.answer.1.part5') }}</b
            >{{ t('faq.1.answer.1.part6') }}
          </p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 1 && questionNumber === 2">
          <p>{{ t('faq.1.answer.2.part1') }}</p>
          <p>
            <b>{{ t('faq.1.answer.2.part2') }}</b>
          </p>
          <p>{{ t('faq.1.answer.2.part3') }}</p>
          <p>
            <b>{{ t('faq.1.answer.2.part4') }}</b
            >{{ t('faq.1.answer.2.part5') }}
          </p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 2 && questionNumber === 1">
          <p>
            <b>{{ t('faq.2.answer.1.part1') }}</b>
            {{ t('faq.2.answer.1.part2') }}
          </p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 2 && questionNumber === 2">
          <p>
            <b>{{ t('faq.2.answer.2.part1') }}</b>
            {{ t('faq.2.answer.2.part2') }}
          </p>
          <p>
            <b>{{ t('faq.2.answer.2.part3') }}</b>
            {{ t('faq.2.answer.2.part4') }}
          </p>
          <p>
            <b>{{ t('faq.2.answer.2.part5') }}</b>
            {{ t('faq.2.answer.2.part6') }}
          </p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 3 && questionNumber === 1">
          <p>
            <b>{{ t('faq.3.answer.1.part1') }}</b>
            {{ t('faq.3.answer.1.part2') }}
          </p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 3 && questionNumber === 2">
          <p>
            <b>{{ t('faq.3.answer.2.part1') }}</b>
            {{ t('faq.3.answer.2.part2') }}
          </p>
          <p>
            <b>{{ t('faq.3.answer.2.part3') }}</b>
            {{ t('faq.3.answer.2.part4') }}
          </p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 4 && questionNumber === 1">
          <p>{{ t('faq.4.answer.1.part1') }}</p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 4 && questionNumber === 2">
          <p>
            <b>{{ t('faq.4.answer.2.part2') }}</b>
            {{ t('faq.4.answer.2.part3') }}
          </p>
          <p>
            <b>{{ t('faq.4.answer.2.part4') }}</b>
            {{ t('faq.4.answer.2.part5') }}
          </p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 5 && questionNumber === 1">
          <p>
            {{ t('faq.5.answer.1.part1') }}
            <b>{{ t('faq.5.answer.1.part2') }}</b>
            {{ t('faq.5.answer.1.part3') }}
            <a href="mailto:szkodyGap@defendinsurance.pl">{{
              t('faq.5.answer.1.part4')
            }}</a>
          </p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 5 && questionNumber === 2">
          <p>
            <b>{{ t('faq.5.answer.2.part1') }}</b>
            {{ t('faq.5.answer.2.part2') }}
          </p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 5 && questionNumber === 3">
          <p>{{ t('faq.5.answer.3.part1') }}</p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 6 && questionNumber === 1">
          <p>
            <b>{{ t('faq.6.answer.1.part1') }}</b>
            {{ t('faq.6.answer.1.part2') }}
          </p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 6 && questionNumber === 2">
          <p>{{ t('faq.6.answer.2.part1') }}</p>
          <p>{{ t('faq.6.answer.2.part2') }}</p>
          <p>{{ t('faq.6.answer.2.part3') }}</p>
          <p>{{ t('faq.6.answer.2.part4') }}</p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 6 && questionNumber === 3">
          <p>{{ t('faq.6.answer.3.part1') }}</p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 7 && questionNumber === 1">
          <p>{{ t('faq.7.answer.1.part1') }}</p>
          <p>
            {{ t('faq.7.answer.1.part2') }}
            <a href="mailto:info@defendinsurance.pl">{{
              t('faq.7.answer.1.part3')
            }}</a>
            {{ t('faq.7.answer.1.part4') }}
            <a
              target="_blank"
              href="https://gaponline.pl/download/pozostale/formularz-anulowanie.pdf"
              >{{ t('faq.7.answer.1.part5') }}</a
            >
          </p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 7 && questionNumber === 2">
          <p>{{ t('faq.7.answer.2.part1') }}</p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 8 && questionNumber === 1">
          <p>
            {{ t('faq.8.answer.1.part1') }}
            <a href="mailto:info@defendinsurance.pl">{{
              t('faq.8.answer.1.part2')
            }}</a>
            {{ t('faq.8.answer.1.part3') }}
          </p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 8 && questionNumber === 2">
          <p>
            {{ t('faq.8.answer.2.part1') }}
            <a href="mailto:bok@gaponline.pl">{{
              t('faq.8.answer.2.part2')
            }}</a>
            {{ t('faq.8.answer.2.part3') }}
            <a href="mailto:info@defendinsurance.pl">{{
              t('faq.8.answer.2.part4')
            }}</a>
            {{ t('faq.8.answer.2.part5') }}
          </p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 9 && questionNumber === 1">
          <p>{{ t('faq.9.answer.1.part1') }}</p>
          <p>{{ t('faq.9.answer.1.part2') }}</p>
          <p>
            {{ t('faq.9.answer.1.part3') }}
            <a
              target="_blank"
              href="https://gaponline.pl/download/pozostale/polityka prywatnosci.pdf"
              >{{ t('faq.9.answer.1.part4') }}</a
            >
          </p>
        </ng-container>

        <ng-container *ngIf="sectionNumber === 9 && questionNumber === 2">
          <p>
            {{ t('faq.9.answer.2.part1') }}
            <a href="mailto:data@defendinsurance.eu">{{
              t('faq.9.answer.2.part2')
            }}</a>
          </p>
          <p>{{ t('faq.9.answer.2.part3') }}</p>
          <p>
            {{ t('faq.9.answer.2.part4') }}
            <a
              target="_blank"
              href="https://gaponline.pl/download/pozostale/polityka prywatnosci.pdf"
              >{{ t('faq.9.answer.2.part5') }}</a
            >
          </p>
        </ng-container>

        <ng-container *ngIf="questionNumber === 10">
          <div class="p-grid p-nogutter p-jc-center faqExtended">
            <div class="p-grid wrapper p-py-3 p-md-px-3">
              <div
                class="p-col-12"
                *ngIf="sectionNumber === 1 || sectionNumber === 10">
                <div class="faqExtended__header">
                  <h2 class="textYellow">
                    {{ t('faq.1.number') }}{{ t('faq.1.header') }}
                  </h2>
                </div>
                <p-accordion>
                  <p-accordionTab header="{{ t('faq.1.question.1') }}">
                    <p>
                      <b>{{ t('faq.1.answer.1.part1') }}</b>
                      {{ t('faq.1.answer.1.part2') }}
                      <b>{{ t('faq.1.answer.1.part3') }}</b>
                      {{ t('faq.1.answer.1.part4') }}
                      <b>{{ t('faq.1.answer.1.part5') }}</b
                      >{{ t('faq.1.answer.1.part6') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.1.question.2') }}">
                    <p>{{ t('faq.1.answer.2.part1') }}</p>
                    <p>
                      <b>{{ t('faq.1.answer.2.part2') }}</b>
                    </p>
                    <p>{{ t('faq.1.answer.2.part3') }}</p>
                    <p>
                      <b>{{ t('faq.1.answer.2.part4') }}</b
                      >{{ t('faq.1.answer.2.part5') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.1.question.3') }}">
                    <p>
                      <b>{{ t('faq.1.answer.3.part1') }}</b>
                      {{ t('faq.1.answer.3.part2') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.1.question.4') }}">
                    <p>
                      <b>{{ t('faq.1.answer.4.part1') }}</b>
                      {{ t('faq.1.answer.4.part2') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.1.question.5') }}">
                    <p>{{ t('faq.1.answer.5.part1') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.1.question.6') }}">
                    <p>{{ t('faq.1.answer.6.part1') }}</p>
                    <p>{{ t('faq.1.answer.6.part2') }}</p>
                    <p>{{ t('faq.1.answer.6.part3') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.1.question.7') }}">
                    <p>
                      <b>{{ t('faq.1.answer.7.part1') }}</b>
                      {{ t('faq.1.answer.7.part2') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.1.question.8') }}">
                    <p>{{ t('faq.1.answer.8.part1') }}</p>
                    <p>
                      <b>{{ t('faq.1.answer.8.part2') }}</b>
                      {{ t('faq.1.answer.8.part3') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.1.question.9') }}">
                    <p>
                      <b>{{ t('faq.1.answer.9.part1') }}</b>
                      {{ t('faq.1.answer.9.part2') }}
                    </p>
                  </p-accordionTab>
                </p-accordion>
              </div>

              <div
                class="p-col-12"
                *ngIf="sectionNumber === 2 || sectionNumber === 10">
                <div class="faqExtended__header">
                  <h2 class="textYellow">
                    {{ t('faq.2.number') }}{{ t('faq.2.header') }}
                  </h2>
                </div>
                <p-accordion>
                  <p-accordionTab header="{{ t('faq.2.question.1') }}">
                    <p>
                      <b>{{ t('faq.2.answer.1.part1') }}</b>
                      {{ t('faq.2.answer.1.part2') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.2.question.2') }}">
                    <p>
                      <b>{{ t('faq.2.answer.2.part1') }}</b>
                      {{ t('faq.2.answer.2.part2') }}
                    </p>
                    <p>
                      <b>{{ t('faq.2.answer.2.part3') }}</b>
                      {{ t('faq.2.answer.2.part4') }}
                    </p>
                    <p>
                      <b>{{ t('faq.2.answer.2.part5') }}</b>
                      {{ t('faq.2.answer.2.part6') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.2.question.3') }}">
                    <p>{{ t('faq.2.answer.3.part1') }}</p>
                    <p>
                      <b>{{ t('faq.2.answer.3.part2') }}</b>
                      {{ t('faq.2.answer.3.part3') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.2.question.4') }}">
                    <p>
                      {{ t('faq.2.answer.4.part1') }}
                      {{ t('faq.2.answer.4.part2') }}
                      {{ t('faq.2.answer.4.part3') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.2.question.5') }}">
                    <p>
                      {{ t('faq.2.answer.5.part1')
                      }}{{ t('faq.2.answer.5.part2') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.2.question.6') }}">
                    <p>{{ t('faq.2.answer.6.part1') }}</p>
                  </p-accordionTab>
                </p-accordion>
              </div>

              <div
                class="p-col-12"
                *ngIf="sectionNumber === 3 || sectionNumber === 10">
                <div class="faqExtended__header">
                  <h2 class="textYellow">
                    {{ t('faq.3.number') }}{{ t('faq.3.header') }}
                  </h2>
                </div>
                <p-accordion>
                  <p-accordionTab header="{{ t('faq.3.question.1') }}">
                    <p>
                      <b>{{ t('faq.3.answer.1.part1') }}</b>
                      {{ t('faq.3.answer.1.part2') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.3.question.2') }}">
                    <p>
                      <b>{{ t('faq.3.answer.2.part1') }}</b>
                      {{ t('faq.3.answer.2.part2') }}
                    </p>
                    <p>
                      <b>{{ t('faq.3.answer.2.part3') }}</b>
                      {{ t('faq.3.answer.2.part4') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.3.question.3') }}">
                    <p>{{ t('faq.3.answer.3.part1') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.3.question.4') }}">
                    <p>
                      <b>{{ t('faq.3.answer.4.part1') }}</b>
                    </p>
                    <p>{{ t('faq.3.answer.4.part2') }}</p>
                    <p>{{ t('faq.3.answer.4.part3') }}</p>
                    <p>{{ t('faq.3.answer.4.part4') }}</p>
                    <p>{{ t('faq.3.answer.4.part5') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.3.question.5') }}">
                    <p>{{ t('faq.3.answer.5.part1') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.3.question.6') }}">
                    <p>
                      <b>{{ t('faq.3.answer.6.part1') }}</b
                      >{{ t('faq.3.answer.6.part2') }}
                    </p>

                    <p>
                      <b>{{ t('faq.3.answer.6.part3') }}</b
                      >{{ t('faq.3.answer.6.part4') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.3.question.7') }}">
                    <p>
                      <b>{{ t('faq.3.answer.7.part1') }}</b>
                      {{ t('faq.3.answer.7.part2') }}
                      <b>{{ t('faq.3.answer.7.part3') }}</b>
                      {{ t('faq.3.answer.7.part4') }}
                      <b>{{ t('faq.3.answer.7.part5') }}</b>
                      {{ t('faq.3.answer.7.part6') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.3.question.8') }}">
                    <p>
                      <b>{{ t('faq.3.answer.8.part1') }}</b>
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.3.question.9') }}">
                    <p>
                      {{ t('faq.3.answer.9.part1') }}
                      <b>{{ t('faq.3.answer.9.part2') }}</b>
                      {{ t('faq.3.answer.9.part3') }}
                    </p>
                  </p-accordionTab>
                </p-accordion>
              </div>

              <div
                class="p-col-12"
                *ngIf="sectionNumber === 4 || sectionNumber === 10">
                <div class="faqExtended__header">
                  <h2 class="textYellow">
                    {{ t('faq.4.number') }}{{ t('faq.4.header') }}
                  </h2>
                </div>
                <p-accordion>
                  <p-accordionTab header="{{ t('faq.4.question.1') }}">
                    <p>{{ t('faq.4.answer.1.part1') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.4.question.2') }}">
                    <p>{{ t('faq.4.answer.2.part1') }}</p>
                    <p>
                      <b>{{ t('faq.4.answer.2.part2') }}</b>
                      {{ t('faq.4.answer.2.part3') }}
                    </p>
                    <p>
                      <b>{{ t('faq.4.answer.2.part4') }}</b>
                      {{ t('faq.4.answer.2.part5') }}
                    </p>
                  </p-accordionTab>
                </p-accordion>
              </div>

              <div
                class="p-col-12"
                *ngIf="sectionNumber === 5 || sectionNumber === 10">
                <div class="faqExtended__header">
                  <h2 class="textYellow">
                    {{ t('faq.5.number') }}{{ t('faq.5.header') }}
                  </h2>
                </div>
                <p-accordion>
                  <p-accordionTab header="{{ t('faq.5.question.1') }}">
                    <p>
                      {{ t('faq.5.answer.1.part1') }}
                      <b>{{ t('faq.5.answer.1.part2') }}</b>
                      {{ t('faq.5.answer.1.part3') }}
                      <a href="mailto:szkodyGap@defendinsurance.pl">{{
                        t('faq.5.answer.1.part4')
                      }}</a>
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.5.question.2') }}">
                    <p>
                      <b>{{ t('faq.5.answer.2.part1') }}</b>
                      {{ t('faq.5.answer.2.part2') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.5.question.3') }}">
                    <p>{{ t('faq.5.answer.3.part1') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.5.question.4') }}">
                    <p>{{ t('faq.5.answer.4.part1') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.5.question.5') }}">
                    <p>{{ t('faq.5.answer.5.part1') }}</p>
                    <p>{{ t('faq.5.answer.5.part2') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.5.question.6') }}">
                    <p>{{ t('faq.5.answer.6.part1') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.5.question.7') }}">
                    <p>{{ t('faq.5.answer.7.part1') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.5.question.8') }}">
                    <p>{{ t('faq.5.answer.8.part1') }}</p>
                    <p>{{ t('faq.5.answer.8.part2') }}</p>
                    <p>{{ t('faq.5.answer.8.part3') }}</p>
                    <p>{{ t('faq.5.answer.8.part4') }}</p>
                    <p>{{ t('faq.5.answer.8.part5') }}</p>
                  </p-accordionTab>
                </p-accordion>
              </div>

              <div
                class="p-col-12"
                *ngIf="sectionNumber === 6 || sectionNumber === 10">
                <div class="faqExtended__header">
                  <h2 class="textYellow">
                    {{ t('faq.6.number') }}{{ t('faq.6.header') }}
                  </h2>
                </div>
                <p-accordion>
                  <p-accordionTab header="{{ t('faq.6.question.1') }}">
                    <p>
                      <b>{{ t('faq.6.answer.1.part1') }}</b>
                      {{ t('faq.6.answer.1.part2') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.6.question.2') }}">
                    <p>{{ t('faq.6.answer.2.part1') }}</p>
                    <p>{{ t('faq.6.answer.2.part2') }}</p>
                    <p>{{ t('faq.6.answer.2.part3') }}</p>
                    <p>{{ t('faq.6.answer.2.part4') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.6.question.3') }}">
                    <p>{{ t('faq.6.answer.3.part1') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.6.question.4') }}">
                    <p>{{ t('faq.6.answer.4.part1') }}</p>
                    <p>{{ t('faq.6.answer.4.part2') }}</p>
                    <p>{{ t('faq.6.answer.4.part3') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.6.question.5') }}">
                    <p>{{ t('faq.6.answer.5.part1') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.6.question.6') }}">
                    <p>
                      {{ t('faq.6.answer.6.part1') }}
                      <a href="mailto:bok@gaponline.pl">{{
                        t('faq.6.answer.6.part2')
                      }}</a>
                      {{ t('faq.6.answer.6.part3') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.6.question.7') }}">
                    <p>{{ t('faq.6.answer.7.part1') }}</p>
                  </p-accordionTab>
                </p-accordion>
              </div>

              <div
                class="p-col-12"
                *ngIf="sectionNumber === 7 || sectionNumber === 10">
                <div class="faqExtended__header">
                  <h2 class="textYellow">
                    {{ t('faq.7.number') }}{{ t('faq.7.header') }}
                  </h2>
                </div>
                <p-accordion>
                  <p-accordionTab header="{{ t('faq.7.question.1') }}">
                    <p>{{ t('faq.7.answer.1.part1') }}</p>
                    <p>
                      {{ t('faq.7.answer.1.part2') }}
                      <a href="mailto:info@defendinsurance.pl">{{
                        t('faq.7.answer.1.part3')
                      }}</a>
                      {{ t('faq.7.answer.1.part4') }}
                      <a
                        target="_blank"
                        href="https://gaponline.pl/download/pozostale/formularz-anulowanie.pdf"
                        >{{ t('faq.7.answer.1.part5') }}</a
                      >
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.7.question.2') }}">
                    <p>{{ t('faq.7.answer.2.part1') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.7.question.3') }}">
                    <p>
                      <b>{{ t('faq.7.answer.3.part1') }}</b>
                    </p>
                    <p>{{ t('faq.7.answer.3.part2') }}</p>
                    <p>{{ t('faq.7.answer.3.part3') }}</p>
                  </p-accordionTab>
                </p-accordion>
              </div>

              <div
                class="p-col-12"
                *ngIf="sectionNumber === 8 || sectionNumber === 10">
                <div class="faqExtended__header">
                  <h2 class="textYellow">
                    {{ t('faq.8.number') }}{{ t('faq.8.header') }}
                  </h2>
                </div>
                <p-accordion>
                  <p-accordionTab header="{{ t('faq.8.question.1') }}">
                    <p>
                      {{ t('faq.8.answer.1.part1') }}
                      <a href="mailto:info@defendinsurance.pl">{{
                        t('faq.8.answer.1.part2')
                      }}</a>
                      {{ t('faq.8.answer.1.part3') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.8.question.2') }}">
                    <p>
                      {{ t('faq.8.answer.2.part1') }}
                      <a href="mailto:bok@gaponline.pl">{{
                        t('faq.8.answer.2.part2')
                      }}</a>
                      {{ t('faq.8.answer.2.part3') }}
                      <a href="mailto:info@defendinsurance.pl">{{
                        t('faq.8.answer.2.part4')
                      }}</a>
                      {{ t('faq.8.answer.2.part5') }}
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.8.question.3') }}">
                    <p>{{ t('faq.8.answer.3.part1') }}</p>
                    <p>{{ t('faq.8.answer.3.part2') }}</p>
                    <p>{{ t('faq.8.answer.3.part3') }}</p>
                    <p>
                      {{ t('faq.8.answer.3.part4') }}
                      <b>{{ t('faq.8.answer.3.part5') }}</b>
                    </p>
                    <p>{{ t('faq.8.answer.3.part6') }}</p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.8.question.4') }}">
                    <p>{{ t('faq.8.answer.4.part1') }}</p>
                  </p-accordionTab>
                </p-accordion>
              </div>

              <div
                class="p-col-12"
                *ngIf="sectionNumber === 9 || sectionNumber === 10">
                <div class="faqExtended__header">
                  <h2 class="textYellow">
                    {{ t('faq.9.number') }}{{ t('faq.9.header') }}
                  </h2>
                </div>
                <p-accordion>
                  <p-accordionTab header="{{ t('faq.9.question.1') }}">
                    <p>{{ t('faq.9.answer.1.part1') }}</p>
                    <p>{{ t('faq.9.answer.1.part2') }}</p>
                    <p>
                      {{ t('faq.9.answer.1.part3') }}
                      <a
                        target="_blank"
                        href="https://gaponline.pl/download/pozostale/polityka prywatnosci.pdf"
                        >{{ t('faq.9.answer.1.part4') }}</a
                      >
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.9.question.2') }}">
                    <p>
                      {{ t('faq.9.answer.2.part1') }}
                      <a href="mailto:data@defendinsurance.eu">{{
                        t('faq.9.answer.2.part2')
                      }}</a>
                    </p>
                    <p>{{ t('faq.9.answer.2.part3') }}</p>
                    <p>
                      {{ t('faq.9.answer.2.part4') }}
                      <a
                        target="_blank"
                        href="https://gaponline.pl/download/pozostale/polityka prywatnosci.pdf"
                        >{{ t('faq.9.answer.2.part5') }}</a
                      >
                    </p>
                  </p-accordionTab>

                  <p-accordionTab header="{{ t('faq.9.question.3') }}">
                    <p>{{ t('faq.9.answer.3.part1') }}</p>
                    <p>
                      {{ t('faq.9.answer.3.part2') }}
                      <a href="mailto:bok@gaponline.pl">{{
                        t('faq.9.answer.3.part3')
                      }}</a>
                      {{ t('faq.9.answer.3.part4') }}
                      <a href="mailto:info@defendinsurance.pl">{{
                        t('faq.9.answer.3.part5')
                      }}</a>
                    </p>
                    <p>
                      {{ t('faq.9.answer.3.part6') }}
                      <a
                        target="_blank"
                        href="https://gaponline.pl/download/pozostale/formularz-zmiana w umowie.pdf"
                        >{{ t('faq.9.answer.3.part7') }}</a
                      >
                    </p>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <button
      class="buttonYellow p-as-center"
      (click)="close()">
      {{ t('buttons.ok') }}
    </button>
  </div>
</ng-container>

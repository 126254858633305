export enum CoverageNameEnum {
  GapInvoice = 'GapInvoice',
  GapDirect = 'GapDirect',
  GapInvoiceMax = 'GapInvoiceMax',
  TruckGapMax = 'TruckGapMax',
  GapMaxAc = 'GapMaxAc',
  GapMaxAcInvoice = 'GapMaxAcInvoice',
  TruckGapMaxAc = 'TruckGapMaxAc',
  TruckGapMaxAcInvoice = 'TruckGapMaxAcInvoice',
}

export interface Button {
  name: CoverageNameEnum;
  label: string;
  labelSmall?: string;
  isVisible: boolean;
  isActive: boolean;
  info?: string;
  isSelected?: boolean;
  coverageName?: string;
}

import { DatePipe } from '../../shared/pipes/date.pipe';
import { CurrencyPipe } from '../../shared/pipes/currency.pipe';
import { NumberPipe } from '../../shared/pipes/number.pipe';
import {
  SummaryData,
  SummaryEntry,
} from '../../shared/interfaces/summary-data.interface';
import * as moment from 'moment';
import { PolicyCreatorStateModel } from '../store/policy-creator.model';

export function mapSummaryData(data: PolicyCreatorStateModel): SummaryData {
  const datePipe = new DatePipe();
  const currencyPipe = new CurrencyPipe();
  const numberPipe = new NumberPipe();

  const handlePaymentType = (type) => {
    switch (type) {
      case 'PT_LS': {
        return 'Jednorazowa za cały okres ubezpieczenia';
      }
      case 'PT_A': {
        return 'Ratalna roczna';
      }
      case 'PT_LS_M': {
        return 'Ratalna miesięczna (wymagana karta kredytowa/debetowa)';
      }
    }
  };
  const handleVariantType = (type) => {
    switch (type) {
      case 'D2C': {
        return 'DEFEND Gap D2C (direct)';
      }
      case 'MAX': {
        return 'DEFEND Gap Max (fakturowy)';
      }
      case 'MAX AC': {
        return 'DEFEND Gap Max (casco)';
      }
      case 'T-MAX': {
        return 'DEFEND Truck Gap Max (fakturowy)';
      }
      case 'T-MAX AC': {
        return 'DEFEND Truck Gap Max (casco)';
      }
    }
  };
  const handlePolicyPeriodPostfix = (policyPeriod: number): string => {
    const policyPeriodInYears = policyPeriod / 12;
    let postfix = 'rok';
    if (
      policyPeriodInYears === 2 ||
      policyPeriodInYears === 3 ||
      policyPeriodInYears === 4
    ) {
      postfix = 'lata';
    }
    if (policyPeriodInYears === 5) {
      postfix = 'lat';
    }
    return `${policyPeriodInYears} ${postfix}`;
  };

  const insuranceInfoValues: SummaryEntry[] = [
    {
      label: 'Typ ubezpieczenia',
      value: handleVariantType(data.firstStepPayload.selectedInsuranceVariant),
    },
    {
      label: 'Długość ubezpieczenia',
      value: handlePolicyPeriodPostfix(
        Number(data.secondStepPayload.policyPeriod.replace('T_', ''))
      ),
    },
    {
      label: 'Limit świadczenia ubezpieczeniowego',
      value: currencyPipe.transform(
        Number(data.secondStepPayload.insuranceLimit.replace('CL_', ''))
      ),
    },
    {
      label: 'Data rozpoczęcia okresu ubezpieczenia',
      value: datePipe.transform(data.fourthStepPayload.insuranceStartDate),
    },
    {
      label: 'Data końcowa ubezpieczenia',
      value: datePipe.transform(
        moment(data.fourthStepPayload.insuranceStartDate)
          .add(
            Number(data.secondStepPayload.policyPeriod.replace('T_', '')),
            'months'
          )
          .toDate()
      ), // TODO: check if date is provided by iDefend
    },
    {
      label: 'Cena ubezpieczenia DEFEND Gap',
      value: currencyPipe.transform(
        data.fourthStepPayload.premiumSuggested / 100
      ),
    },
    {
      label: 'Rodzaj płatności',
      value: handlePaymentType(data.secondStepPayload.paymentType),
    },
  ];
  const vehicleInfoValues: SummaryEntry[] = [
    {
      label: 'Wartość bieżąca pojazdu',
      value: data.firstStepPayload.vehiclePrice
        ? currencyPipe.transform(data.firstStepPayload.vehiclePrice)
        : null,
    },
    {
      label: 'Data zakupu pojazdu',
      value: data.firstStepPayload.vehicleBuyDate
        ? datePipe.transform(data.firstStepPayload.vehicleBuyDate)
        : null,
    },
    {
      label: 'Kategoria pojazdu',
      value: data.firstStepPayload.vehicleType.name,
    },
    {
      label: 'Numer VIN',
      value: data.fourthStepPayload.vin.toUpperCase(),
    },
    {
      label: 'Data pierwszej rejestracji',
      value: datePipe.transform(data.firstStepPayload.vehicleRegDate),
    },
    {
      label: 'Aktualny przebieg',
      value: `${numberPipe.transform(data.fourthStepPayload.millage)} km`,
    },
    {
      label: 'Marka pojazdu',
      value: data.firstStepPayload.selectedVehicleMake.name,
    },
    {
      label: 'Model pojazdu',
      value: data.firstStepPayload.selectedVehicleModel.name,
    },
  ];
  const insuredInfoValues: SummaryEntry[] = [
    {
      label: 'Typ osoby',
      value:
        data.thirdStepPayload.insurer.clientType === 'individual'
          ? 'Osoba fizyczna'
          : 'Działalność gospodarcza',
    },
    {
      label: 'Imię i nazwisko',
      value:
        data.thirdStepPayload.insurer.firstName ||
        data.thirdStepPayload.insurer.lastName
          ? `${data.thirdStepPayload.insurer.firstName ?? ''} ${
              data.thirdStepPayload.insurer.lastName ?? ''
            }`
          : null,
    },
    {
      label: 'Nazwa',
      value: data.thirdStepPayload.insurer.companyName || null,
    },
    {
      label: 'PESEL / NIP',
      value: data.thirdStepPayload.insurer.idNumber,
    },
    {
      label: 'Numer telefonu',
      value: `${data.thirdStepPayload.insurer.phonePrefix}${data.thirdStepPayload.insurer.phone}`,
    },
    {
      label: 'E-mail',
      value: data.thirdStepPayload.insurer.email,
    },
    {
      label: 'Adres',
      value: `${data.thirdStepPayload.insurer.address1} ${
        data.thirdStepPayload.insurer.address2 ?? ''
      }, ${data.thirdStepPayload.insurer.postalCode}, ${
        data.thirdStepPayload.insurer.city
      }`,
    },
    {
      label: 'Ubezpieczający jest również',
      value: data.thirdStepPayload.insured.sameAsInsurer
        ? 'Ubezpieczony'
        : null,
    },
  ];

  const ownerInfoValues: SummaryEntry[] = [
    {
      label: 'Typ osoby',
      value:
        data.fourthStepPayload.owner.clientType === 'individual'
          ? 'Osoba fizyczna'
          : 'Działalność gospodarcza',
    },
    {
      label: 'Imię i nazwisko',
      value:
        data.fourthStepPayload.owner.firstName ||
        data.fourthStepPayload.owner.lastName
          ? `${data.fourthStepPayload.owner.firstName ?? ''} ${
              data.fourthStepPayload.owner.lastName ?? ''
            }`
          : null,
    },
    {
      label: 'Nazwa',
      value: data.fourthStepPayload.owner.companyName || null,
    },
    {
      label: 'PESEL / NIP',
      value: data.fourthStepPayload.owner.idNumber,
    },
    {
      label: 'Adres',
      value: `${data.fourthStepPayload.owner.address1} ${
        data.fourthStepPayload.owner.address2 ?? ''
      }, ${data.fourthStepPayload.owner.postalCode}, ${
        data.fourthStepPayload.owner.city
      }`,
    },
  ];

  const insurerInfoValues: SummaryEntry[] = [
    {
      label: 'Typ osoby',
      value:
        data.thirdStepPayload.insured.clientType === 'individual'
          ? 'Osoba fizyczna'
          : 'Działalność gospodarcza',
    },
    {
      label: 'Imię i nazwisko',
      value:
        data.thirdStepPayload.insured.firstName ||
        data.thirdStepPayload.insured.lastName
          ? `${data.thirdStepPayload.insured.firstName ?? ''} ${
              data.thirdStepPayload.insured.lastName ?? ''
            }`
          : null,
    },
    {
      label: 'Nazwa',
      value: data.thirdStepPayload.insured.companyName || null,
    },
    {
      label: 'PESEL / NIP',
      value: data.thirdStepPayload.insured.idNumber,
    },
    {
      label: 'Numer telefonu',
      value: `${data.thirdStepPayload.insured.phonePrefix}${data.thirdStepPayload.insured.phone}`,
    },
    {
      label: 'E-mail',
      value: data.thirdStepPayload.insured.email,
    },
    {
      label: 'Adres',
      value: `${data.thirdStepPayload.insured.address1} ${
        data.thirdStepPayload.insured.address2 ?? ''
      }, ${data.thirdStepPayload.insured.postalCode}, ${
        data.thirdStepPayload.insured.city
      }`,
    },
  ];

  return {
    insuranceInfo: {
      label: 'Informacje o ubezpieczeniu',
      values: insuranceInfoValues,
    },
    vehicleInfo: {
      label: 'Informacje o pojeździe',
      values: vehicleInfoValues,
    },
    insuredInfo: {
      label: 'Informacje o ubezpieczającym',
      values: insuredInfoValues,
    },
    insurerInfo: !data.thirdStepPayload.insured.sameAsInsurer
      ? {
          label: 'Informacje o ubezpieczonym',
          values: insurerInfoValues,
        }
      : null,
    ownerInfo: !data.fourthStepPayload.owner.sameAsInsurer
      ? {
          label: 'Informacje o właścicielu',
          values: ownerInfoValues,
        }
      : null,
  };
}

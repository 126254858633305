import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss'],
})
export class BenefitsComponent implements OnInit {
  typeOfPolicy = 'invoice';

  constructor() {}

  ngOnInit(): void {}

  selectTypeOfPolicy(type): void {
    this.typeOfPolicy = type;
  }
}

import { Portfolio } from '../../../../interfaces/idefend/portfolio.interface';
import { PortfolioGroups } from '../../../../interfaces/idefend/portfolio-group.interface';
import { Currency } from '../../../../interfaces/idefend/currency.interface';
import { LocalizationSetting } from '../../../../interfaces/idefend/localization-settings.interface';
import { SodanReplayHeads } from '../../../../interfaces/idefend/sodan-replay-heads.interface';
import { PolicyGroupResponse } from '../../../../interfaces/idefend/policy-groups-response.interface';
import { VehiclesCategory } from '../../../../interfaces/idefend/vehilces-category.interface';
import { VehicleUsage } from '../../../../interfaces/idefend/vehicle-usage.interface';
import { VehicleMake } from '../../../../interfaces/idefend/vehicle-make.interface';
import { VehicleModel } from '../../../../interfaces/idefend/vehicle-model.interface';
import { FirstStepPayload } from '../../shared/interfaces/first-step-payload.interface';
import { SecondStepPayload } from '../../shared/interfaces/second-step-payload.interface';
import { ThirdStepPayload } from '../../shared/interfaces/third-step-payload.interface';
import { FourthStepPayload } from '../../shared/interfaces/fourth-step-payload.interface';
import { CalculateOfferResponse } from '../../shared/interfaces/calculate-offer-response.interface';
import { StepsValidators } from '../../../../interfaces/idefend/steps-validators.interface';

export interface PolicyCreatorStateModel {
  portfolios: Portfolio[];
  groups: PortfolioGroups[];
  currency: Currency;
  localizationSettings: LocalizationSetting;
  sodanReplyHeads: SodanReplayHeads;
  policyGroups: PolicyGroupResponse;
  vehicleCategories: VehiclesCategory[];
  vehicleUsages: VehicleUsage[];
  vehicleMakes: VehicleMake[];
  vehicleModels: VehicleModel[];
  vehicleModelsFilter: VehicleModel[];
  offer: CalculateOfferResponse;
  activeStep: number;
  stepsValidator: StepsValidators;

  // User selections
  firstStepPayload: FirstStepPayload;
  secondStepPayload: SecondStepPayload;
  thirdStepPayload: ThirdStepPayload;
  fourthStepPayload: FourthStepPayload;
  paymentMethod: string;
}

export const defaults: PolicyCreatorStateModel = {
  portfolios: [],
  groups: [],
  currency: null,
  localizationSettings: null,
  sodanReplyHeads: null,
  policyGroups: null,
  vehicleCategories: [],
  vehicleUsages: [],
  vehicleMakes: [],
  vehicleModels: [],
  vehicleModelsFilter: [],
  offer: null,
  activeStep: null,
  stepsValidator: null,

  // User selections
  firstStepPayload: null,
  secondStepPayload: null,
  thirdStepPayload: null,
  fourthStepPayload: null,
  paymentMethod: null,
};
